section.login {
  form {
    @include grid;
    @include origin(center); }

  fieldset {
    @include screen-medium {
      @include columns(4/12); } }

  .forgot-password {
    text-align: center;
    color: grey;
    font-size: $type-size-small;
    margin-top: 2rem;

    a {
      text-decoration: underline; } } }
