.text-content {

  .content:not(:first-child) {
    margin-top: space(3); }

  p {
    & + * {
      margin-top: space(2); } }

  strong,
  b {
    font-weight: $type-weight-bold; }

  em,
  i {
    font-style: italic; }

  a {
    text-decoration: underline;
    color: $color-grey;

    &:hover {
      color: $color-black; } }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: space(1.5); }

  h1,
  h2 {
    @include large-text;
    @include fine-underlines; }

  ul,
  ol {
    padding-left: space(2);
    margin-top: space(2);
    margin-bottom: 0;

    ul,
    ol {
      margin-top: space(0.5); }

    li {
      margin-bottom: space(0.5);
      font-size: $type-size-paragraph;
      line-height: 1.46;
      letter-spacing: 0.1px;

      @include screen-medium {
        font-size: $type-size-paragraph-small; }

      @include screen-large {
        font-size: $type-size-paragraph; }

      @include breakpoint($max-width: 650px) {
        font-size: 20px;
        line-height: 1.3; }

      &:last-child {
        margin-bottom: 0; } } }

  ul {
    list-style-type: disc;
    li {
      position: relative;
      margin-left: space(2); } }

  ol {
    list-style-type: decimal; }

  // pullquotes are styled here rather than creating their own content block
  &.pullquote-block,
  blockquote {
    position: relative;
    @include headline($light: true);
    padding-bottom: space(2);
    margin-bottom: space(3);
    border-bottom: 1px solid $color-black;

    @include screen-medium {
      @include columns(1.05);
      @include push(-0.05); }

    @include screen-large {
      padding-bottom: space(3);
      border-bottom: 1px solid $color-black; }

    @include breakpoint($max-width: 650px) {
      font-size: 28px; }

    p {
      @include headline($light: true);

      @include breakpoint($max-width: 650px) {
        font-size: 28px; } }

    &:not(:first-child) {
      margin-top: space(3); }

    &:last-child {
      margin-bottom: 0; }

    &:before {
      content: '';
      display: block;
      position: relative;
      width: 43px;
      height: 36px;
      margin-bottom: space(2);
      background-image: url(inline-asset('/web/assets/images/icons/quotemark.svg'));
      background-position: center center;
      background-repeat: no-repeat;

      @include screen-medium {
        display: inline-block;
        position: absolute;
        top: 11px;
        left: -15%; }

      @include screen-large {
        width: 33px;
        height: 28px; }

      @include screen-wide {
        width: 43px;
        height: 36px; } } }

  &.large,
  .big-type {
    margin-bottom: space(3);
    @include fine-underlines;
    // +large-text
    @include headline-3($big-type: true);

    &:not(:first-child) {
      margin-top: space(3); }

    &:last-child {
      margin-bottom: 0; } } }
