html {
  --header-padding-horiz: 42px;
  --header-padding-top: calc(3vw * 1.5);
  --header-padding-bottom: 24px;
  --header-logo-width: #{4.125 * 16px};
  --header-logo-height: calc(var(--header-logo-width) * 0.4153);
  --header-search-width: 22px;
  --header-approx-height: calc(var(--header-padding-top) + var(--header-padding-bottom) + var(--header-logo-height));
  --header-default-clearance: 108px; // used for top margin on first item in page

  @include screen-small {
    --header-padding-horiz: 18px; }

  @include screen-medium {
    --header-default-clearance: 148px; }

  @include screen-wide {
    --header-default-clearance: 196px; }

  // breakpoints to match shop header
  @include screen-shop-phone {
    --header-padding-top: calc(3vw * 2.5);
    --header-logo-width: #{3.5 * 16px * 0.875};
    --header-search-width: 17.5px; }

  @include screen-shop-tablet {
      --header-logo-width: #{4.125 * 16px * 0.875};
      --header-search-width: #{22px * 0.875}; }

  @include screen-shop-full-xl {
      --header-logo-width: #{4.125 * 16px * 1.1}; }

  @include screen-shop-full-xxl {
      --header-logo-width: #{4.125 * 16px * 1.3}; } }


header {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $color-white;
  user-select: none;
  transition: background-color 0.15s linear, transform 0.2s ease-in-out;

  .grid {
    @include vertical-align(top);
    position: relative;
    margin-left: 0;
    margin-right: 0; }

  &.hidden {
    transform: translate3d(0, -100%, 0); }

  &.hero:not(.sticky) {
    width: 100%;
    background-color: transparent;

    &::after {
      border-top-color: $color-white; }

    nav a {
      color: $color-white;

      &:hover {
        border-bottom: 1px solid $color-white; } }

    section {
      @include svg-flood {
        fill: $color-white; } }

    &.dark {
      @include screen-medium {
        nav a {
          color: $color-black;

          &:hover {
            border-bottom: 1px solid $color-black; } }

        @include svg-flood {
          fill: $color-black; } } }

    nav {
      a {
        color: $color-white;

        &:hover {
          border-bottom: 1px solid $color-white; } } }

    &.sticky {
      background-color: $color-white;

      &.dark {
        @include screen-medium {
          nav a {
            color: $color-black;

            @include svg-flood {
              fill: $color-black; }

            &:hover {
              border-bottom: 1px solid $color-black; } }

          @include svg-flood {
            fill: $color-black; } } } }

    &.black {
      background-color: $color-white; } }

  &::after {
    display: none;
    content: '';
    position: absolute;
    bottom: 0;
    left: var(--header-padding-horiz);
    right: var(--header-padding-horiz);
    border-top: 0.5px solid $color-black;
    opacity: 0.333; }

  &.sticky::after {
 }    // display: block

  section {
    position: relative;
    padding: var(--header-padding-top) var(--header-padding-horiz) var(--header-padding-bottom); }

  .page-title {
    padding: 0 0 0 var(--header-search-width);
    flex-grow: 1;
    width: auto; }

  .logo {
    width: var(--header-logo-width);
    display: block;
    margin: 0 auto;

    .responsive-svg {
      margin-top: 0; } }

  .logo,
  .responsive-svg {
    @include svg-flood {
      fill: $color-black; } }

  .responsive-svg {
    margin-top: 2px;
    line {
      stroke: $color-black; } }

  a {
    color: $color-black;
    transition: 0.1s linear color; }

  nav {
    a {
      color: $color-black;
      @include svg-flood {
        transition: 0.1s linear fill; }

      &:hover {
        border-bottom: 1px solid $color-black; } } }

  nav.header {
    display: block;
    min-width: var(--header-search-width);
    color: $color-grey;
    font-size: $type-size-nav;
    line-height: 1;
    letter-spacing: $type-tracking-nav;
    width: 100%;

    @include screen-small {
 }      // display: none

    ul {
      @include grid;
      align-items: flex-end;
      margin-left: 0;
      margin-right: 0;

      li {
        text-align: left;

        @include screen-ipad {
          width: auto; }

        &.last {
          flex-grow: 1; } }

      // li:not(:first-child)
      //   +screen-ipad
      //     margin-left: space(1.8)

      // li:first-child
      //   +screen-ipad
      //     margin-left: -6px

      .search {
        width: var(--header-search-width);
        height: var(--header-search-width);
        cursor: pointer;
        padding: 0;
        margin-left: 0;

        .responsive-svg {
          margin-top: 0; }

        &:hover svg path {
          fill: $color-grey; } }

      .aaa24-link {
        width: 68px;
        cursor: pointer;

        .responsive-svg {
          margin-top: -2px; } }

      .public-access {
        width: 68px;
        cursor: pointer;

        .responsive-svg {
          margin-top: -2px; } }

      .a24-auctions {
        width: 102px;
        cursor: pointer;

        .responsive-svg {
          margin-top: -2px; } }

      .screening-room {
        width: 88px;
        cursor: pointer;

        .responsive-svg {
          margin-top: -1px; } } } }

  .cart-hud {
    @include svg-flood {
      transition: 0.1s linear stroke;
      fill: none;
      stroke: $color-grey; }

    a:hover {
      color: $color-grey;
      @include svg-flood {
        stroke: $color-grey; } } }

  .menu-supplemental {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    flex-direction: row;
    width: auto;
    padding-left: 0;
    padding-right: 0;

    @include screen-ipad {
 }      // display: flex

    .cart-hud {
      @include columns(1/4);
      text-align: left;

      .label {
        width: 16px;
        display: inline-block;
        vertical-align: text-bottom; } } } }
