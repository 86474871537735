.marquee {
  white-space: nowrap;
  opacity: 0;
  transition: 0.5s linear opacity;
  position: relative;
  font-size: 0;

  &.ready {
    opacity: 1; }

  .marquee-segment {
    white-space: nowrap;
    display: inline;
    width: auto;
    padding: 0 15px;

    @include screen-medium {
      padding: 0 45px; } } }
