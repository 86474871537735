html,
body {
  overflow-x: hidden;
  width: 100%; }

body {
  position: relative;
  transition: 0.25s cubic-bezier(0, 1, 0.25, 1) right;
  right: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: $type-tracking-default; }

body.mobile-menu-open {
  @include screen-small {
    position: fixed; }

  @include screen-medium {
    // counteract the "top" property added by the mobilenav js
    // when on a non-phone
    top: auto !important; } }

html.featured-open,
body.featured-open {
  overflow: hidden;
  height: 100%;

  &.mobile-menu-open {
    height: auto; } }

h2 {
  @include headline; }

a {
  text-decoration: inherit;
  color: inherit; }

sup {
  @include sup; }

p {
  font-size: $type-size-paragraph;
  line-height: 1.33;
  letter-spacing: $type-tracking-paragraph;

  @include screen-medium {
    font-size: $type-size-paragraph-small; }

  @include screen-large {
    font-size: $type-size-paragraph; } }

::-moz-selection {
  background: $color-black;
  color: $color-white; }

::selection {
  background: $color-black;
  color: $color-white; }
