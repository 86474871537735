.checkout-sidebar {
  .sidebar-section {
    &:not(:first-child) {
      margin-top: space(2); }

    h3 {
      margin-bottom: space(1); } }

  .sidebar-attribute-group {
    .label,
    .value {
      display: inline-block; }

    .label {
      margin-right: 2px;

      &::after {
        content: ':'; } }

    .value {} }

  .cart-quick-view {
    .cart-item {
      &:not(:first-child) {
        margin-top: space(2); } }

    .item-title .label {
      display: none; } }

  .totals {
    .total {
      &:not(:first-child) {
        margin-top: space(2); } }

    .total-price {
      .label {
        font-weight: $type-weight-medium; } } } }
