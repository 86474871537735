.waitlist {
  .email {
    position: relative;

    .label {
      padding-right: space(2); } }

  .waitlist-email {
    padding-right: space(2); }

  .waitlist-submit {
    @include icon('arrow-right-micro');
    position: absolute;
    top: 32px;
    right: 0;
    border: 0;
    padding: 0;

    &:disabled {
      @include icon('arrow-right-micro-grey-mid'); } } }
