aside.shop-hud {
  .back-to-shop {
    display: none;

    @include screen-large {
      display: block; }

    a {
      @include icon('arrow-left-micro', 1, before);
      color: $color-grey;
      text-decoration: underline;

      &::before {
        vertical-align: -13%; }

      &:hover {
        color: $color-black; } } } }
