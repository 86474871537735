main.story {

  @include screen-medium {
    padding-bottom: 0; }

  p {
    @include breakpoint($max-width: 650px) {
      font-size: 18px;
      line-height: 1.58;
      letter-spacing: .1px; } }

  .header {
    margin-bottom: 47px;
    max-height: 100vh;
    overflow: hidden;

    @include screen-medium {
      max-height: 85vh; }

    .story-title {
      h1 {
        @include headline;
        @include inline-markdown;
        color: $color-black;
        margin-bottom: 0;
        margin-top: space(2);

        @include screen-medium {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          margin-bottom: 0;
          padding: space(2); } }


      &.light {
        h1 {
          @include screen-medium {
            @include antialiased-type;
            color: $color-white;

            &::after {
              border-top-color: $color-white; } } } }

      br {
        display: none;

        @include screen-medium {
          display: inline; } } }

    .featured-image {
      position: relative;
      height: 100vh;
      background-color: $color-black;

      @include screen-medium {
        height: auto;
        background-color: none; }

      &.no-mobile {
        display: none;

        @include screen-medium {
          display: block; } }

      &.mobile {
        display: block;

        @include screen-medium {
          display: none; } } }

    .responsive-image {
      padding-bottom: (9/16) * 100%;
      height: 100vh;
      opacity: 0.7;

      @include screen-medium {
        height: auto;
        opacity: 1; }

      @include screen-large {
        padding-bottom: 40%; } }

    figcaption {
      margin-top: 8px;
      margin-right: 12px; } }

  section.title {
    display: flex;
    position: absolute;
    top: 0;
    height: calc(100vh - 76px);
    color: $color-white;

    @include screen-small {
      padding-left: 18px;
      padding-right: 18px; }

    @include screen-medium {
      display: block;
      position: relative;
      height: auto;
      color: $color-black; }

    &.slides {
      display: block;
      position: relative;
      height: auto;
      color: $color-black; }

    .grid {
      align-items: flex-end;
      align-content: flex-end;

      @include screen-medium {
        align-items: flex-start; } }

    .metadata {
      margin-bottom: space(4);

      @include screen-medium {
        @include columns(0.70);
        margin-left: 17.5%;
        margin-right: auto;
        margin-bottom: space(2);
        padding: 0; } }

    time {
      @include eyebrow;
      display: block;
      margin-bottom: space(1);
      font-size: $type-size-note-details;
      color: $color-grey; }

    h2 p {
      @include headline;

      em {
        font-style: italic; } } }

  section.intro {
    margin-bottom: space(4);

    @include screen-small {
      padding-left: 18px;
      padding-right: 18px; }

    .text-content.outer {
      @include screen-medium {
        @include columns(0.65);
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 36px; } }

    .top {
      margin-bottom: space(2);

      @include screen-medium {
        margin-bottom: 0; } }

    .contents {
      margin-bottom: space(2);

      a {
        color: $color-grey;
        border-bottom: 1px solid $color-grey;

        &:hover {
          color: $color-black;
          border-bottom-color: $color-black; } } }

    .big-type {
      @include screen-small {
        margin-bottom: 0; } }

    .contributors {
      margin-bottom: space(1);

      p {
        @include eyebrow;
        font-size: $type-size-note-details;
        text-transform: none; }
      p a,
      p span {
          text-transform: uppercase; } } }

  .header.slideshow {
      @include screen-medium {
        max-height: 85vh; } }

  section.story-content {
    position: relative;
    padding-bottom: 90px; }

  figcaption.text-content p {
    line-height: 1.2;
    font-size: $type-size-note-details;
    padding-right: 10px;

    &.image-title {
      font-family: $type-family-sans;
      text-transform: uppercase;
      font-size: 18px; } }

  .text-content.attribution {
    @include eyebrow($attribution: true);
    display: block;
    min-height: 25px;
    text-transform: none;
    font-size: 12px;
    color: $color-grey;

    &.masthead {
      display: block;
      position: absolute;
      right: space(1);
      text-align: right;

      @include screen-medium {
        position: relative; } } }

  .text-content .content p:not(.big-type) {
    @include screen-medium {
      font-size: 18px; } }

  .sharing {
    margin: 0;
    padding: 0;
    justify-content: flex-start;

    li {
      margin-bottom: 0;
      margin-left: 0;

      &:before {
        display: none; } }

    ul {
      padding-left: 0; } }

  .explore {
    display: none;

    @include screen-medium {
      display: flex;
      padding-bottom: space(4.5); }

    .explore-wrapper {
      display: flex; } } }
