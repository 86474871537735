html {
  --mobile-menu-width: 100vw;

  @include screen-medium {
    --mobile-menu-width: 500px; } }

aside.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: var(--mobile-menu-width);
  height: 100vh;
  font-family: $type-family-sans;
  background-color: $color-black;
  transition: opacity 250ms ease, left 0ms 250ms ease;
  z-index: 3;
  overflow: none;
  opacity: 0;
  left: calc(-1 * var(--mobile-menu-width) - 1px);
  pointer-events: none;
  scrollbar-color: $color-black $color-grey-dark;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
      width: 9px; }

  &::-webkit-scrollbar-track {
      background: $color-black; }

  &::-webkit-scrollbar-thumb {
      background: $color-grey-dark;
      border-radius: 9px; }

  &::-webkit-scrollbar-thumb:hover {
      background: $color-grey;
      opacity: 0.75; }

  @include screen-medium {
    box-shadow: 2.1px 0px 2.2px rgba(0, 0, 0, 0.025), 5.1px 0px 5.3px rgba(0, 0, 0, 0.036), 9.5px 0px 9.9px rgba(0, 0, 0, 0.045), 17px 0px 17.6px rgba(0, 0, 0, 0.054), 31.8px 0px 33px rgba(0, 0, 0, 0.065), 76px 0px 79px rgba(0, 0, 0, 0.09); }


  @at-root body.mobile-menu-open #{&} {
    transition: opacity 250ms ease, left 0ms 0ms ease;
    left: 0;
    opacity: 1;
    overflow-y: scroll;
    pointer-events: auto; }

  section {
    position: relative;
    padding: var(--header-default-clearance) 18px space(1);

    @include screen-small {
      padding-bottom: space(6); }

    @include screen-medium {
      padding-left: 42px;
      padding-right: 42px; }

    @media screen and (min-width:  $breakpoint-small) and (max-height: 885px) {
      padding-top: calc(var(--header-approx-height) + #{space(1)}); } }

  nav {
    margin-bottom: space(2); }

  ul {
    display: flex;
    flex-direction: column;
    margin-top: -6px; }

  li {
    // margin-bottom: space(1)

    &:last-child {
      margin-bottom: 0; }

    &.search {
      display: none; }

    &.aaa24-link {
      .responsive-svg {
        width: $type-size-h3 * 2.834;
        height: $type-size-h3;
        margin: 6px 0;
        padding-bottom: 0 !important;

        @include screen-large {
          width: $type-size-h2 * 2.834 * 0.75;
          height: $type-size-h2 * 0.75; }

        @include screen-wide {
          width: $type-size-h1 * 2.834 * 0.75;
          height: $type-size-h1 * 0.75; } }

      a:not(:hover) .responsive-svg path {
        fill: $color-white; } }

    &.public-access {
      width: 114px; }

    &.a24-auctions {
      width: 163px;

      a {
        padding: 3px 0; } }

    &.screening-room {
      width: 118px; }

    &.public-access,
    &.a24-auctions {
      @include svg-flood {
        fill: $color-white; } } }

  a {
    @include antialiased-type;
    @include headline;
    display: block;
    padding: 9px 0;
    color: $color-white;

    &:hover {
      @include screen-medium {
        color: $color-grey-mid;

        @include svg-flood {
          fill: $color-grey-mid; } } } } }
