button.play-video {
  @include image-button;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: 79px;
  height: 91px;
  transform: translate3d(-50%, -50%, 0);

  @include screen-medium {
    width: 99px;
    height: 114px; } }
