aside.newsletter-banner {
  display: none;
  position: fixed;
  overflow: hidden;
  max-height: 0;
  max-width: 0;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  color: $color-white;
  background-color: $color-black;
  z-index: 4;
  transition: max-height 500ms ease-out;

  @include screen-medium {
    display: block; }

  &.active {
    max-height: 320px;
    max-width: 100vw;

    @include screen-medium {
      max-height: 250px; }

    @include screen-large {
      max-height: 330px;
      width: 55.6vw;
      max-width: 530px; }

    &.no-content {
      @include screen-medium {
        max-height: 250px;

        section {
          height: 250px; } }

      @include screen-wide {
        max-height: 300px;

        section {
          height: 300px; } } } }

  section {
    padding-bottom: space(4);

    @include screen-medium {
      height: 250px;
      width: 75%;
      margin: 0 auto;
      padding: 0; }

    @include screen-large {
      height: 330px; } }

  .grid {
    position: relative;
    height: 100%;
    @include vertical-align(center);
    align-content: center;
    padding: 0;
    flex-wrap: wrap; }

  .cta {
    @include eyebrow;
    margin-top: 30px;
    margin-bottom: space(2);
    font-size: 15px;

    @include screen-medium {
      @include columns(1);
      margin-top: 0; } }

  .description {
    @include antialiased-type;

    p {
      font-size: $type-size-note-details; }

    @include screen-large {
      margin-bottom: space(1);

      p {
        font-size: $type-size-nav; } } }

  button.close-newsletter {
    @include image-button;
    position: absolute;
    top: 25px;
    right: 25px;
    background-image: url(inline-asset('/web/assets/images/icons/close-medium.svg'));
    width: 20px;
    height: 20px;

    @include screen-medium {
      top: 32px;
      right: 32px;
      width: 30px;
      height: 30px; } }

  .newsletter-signup label.submit {
    padding: 22px 26px; }

  .newsletter-signup label.email {
    flex: 1; }

  .messages,
  .signup-form-disclaimer {
    margin-top: space(0.5);
    font-size: 12px;
    line-height: 1.3;
    color: $color-grey-mid;
    max-width: 12em; }

  .signup-form-disclaimer {
    margin: 12px 80px 0 0;
    max-width: 27em;
    text-align: left;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: $color-white; } } } }
