address {
  .street,
  .region {
    @include screen-medium {
      display: block; } }

  .street {
    .route::after {
      content: ', ';

      @include screen-medium {
        display: none; } } } }
