.story-content {
  &.blog {
    @include grid;

    @include screen-small {
      padding-left: 18px;
      padding-right: 18px; }

    .block {
      @include screen-medium {
        @include columns(0.54);
        margin-left: auto;
        margin-right: auto; } } }

  .chapter {
    margin-top: space(8); }

  .section {
    margin-bottom: space(3);

    &:last-child {
      margin-bottom: 0; } }

  // Split Sections
  .split {
    .column {
      &:not(:first-child) {
        margin-top: space(3);

        @include screen-medium {
          margin-top: 0; } } }

    // Vertical Alignment
    &.column-align-top {}
    &.column-align-center {
      .grid {
        align-items: center; } }

    &.column-align-bottom {
      .grid {
        align-items: flex-end; } }


    // Equalized
    &.bias-equal {
      .first,
      .last {
        @include screen-medium {
          @include columns(5/10); } }

      // Block behavior inside equalized columns
      .block {
        &.left {
          @include screen-large {
            @include columns(4/5); } }

        &.right {
          @include screen-large {
            @include columns(4/5);
            @include push(1/5); } }

        &.center {
          @include screen-large {
            @include columns(3/5);
            @include push(1/5); } } } }

    // Left-dominaint sections
    &.bias-left {
      // Left column
      .first {
        @include screen-medium {
          @include columns(6/10); }

        // Block behavior inside the left column of a left-dominant layout
        .block {
          &.center {
            @include screen-large {
              @include columns(4/6);
              @include push(1/6); } }

          &.left {
            @include screen-large {
              @include columns(5/6); } }

          &.right {
            @include screen-large {
              @include columns(5/6);
              @include push(1/6); } } } }

      // Right column
      .last {
        @include screen-medium {
          @include columns(4/10); }

        // Block behavior inside the right column of a left-dominant layout
        .block {
          &.center {
            @include screen-large {
              @include columns(2/4);
              @include push(1/4); } }

          &.left {
            @include screen-large {
              @include columns(3/4); } }

          &.right {
            @include screen-large {
              @include columns(3/4);
              @include push(1/4); } } } } }


    // Left-dominaint sections
    &.bias-right {
      // Left column
      .first {
        @include screen-medium {
          @include columns(4/10); }

        // Block behavior inside the left column of a right-dominant layout
        .block {
          &.center {
            @include screen-medium {
              @include columns(2/4);
              @include push(1/4); } }

          &.left {
            @include screen-medium {
              @include columns(3/4); } }

          &.right {
            @include screen-medium {
              @include columns(3/4);
              @include push(1/4); } } } }

      // Right column
      .last {
        @include screen-medium {
          @include columns(6/10); }

        // Block behavior inside the right column of a right-dominant layout
        .block {
          &.center {
            @include screen-medium {
              @include columns(4/6);
              @include push(1/6); } }

          &.left {
            @include screen-medium {
              @include columns(5/6); } }

          &.right {
            @include screen-medium {
              @include columns(5/6);
              @include push(1/6); } } } } } }

  .wide {
    // Block behavior inside a wide section
    // No columns or section-level bias setting, here!
    .block {
      &.full {}

      &.left {
        @include screen-medium {
          @include columns(7/10); } }

      &.right {
        @include screen-medium {
          @include columns(7/10);
          @include push(3/10); } }

      &.center {
        @include screen-medium {
          @include columns(8/10);
          @include push(1/10); } } } } }

// Global block settings
.block {
  margin-bottom: space(3);

  &:last-child {
    margin-bottom: 0; }

  &.sideBySide,
  &.images {
    figure {
      & + figure {
        margin-top: space(2); } }

    figcaption {
      margin-top: space(0.5); }

    figcaption span {
      font-family: "akzidenz-grotesk-extended", "Helvetica Neue", Helvetica, Arial, sans-serif;
      letter-spacing: 0.8px;
      font-size: 12px;
      line-height: 1.5;
      display: block;
      @include screen-large {
        display: inline-block; }

      &.caption {
        color: $color-grey-dark;
        display: inline-block; }

      &.attribution {
        color: $color-grey; } }

    .responsive-image {
      margin-top: space(1); }

    .fullwidth {
      @include screen-medium {
        @include columns(1.30);
        @include push(-0.15); }

      &.center {
        @include push(-0.15); }

      .attribution {
        @include push(0.13);

        @include screen-medium {
          @include push(0); } } }

    .twenty-five {
      @include screen-medium {
        @include columns(1/4); } }

    .fifty {
      @include screen-medium {
        @include columns(1/2); } }

    .seventy-five {
      @include screen-medium {
        @include columns(3/4); } }

    .center {
      margin: 0 auto; }

    .right {
      margin-left: auto; } }

  &.sideBySide {
    @include screen-medium {
      padding: 0 2px; }

    .side-by-side {
      @include screen-medium {
        @include columns(1.30);
        @include push(-0.15); } }

    .single-side {
      @include columns(1/2); } }


  &.spacer {
    margin: 0;
    display: none;

    @include screen-medium {
      display: block;

      .spacer-shim {
        &.normal {
          height: space(4.5); }

        &.double {
          height: space(9); }

        &.tiny {
          height: space(2); } } } } }
