form.product-download {
  .loader {
    margin-left: space(1); }

  .messages {
    margin-top: space(1); }

  button {
    // Try to match this with the "How to Print" button, below it
    min-width: 160px; } }
