.responsive-video {
  padding-bottom: (9/16) * 100%;
  width: 100%;
  border: none;
  position: relative;

  .modal-scrollable & {
    margin-top: 10vh; }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; } }
