main.notes {
  margin-top: calc(var(--header-default-clearance) - 22px);

  @include screen-medium {
    margin-top: calc(var(--header-default-clearance) - 48px); }

  .filter-wrapper {
    position: relative;
    margin: 0 18px;
    padding-top: 22px;

    @include screen-medium {
      padding-bottom: 24px;
      text-align: center;
      margin: 0 42px; }

    .sort-title {
      @include eyebrow;
      display: none;
      color: $color-grey;

      @include screen-medium {
        display: inline-block; } }

    .active-filter {
      @include eyebrow;
      @include dropdown-filter; }

    .button-container {
      @include screen-medium {
        display: none; } }

    .controls {
      @include controls; } }

  .show-more-wrapper {
    @include show-more-wrapper;

    @include screen-small {
      margin-bottom: 33px; } }

  section.archive {
    padding: 0;

    @include screen-medium {
      padding: 0 24px; }

    .show-more-wrapper {
      padding: 0 24px; }

    .archive-preview {
      margin-bottom: 5px;

      @include screen-medium {
        margin-bottom: space(8);
        padding: 0; }

      @include screen-large {
        margin-bottom: space(12); }

      @include screen-wide {
        width: 88.5%; }

      &:first-of-type {
        margin-top: 38px;

        @include screen-medium {
          margin-top: space(3); } }

      &:last-of-type {
        margin-bottom: 0;

        @include screen-medium {
          margin-bottom: 100px; } }

      h2 {
        @include headline;
        @include inline-markdown;
        margin-bottom: space(2.5);

        @include screen-medium {
          margin-bottom: space(5); } } }

    .story-image {
      @include columns(1);

      @include screen-medium {
        @include columns(6/10); }

      a {
        display: block; } }

    .story-intro {
      p {
        display: inline;
        margin-right: space(0.25); }

      .read-more {
        text-decoration: underline;
        color: $color-grey;
        display: inline-block;
        margin-top: 0;

        &:hover {
          color: $color-black; } } } }

  // .pagination-wrapper
  //   +eyebrow
  //   padding-top: space(2)
  //   border-top: 1px solid $color-black
  //   text-align: center

  //   +screen-wide
  //     padding-top: 46px
  //     font-size: 20px

  //   span
  //     display: inline
  //     color: $color-grey
  //     margin: 0 space(1.2)

  //     +screen-medium
  //       margin: 0 space(1.5)

  //     &.active
 }  //       color: $color-black
