.page.search .site-container {
  margin-top: var(--header-approx-height);

  .search-wrapper .search-bar {
    padding: 26.6px 4.33%;

    @include screen-medium {
      padding: 20px 42px 20px 42px; }

    @include screen-large {
      padding: 42px 42px 45px 42px; } }

  h6 {
    @include eyebrow; }

  .results-wrapper {

    @include screen-medium {
      margin: 0 42px 0 42px; }

    h6 {
      width: 91.667%;
      margin: 0 auto;
      padding: 29px 7px 39px;
      border-bottom: 1px solid $color-black;

      @include screen-medium {
        width: 100%;
        margin: 0; } } }

  .search-form input {
    color: $color-white;
    border-bottom: none;
    font-weight: 300; }

  .stack-item:first-of-type {
    margin-top: space(2);

    @include screen-medium {
      margin-top: 82px; } }

  .stack-item:last-of-type {
    margin-bottom: 0;

    @include screen-medium {
      margin-bottom: 116px; } }

  .stack-item {
    @include screen-wide {
      width: 90.8%;
      margin: 0 auto 80px; } }

  .stack-image {
    img {
      opacity: 1;
      object-fit: cover;
      width: 100%;
      height: 100%; } } }
