aside.search-wrapper {
  // display: none
  position: fixed;
  max-height: 0;
  height: 100vh;
  min-width: 100vw;
  top: 0;
  color: $color-white;
  background-color: $color-black;
  overflow: hidden;
  z-index: 5;

  @include screen-medium {
    position: relative;
    height: 60vh; }

  &.active {
    // display: block
    max-height: 100vh;
    height: 100vh;

    @include breakpoint($min-width: 769px) {
      height: 60vh; }

    button.close {
      top: 2px;
      opacity: 1;

      @include screen-medium {
        top: 60px; } } }

  @include screen-medium {
    transition: max-height 500ms ease-in-out; }

  section {
    position: relative;
    height: 100%;
    padding-top: space(1.5);
    padding-bottom: space(2);
    padding-left: 18px;
    padding-right: 18px;

    @include screen-medium {
      padding-top: space(0.75);
      padding-bottom: space(0.75);
      padding-left: 42px;
      padding-right: 42px; }

    .grid {
      align-content: center; }

    label {
      @include antialiased-type; }

    button.close {
      top: 2px;

      @include screen-medium {
        top: 60px; } } } }
