main.contact {

  .location {
    margin-bottom: space(4.5);

    &:last-child {
      margin-bottom: space(1); }

    @include screen-medium {
      @include columns(5/10);
      margin-bottom: space(1); }

    h2 {
      @include headline; }

    .address,
    .email,
    .phone {
      color: $color-grey; }

    a {
      color: $color-grey;

      &:hover {
        color: $color-black; } } }

  .map {
    padding-bottom: 100%;
    position: relative;
    margin-top: space(4.5);

    @include screen-large {
      padding-bottom: 65%; }

    .map-canvas {
      @include fill; } } }
