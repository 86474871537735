.block.review {
  .review-container {
    // background-color: $color-grey-light
    // padding: space(1)

    // +screen-medium
    //   padding: space(2)
 }    //   padding-bottom: space(3)

  // .review-image
  //   margin-bottom: space(1.5)

  .review-headline {
    margin-bottom: space(1.5);

    h2 {
      @include headline; } }

  .review-attribution {
    @include eyebrow; }

  .review-link {
    @include arrow-link($hover: true);
    margin-top: space(4); } }
