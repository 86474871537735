.video-preview {
  position: relative;
  cursor: pointer;

  &.aspect-ratio-letterbox {
    .responsive-image {
      padding-bottom: (9/21) * 100%; } }

  &.aspect-ratio-widescreen {
    .responsive-image {
      padding-bottom: (9/16) * 100%; } }

  &.aspect-ratio-fullscreen {
    .responsive-image {
      padding-bottom: (3/4) * 100%; } }

  &.aspect-ratio-square {
    .responsive-image {
      padding-bottom: 100%; } } }
