
@mixin external-link {
  font-size: $type-size-h6;
  text-transform: uppercase;
  color: $color-black;
  border-bottom: 1px solid $color-black;
  transition: 100ms linear border-bottom-color;
  margin-left: 21.6px;

  @include screen-medium {
    font-size: $type-size-footer-copy;
    border-bottom: 1px solid transparent; }

  @include screen-wide {
    font-size: $type-size-h6; }

  &:hover {
    // color: $color-grey
    border-bottom: 1px solid $color-black; }

  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    width: 17px;
    height: 17px;
    top: 3px;
    left: -2px;
    background-image: url(inline-asset('/web/assets/images/icons/dropdown-arrow.svg'));
    background-position: center center;
    background-repeat: no-repeat;
    transform: rotate(-135deg);

    @include screen-medium {
      width: 14px;
      height: 12px;
      top: 6px; }

    @include screen-wide {
      width: 16px;
      height: 15px;
      top: 4px; } } }

@mixin arrow-link($plus: false, $hover: false) {
  // a
  text-transform: uppercase;
  font-size: 21px;
  font-weight: $type-weight-medium;

  @include screen-medium {
    // font-size: $type-size-h6
    line-height: $type-leading-h6; }

  @include screen-large {
    // font-size: $type-size-h5
    line-height: $type-leading-h5;
    letter-spacing: 0.3px; }

  @include screen-wide {
    // font-size: $type-size-h4
    // font-size: 26px;
    line-height: $type-leading-h4; }

  span {
    display: inline-block;
    transition: transform 200ms ease-in-out 25ms;
    transform: translate3d(0, -3px, 0);

    @include screen-small {
      transform: none; } }

  &:before {
    content: '';
    display: inline-block;
    position: relative;
    width: 41px;
    height: 15px;
    margin-right: 33px;
    background-image: url(inline-asset('/web/assets/images/icons/link-arrow.svg'));
    background-position: center center;
    background-repeat: no-repeat;
    transition: transform 200ms ease-in-out;

    @include screen-medium {
      width: 34px;
      height: 12px; }

    @include screen-large {
      width: 45px;
      height: 17px; }

    @include screen-wide {
      width: 55px;
      height: 20px; } }

  @if $plus {
    &:before {
      width: 18px;
      height: 18px;
      top: 1px;
      margin-right: 27px;
      background-image: url(inline-asset('/web/assets/images/icons/plus.svg'));

      @include screen-large {
        top: 0; }

      @include screen-wide {
        width: 31px;
        height: 31px; } } }

  @if $hover {
    overflow: hidden;
    &:hover {
      span {
        transform: translate3d(-25px, -3px, 0);

        @include screen-small {
          transform: translate3d(-25px, 0, 0); } }

      &:before {
        transform: translate3d(-50%, 0, 0); } } } }
