.site-container {
  position: relative;
  transition: 0.25s cubic-bezier(0, 1, 0.6, 1) transform; }

section {
  padding: 0 space(2);

  &.home-content,
  &.hero-media {
    padding: 0; }

  @include screen-medium {
    padding: 0 space(2); } }

main {
  display: block;
  padding-bottom: space(4.5); }

.grid {
  @include grid; }
