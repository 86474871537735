.option-selection {
  .label {
    display: inline-block;
    vertical-align: top;
    line-height: 1;
    margin-right: space(1);

    &::after {
      content: ':'; } }

  .variant-selector {
    display: inline-block;
    cursor: default;

    li {
      display: inline-block;
      margin-right: space(0.5);

      &.unavailable {
        pointer-events: none;

        label {
          border-color: $color-grey-mid;
          color: $color-grey-mid; } } }

    label {
      border: 2px solid $color-black;
      transition: 0.1s linear background-color, 0.1s linear color;
      padding: 2px 9px 0px;
      margin: 0;
      cursor: pointer; }

    input:checked + label {
      background-color: $color-black;
      color: $color-white; }

    input[type="radio"] {
      display: none; } } }
