.block.website {
  .browser-frame {
    display: block; }

  .toolbar {
    padding: 5px; }

  .buttons {
    width: 25px; }

  .browser-content {
    position: relative; }

  .link {
    @include arrow-link($hover: true);
    margin-top: space(2); } }
