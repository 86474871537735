main.home {
  padding-bottom: 0;
  max-width: 100vw;
  overflow-x: hidden;

  @include screen-medium {
    margin-top: 0;
    padding-bottom: 102px;
    max-width: none; }

  .featured {
    display: none;
    position: relative;
    height: 540px;
    max-height: 85vh;
    margin-bottom: 50px;
    overflow: hidden;
    background-color: $color-black;

    .play-video {
      top: 40%; }

    .arrow-svg {
      display: none;
      position: absolute;
      bottom: space(4.5);
      right: space(-2);
      width: 45px;
      height: 17px;
      transform: rotate(90deg);
      transform-origin: center center;
      z-index: 1;

      svg {
        @include svg-flood {
          fill: $color-white;
          stroke: $color-white; } } }

    @include screen-medium {
      display: flex;
      height: 560px;

      .arrow-svg {
        display: block; } }

    @include screen-large {
      height: 698px;
      margin-bottom: 62px; }

    @include screen-wide {
      height: 860px; }

    @include breakpoint($max-height: 1600px) {
      max-height: 100vh;

      .featured-item,
      .image,
      figure {
        height: 100vh; }

      img {
        min-height: 100vh;
        width: auto;
        max-width: none; } } }

  .featured-item {
    margin-bottom: space(1);
    @include fill;
    position: relative;
    z-index: 1;

    &:before,
    &:after {
      box-sizing: inherit;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-top: 1px solid transparent;
      border-bottom: 1px solid transparent;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      width: 0;
      height: 0;
      z-index: 1;
      transition: border 300ms ease-out; }

    // This covers the top & right borders (expands right, then down)
    &:before {
      top: 0;
      left: 0; }

    // And this the bottom & left borders (expands left, then up)
    &:after {
      bottom: 0;
      right: 0; }

    &.loading {
      &:before,
      &:after {
        width: 100%;
        height: 100%; }

      &:before {
        transition: width 0.6s ease-out, height 0.6s ease-out 0.6s, border-right-color 0s ease-out 0.6s; }

      &:after {
        transition: border-bottom-color 0s ease-out 1.2s, width 0.6s ease-out 1.2s, border-left-color 0s ease-out 1.8s, height 0.6s ease-out 1.8s; } }

    // &.cymb
    //   // CYMB
    //   &:before
    //     border-top-color: rgb(0, 255, 255)
    //     border-right-color: rgb(255, 255, 0)
    //
    //   &:after
    //     border-bottom-color: rgb(255, 0, 255)
    //     border-left-color: $color-black
    // &.ymcg
    //   // YMCG
    //   &:before
    //     border-top-color: rgb(255, 255, 0)
    //     border-right-color: rgb(255, 0, 255)
    //
    //   &:after
    //     border-bottom-color: rgb(0, 255, 255)
    //     border-left-color: rgb(0, 255, 0)
    // &.mcyw
    //   // MCYW
    //   &:before
    //     border-top-color: rgb(255, 0, 255)
    //     border-right-color: rgb(0, 255, 255)
    //
    //   &:after
    //     border-bottom-color: rgb(255, 255, 0)
    //     border-left-color: $color-white
    // &.gycm
    //   // GYCM
    //   &:before
    //     border-top-color: rgb(0, 255, 0)
    //     border-right-color: rgb(255, 255, 0)
    //
    //   &:after
    //     border-bottom-color: rgb(0, 255, 255)
    //     border-left-color: rgb(255, 0, 255)

    &.cyan {
      &:before {
        border-top-color: rgb(0, 255, 255);
        border-right-color: rgb(0, 255, 255); }
      &:after {
        border-bottom-color: rgb(0, 255, 255);
        border-left-color: rgb(0, 255, 255); } }

    &.yellow {
      &:before {
        border-top-color: rgb(255, 255, 0);
        border-right-color: rgb(255, 255, 0); }
      &:after {
        border-bottom-color: rgb(255, 255, 0);
        border-left-color: rgb(255, 255, 0); } }

    &.magenta {
      &:before {
        border-top-color: rgb(255, 0, 255);
        border-right-color: rgb(255, 0, 255); }
      &:after {
        border-bottom-color: rgb(255, 0, 255);
        border-left-color: rgb(255, 0, 255); } }

    &.cymg {
      // GYCM
      &:before {
        border-top-color: rgb(0, 255, 255);
        border-right-color: rgb(255, 255, 0); }

      &:after {
        border-bottom-color: rgb(255, 0, 255);
        border-left-color: rgb(0, 255, 0); } }

    // Large tiles are full-width—no need to style them further.
    &.small {
      @include screen-large {
        @include columns(10/10); }

      .image {
        @include screen-large {
          padding-bottom: 55%; } } }

    .image {
      padding-bottom: 55%;
      @include filling-image-container;

      @include screen-medium {
        background-color: $color-black; }

      @include screen-large {
        padding-bottom: 35%; }

      img {
        @include fade-in(false);

        &.loaded {
          opacity: 1; } } }

    figure {
      position: relative;
      transition: filter 0.6s ease-in, transform 0.6s ease-in;

      &.loading:not(.isFF) {
        transition: filter 0.1s ease-out, transform 0.3s ease-out;
        filter: blur(28px) saturate(110%);
        transform: scale(1.04);

        img {
          opacity: 0.8!important; } } }

    figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: space(1);

      @include screen-medium {
        padding: space(2); } } }

  .featured-mobile {
    display: flex;
    position: absolute;
    top: 0;
    min-width: 100vw;
    height: 100vh;
    margin-bottom: 0;
    z-index: 2;
    transition: transform 600ms ease-out;

    @include screen-medium {
      display: none; }

    &.hide {
      transform: translate3d(0, -160%, 0); }

    .featured-item {
      position: absolute;
      height: 100vh;
      padding: 0;
      opacity: 0;
      visibility: hidden;
      background-color: rgb(100, 100, 100);

      figure .image {
        opacity: 0.9;
        transform: scale(1);
        transition: transform 250ms ease-out, opacity 150ms ease-in-out; }

      &.active {
        opacity: 1;
        visibility: visible;
        // transition: opacity 150ms ease-out
        // transform: translate3d(0, 0, 0)

        figure .image {
          opacity: 1;
          transform: scale(1.02); } } }

    figure {
      height: 100vh; }

    .mobile-image {
      height: 100vh;

      img {
        width: auto;
 } }        // height: 100%

    .titles-wrapper {
      color: $color-white;
      bottom: 105px;

      figcaption {
        width: 350px;
        padding: space(1) 18px; }

      h1 {
        @include headline;
        // +headline
        margin-bottom: space(0.5); } }

    time {
      @include sup;
      margin-left: 2px; }

    .indicator-wrapper {
      position: absolute;
      bottom: 105px;
      padding: space(1) space(2);
      right: 0;
      color: $color-white;
      z-index: 1;
      text-align: center;

      span {
        display: block; }

      .indicator-line {
        width: 1px;
        height: 80px;
        margin: 8px auto;
        background-color: white;
        transition: height 275ms $cubic-ease-in-out; }

      .indicator-total.arrow:after {
        content: '';
        display: block;
        position: relative;
        width: 18px;
        height: 18px;
        right: 0;
        background-image: url(inline-asset('web/assets/images/icons/dropdown-glyph-white.svg'));
        background-position: center;
        background-size: 18px 18px;
        background-repeat: no-repeat; } } }

  .titles-wrapper {
    position: absolute;
    bottom: space(5);
    padding: 0 24px 0 42px;
    z-index: 1;
    opacity: 0;
    transition: opacity 120ms ease-out;

    &.loaded {
      opacity: 1; }

    @include screen-medium {
      bottom: 52px; }

    @include screen-large {
      width: 90%; }

    // +screen-wide
 }    //   width: 80%

  .featured-item-title {
    display: block;
    transition: opacity 200ms ease-in-out;

    .hero-title span {
      display: inline-block;
      white-space: nowrap; }

    ul, li {
      display: inline-block;
      list-style: none; }

    &.active {
      h1 {
        opacity: 0.6; } } }

  .eyebrow {
    @include eyebrow;
    margin-top: space(0.8);
    margin-bottom: space(0.8);

    @include screen-medium {
      margin-top: 0;
      margin-bottom: space(1); } }

  h1 {
    @include headline-1($mobile-friendly: false);
    @include antialiased-type;
    color: $color-white;
    margin-bottom: 0;

    // +screen-small
    //   font-size: 24px
    //   line-height: 28px

    @include breakpoint($max-width: 360px) {
      font-size: 6vw;
      line-height: 8vw; }

    @include screen-medium {
      margin-bottom: 0; }

    a {
      &:hover {
        opacity: 0.8; } }

    sup {
      margin-left: 0.33em; } }

  .home-stack {
    @include screen-small {
      margin-top: 78px; } } }
