html {
  font-size: 16px;
  font-family: $type-family-sans;
  font-weight: $type-weight-normal;

  @include screen-medium {
    font-size: $type-size-root; } }

body {
  line-height: $type-leading-base; }
