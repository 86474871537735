.sharing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 30px;
  margin: 0 space(2);
  // border-top: 1px solid $color-black
  text-align: center;

  .grid {
    @include origin(center); }

  &.footer {
    @include screen-medium {
      display: inline-block;
      padding: 0;
      border: none; } }

  h4 {
    @include eyebrow;
    flex-basis: 100%;
    position: relative;
    top: 0px;
    margin-right: space(3);
    margin-bottom: space(0.5);
    font-size: $type-size-overlay-eyebrow;

    @include screen-large {
      flex-basis: auto;
      margin-right: space(1.5);
      margin-bottom: 0;
      font-size: $type-size-footer-copy;
      margin-right: space(3); } }

  .sharing-options {
    @include inline-list;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;
    cursor: default;

    @include screen-large {
      flex-wrap: nowrap;
      justify-content: center; }

    .sharing-option {
      margin-right: space(1.2);

      &.email {
        margin-left: 8px; }

      &:last-of-type {
        margin-right: 0; } }

    a {
      display: block;
      width: 1.5rem;
      height: 1.5rem;

      @include svg-flood {
        transition: 100ms linear fill;
        fill: $color-black; }

      &:hover {
        @include svg-flood {
          fill: $color-grey; } } } } }

main.single-film,
main.single-television {
  .sharing {
    display: block;
    width: 100%;
    justify-content: flex-start;
    margin: 0;
    border-top: 1px solid black;
    border-bottom: none;
    @include screen-medium {
      border-top: none;
      @include columns(0.51); } }

  .sharing-options {
    justify-content: center;
    @include screen-medium {
      justify-content: flex-start; } }

  .share-spacer {
    display: none;
    @include screen-medium {
      display: inherit;
      @include columns(0.34);
      padding-left: space(1); } }

  .share-wrapper {
    @include screen-small {
      padding: 0; }

    @include screen-medium {
      padding-left: space(1); } }

  h4 {
    flex-basis: auto;
    margin-right: 18px;
    margin-bottom: 0;
    margin-right: 36px; } }
