main.login {
  margin-top: 140px;

  @include screen-large {
    margin-top: 200px; } }

main.page {
  section.header-image {
    .responsive-image {
      padding-bottom: 100%;
      min-height: 100vh;

      @include screen-medium {
        padding-bottom: 56.25%;
        min-height: 0; }

      @include screen-large {
        padding-bottom: 40%; } } }

  &.jobs {
    section.header-image {
      .responsive-image {
        @include screen-small {
          padding-bottom: 0;
          min-height: unset; } }

      .responsive-image img {
        @include screen-small {
          position: unset; } } } }

  section.page-content {
    margin-top: var(--header-default-clearance);

    @include screen-small {
      padding-left: 18px;
      padding-right: 18px; }

    .sidebar {
      @include screen-large {
        @include columns(2/10); } }

    .primary-content {
      @include screen-medium {
        @include columns(4/6); }

      @include screen-large {
        @include columns(6/10); }

      &.narrow {
        @include screen-large {
          @include columns(4/10); } }

      h1 {
        @include headline($light: true); } } }

  &.about {
    // margin-top: space(13)

    .header-image {
      padding: 0; }

    .primary-content {
      @include screen-medium {
        width: 100%!important; } }

    .text-content {
      color: $color-black; } }

  &.error {

    @include screen-wide {
      padding-top: space(4); }

    .title {
      h1 {
        @include headline; } }

    .text-content {
      padding-top: 0; }

    .error-image {
      figure {
        @include filling-image-container;
        margin-bottom: space(1); }

      img {
        @include fade-in(false);

        &.loaded {
          opacity: 1; } } } }

  section.success-image,
  section.error-image {
    margin-top: space(4.5); } }

.related-products {
  &.explore {
    padding-bottom: 0;

    @include screen-medium {
      margin-top: space(4.5); }

    .button-container {
      @include dropdown-container;
      border-bottom: none;

      &.margin {
        margin: 0 18px;

        @include screen-medium {
          margin: 0; } }

      .button {
        @include eyebrow;
        @include dropdown-button;
        display: block;
        padding: space(2.2) 0;
        width: 100%;
        line-height: normal;
        margin: 0;
        border-top: none;

        @include screen-medium {
          display: none; } } }

    .secondary-features.full {
      @include screen-medium {
        margin: 0 -21px;
        width: auto; }

      .related-title {
        @include screen-medium {
          padding-left: 21px;
          padding-right: 21px; } }

      .feature-tile {
        @include screen-medium {
          padding: 0 21px !important; } } }

    .feature-image {
      .responsive-image {
        position: relative; } } } }
