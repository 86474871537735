.quantity-select {
  button {
    font-size: inherit; }

  .quantity-label {
    display: inline-block;

    &::after {
      content: ':'; } }

  .quantity-ui {
    display: inline-block;

    & > * {
      display: inline-block;
      width: space(2);
      text-align: center; } }

  .quantity-ui-button {
    border: none;
    padding: 0;
    line-height: inherit;

    &:hover {
      background-color: $color-grey-light; } }

  .quantity-submit {
    padding: 0;
    width: auto;
    color: $color-grey;
    text-decoration: underline;
    margin-left: space(1);

    &:hover {
      background-color: transparent;
      color: $color-black; } } }
