section.media {

  padding: 0 14px;

  @include screen-medium {
    margin-top: space(4.5);
    padding: 0 space(2); }

  .button-container {
    position: relative;
    display: block;
    height: auto;

    .button {
      @include eyebrow;
      @include dropdown-button;
      display: block;
      position: relative;
      padding: space(2.2) 0;
      width: 100%;
      line-height: normal;

      &.active {
        &:after {
          transform: rotate(180deg); } }

      @include screen-medium {
        display: none; } }

    a.button {
      cursor: pointer; } }

  .media-columns {
    @include grid($gutter-width: 40px);
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0;

    &.active {
      max-height: none;
      margin-bottom: space(3); }
    @include screen-medium {
      overflow: visible;
      max-height: none; }

    @include screen-wide {
      @include grid($gutter-width: 98px); } }

  .column {
    &.first {
      @include screen-medium {
        @include columns(3/5); }

      @include screen-large {
        @include columns(4/10);
        @include push(1/10);

        .block {
          &.large {}
          &.medium {
            @include push(1/4); }

          &.small {
            @include push(2/4); } } } }

    &.last {
      @include screen-medium {
        @include columns(2/5); }

      @include screen-large {
        @include columns(4/10); } } }


  .block {
    @include grid;
    margin-bottom: space(3);

    @include screen-medium {
      margin-bottom: space(1); }

    @include screen-large {
      margin-bottom: space(4.5); }

    // Layout
    &.small {
      @include screen-large {
        @include columns(2/4); } }

    &.medium {
      @include screen-large {
        @include columns(3/4); } }

    &.large {
      @include screen-large {
        @include columns(4/4); } }

    // Block Types

    &.image {
      figcaption {
        margin-top: space(1);
        margin-bottom: 0;

        p {
          font-size: 18px; }

        a {
          color: $color-black;

          &:hover {
            color: $color-grey; } } } }

    &.tweet {}
    &.website {}
    &.review {}
    &.headline {} } }
