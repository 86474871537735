.stills {
  @include fill;
  opacity: 0;
  transition: 150ms linear opacity 350ms;

  &.playing {
    transition: 150ms linear opacity;
    opacity: 1; }

  .still {
    @include fill;
    display: none; } }
