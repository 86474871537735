.media-tile {
  margin-bottom: space(3);
  color: $color-black;
  position: relative;
  @include columns(1);
  transition: margin 600ms ease-out;

  @include screen-medium {
    @include columns(4/12);
    margin-bottom: 50px;

    &:nth-child(3),
    &:nth-child(4),
    &:nth-child(9),
    &:nth-child(10) {
      .responsive-image {
        padding-bottom: 65.5%; } }

    &:nth-child(1),
    &:nth-child(6n + 1),
    &:nth-child(6n) {
      .responsive-image {
        padding-bottom: 117.5%; } }

    // &:nth-child(5)
    //   margin-top: -12.3%

    // &:nth-child(6)
    //   margin-top: -16.1%

    // &:nth-child(8)
 }    //   margin-top: -8.8%

  @include screen-large {
    margin-bottom: 80px; }

  &.mobile {
    @include screen-medium {
      display: none; } }

  &.inactive {
    a {
      @include screen-medium {
        cursor: default; } } }

  &.no-thumb {
    .responsive-image img {
      display: none; }

    .responsive-image {
      background-color: transparent; }

    figure .image {
      background-color: $color-black; } }

  &:hover {
    .overlay {
      @include screen-medium {
        opacity: 0.75; } } }

  a {
    display: block;
    transition: 100ms linear color; }

  // &.active
  //   a
  //     &:hover
  //       color: $color-grey

  .responsive-image {
    padding-bottom: (9/16) * 100%;

    @include screen-medium {
      padding-bottom: 77%; } }

  .image {
    position: relative;
    margin-bottom: 15px;

    @include screen-small {
      margin: 0 18px 10px 18px; }

    @include screen-large {
      margin-bottom: 20px; }

    .thumb.no-mobile {
      display: none;

      @include screen-medium {
        display: block; } }

    .mobile-thumb {
      display: block;

      @include screen-medium {
        display: none; } } }

  img {
    position: absolute;
    top: 0;
    left: 0;
    @include fade-in(false);

    &.loaded {
      opacity: 1; } }

  figcaption {
    padding: 0 18px;
    line-height: 18px;

    @include screen-small {
      display: flex;
      flex-direction: row-reverse; }

    @include screen-medium {
      padding: 0; } }

  h3 {
    margin-bottom: 3px;

    @include screen-small {
      flex: 1;
      margin-right: 20px; }

    @include screen-large {
      margin-bottom: 0; } }

  .overlay {
    @include fill;
    @include antialiased-type;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: rgba($color-black, 0.9);
    color: $color-white;
    padding: space(2);
    opacity: 0;
    transition: 100ms linear opacity;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: space(4.5);
      background-image: linear-gradient(transparent 0%, $color-black 90%); }

    h4 {
      @include eyebrow($overlay: true);
 }      // display: inline

    .datum {
      @include overlay-body;
      // display: inline
      @include antialiased-type; } }

  .overlay-data-group {
    margin-bottom: space(1);

    &.partner-logos {
      margin-bottom: 24px;

      .apple-original,
      .hulu,
      .prime-video,
      .netflix,
      .hbo-max,
      .apple-tv,
      .showtime,
      .peacock {
        min-height: 17px;
        background-size: contain;
        background-repeat: no-repeat; }

      .apple-original {
        background-image: url('/assets/images/AAOF-white.svg');
        margin: 10px 0 10px; }

      .hulu {
        background-image: url('/assets/images/hulu_logo-white.svg');
        margin: 10px 0 13px; }

      .prime-video {
        background-image: url('/assets/images/prime_video_logo-white.svg');
        margin: 10px 0 13px;
        min-height: 31px; }

      .netflix {
        background-image: url('/assets/images/netflix_logo-white.svg');
        margin: 10px 0 13px; }

      .hbo-max {
        background-image: url('/assets/images/max_logo-white.svg');
        margin: 10px 0 13px; }

      .apple-tv {
        background-image: url('/assets/images/apple_tv-white.svg');
        min-height: 18px; }

      .showtime {
        background-image: url('/assets/images/showtime-white.svg');
        min-height: 20px; }

      .peacock {
        background-image: url('/assets/images/peacock-white.svg');
        min-height: 20px; } } } }
