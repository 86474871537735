aside.shop-hud {
  .shop-hud-content-margin,
  .shop-hud-content-center {
    & > :last-child {
      margin-bottom: space(4.5); } }

  .shop-hud-content-margin {
    display: none;

    @include screen-large {
      display: block;
      @include columns(2/10); } }

  .shop-hud-content-center {
    margin-bottom: space(1);

    @include screen-large {
      margin-bottom: 0;
      @include columns(6/10); } }

  .shop-hud-checkout-progress {
    margin-bottom: space(2);

    .cart-hud {
      display: inline-block; }

    ol {
      display: inline-block;

      @include screen-medium {
        @include inline-list; } }

    .step {
      color: $color-grey;

      @include screen-medium {
        &:not(:last-child) {
          &::after {
            content: '>';
            margin: 0 space(0.5);
            color: $color-grey;
            font-weight: $type-weight-normal; } } }

      &.current {
        font-weight: $type-weight-medium;
        color: $color-black; }

      &.bag {
        margin-bottom: space(1);

        @include screen-medium {
          margin-bottom: 0; } } }

    a {
      &:hover {
        color: $color-black; } } } }
