
@mixin fade-in($initial-visibility: true) {
  transition: 150ms linear opacity;

  @if not $initial-visibility {
    opacity: 0; } }

@mixin inline-list {
  li {
    display: inline-block; } }

@mixin margin-auto {
  margin: 0 auto; }

@mixin dropdown-container {
  position: relative;
  display: block;
  height: auto;
  border-top: 1px solid $color-black;
  border-bottom: 1px solid $color-black;
  padding: 0;

  @include screen-medium {
    border: none; }

  &:first-of-type {
    border-bottom: none; } }

@mixin dropdown-button {
  &:after {
    content: '';
    position: absolute;
    width: 18px;
    height: 18px;
    right: space(2.2);
    transform-origin: center center;
    background-image: url(inline-asset('web/assets/images/icons/dropdown-glyph.svg'));
    background-position: center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    transition: transform 250ms ease-out; }

  &.active {
    &:after {
      transform: rotate(180deg); } } }

@mixin dropdown-filter {
  @include dropdown-button;
  display: block;
  position: relative;
  width: 100%;
  padding: space(1.6) space(2.2);
  border: 1px solid $color-black;
  line-height: normal;

  @include screen-medium {
    display: none; }

  a.button {
    cursor: pointer;
    transition: 100ms linear color, 100ms linear background-color; } }

@mixin controls {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  max-height: 0;
  list-style-type: none;
  padding: 0 space(2.2);
  margin: 0;
  background-color: $color-white;
  border-left: 1px solid $color-black;
  border-right: 1px solid $color-black;
  transition: max-height 150ms ease-out;
  z-index: 1;

  @include screen-medium {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 0;
    border: none;
    overflow: visible;
    max-width: none;
    max-height: none; }

  &.active {
    overflow: visible;
    max-height: 280px;
    padding: 0 space(2.2) space(2.2) space(2.2);
    border-bottom: 1px solid $color-black; }

  label:first-of-type {
    padding-top: space(2.2); }

  a {
    color: $color-grey;
    border-bottom: 1px solid $color-grey;
    transition: 100ms linear color, 100ms linear border-bottom-color; }

  label {
    @include eyebrow;
    display: block;
    position: relative;
    margin-bottom: space(2);
    cursor: pointer;

    @include screen-medium {
      display: inline-block;
      margin-left: space(3);
      margin-bottom: 0; } }

  label:last-of-type {
    margin-bottom: 0; }

  input {
    position: absolute;
    opacity: 1;
    padding: 0; }

  span {
    position: absolute;
    width: 100%;
    left: 0; }

  input:checked {
    @include screen-medium {
      border-bottom: 0.5px solid $color-black; } } }

@mixin comma-separated-list($last: false) {
  @include inline-list;

  li {
    &::after {
      content: ', ';
      display: inline; }

    @if $last {
      &:nth-last-of-type(2)::after {
        content: " #{$last} "; } }

    &:last-of-type::after {
      display: none; } } }

@mixin fill($position: absolute) {
  position: $position;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

@mixin filling-image-container {
  position: relative;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none; } }

@mixin svg-flood {
  path,
  rect,
  circle,
  polygon {
    @content; } }

@mixin image-button {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1; }

@mixin icon($filename, $space: 2, $position: after) {
  &::#{$position} {
    content: '';
    width: 1em;
    height: 1em;
    display: inline-block;
    vertical-align: -17%;
    background-size: 18px 18px;
    background-position: center center;
    background-image: url(inline-asset('/web/assets/images/icons/#{$filename}.svg'));

    @if $position == after {
      margin-left: space($space); }
    @else {
      margin-right: space($space); } } }

@mixin input {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left; }

@mixin label {
  font-weight: $type-weight-normal;
  font-family: $type-family-sans;
  letter-spacing: 0.8px;
  font-size: 13px; }

