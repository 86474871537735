// FONT VARIABLES
///////////////////////////////////////////////////////////
$font-dir: '../fonts/' !default;

$font-name-sans: 'NB International Web';
$font-name-mono: 'NB International Mono Web';

// FONT-FACE DELARATIONS
///////////////////////////////////////////////////////////
// mixin to include fonts, assuming each font has the same file
// names for each format (except for the extension)
@mixin font-face($filename, $family, $weight, $style: normal, $unicode: false) {
    @font-face {
        font-family: $family;
        font-style: $style;
        font-weight: $weight;
        src: url('#{$font-dir}#{$filename}.eot');
        src: url('#{$font-dir}#{$filename}.eot?#iefix') format('embedded-opentype'),
            url('#{$font-dir}#{$filename}.woff2') format('woff2'),
            url('#{$font-dir}#{$filename}.woff') format('woff'),
            url('#{$font-dir}#{$filename}.ttf') format('truetype');
        
        @if $unicode {
            unicode-range: $unicode;
        }
    }
}

// nb international (2021 version)
// - regular
@include font-face(nbinternationalproreg-webfont, $font-name-sans, 400);
@include font-face(nbinternationalproita-webfont, $font-name-sans, 400, italic);

// - medium
@include font-face(nbinternationalpromed-webfont, $font-name-sans, 500);
@include font-face(nbinternationalpromedita-webfont, $font-name-sans, 500, italic);

// - bold
@include font-face(nbinternationalprobol-webfont, $font-name-sans, 700);
@include font-face(nbinternationalprobolita-webfont, $font-name-sans, 700, italic);

// - mono
@include font-face(nbinternationalpromono-webfont, $font-name-mono, 400);