.ways-to-watch {
  position: relative;

  .button {
    @include eyebrow;
    display: block;
    padding: 22px 22px 21px;
    border: 1px solid $color-black;
    line-height: normal; }

  a.button {
    cursor: pointer;
    transition: 100ms linear color, 100ms linear background-color;

    // &:hover
    //   color: white
 }    //   background-color: $color-black

  .watch-now-links {
    @include eyebrow;
    position: absolute;
    overflow: hidden;
    width: 100%;
    max-width: 380px;
    max-height: 0;
    list-style-type: none;
    padding: 0 space(2.2);
    margin: 0;
    background-color: $color-white;
    border-left: 1px solid $color-black;
    border-right: 1px solid $color-black;
    transition: max-height 150ms ease-out;
    z-index: 1;

    @include screen-ipad {
      width: 85%; }

    @include screen-wide {
      width: 100%; }

    &.active {
      overflow: visible;
      max-height: 500px;
      padding: 0 space(2.2) space(2.2) space(2.2);
      border-bottom: 1px solid $color-black;

      @include screen-large {
        max-height: 500px; }

      li {
        opacity: 1; } }

    li {
      opacity: 0;
      transition: opacity 50ms ease-out 75ms; }

    li:first-of-type {
      padding-top: space(2.2); }

    a {
      color: $color-black;

      &:hover {
        border-bottom-color: $color-black; }

      @include screen-medium {
        border-bottom: 1px solid transparent; } } } }
