main.zine {
  section.header {
    .pagination {
      .prev {
        background-image: url(inline-asset('/web/assets/images/icons/arrow-left-small-black.svg'));

        @include screen-medium {
          background-image: url(inline-asset('/web/assets/images/icons/arrow-left-black.svg')); } }

      .next {
        background-image: url(inline-asset('/web/assets/images/icons/arrow-right-small-black.svg'));

        @include screen-medium {
          background-image: url(inline-asset('/web/assets/images/icons/arrow-right-black.svg')); } } } } }
