section.staff-filters {
  form {
    select {
      padding-right: 50px; }

    button {
      &.button-with-arrow {
        &::after {
          margin-left: 0; } } } }

  .filter-groups {
    padding-top: 110px;
    align-items: center; }

  fieldset {
    margin-bottom: 1rem;

    @include screen-large {
      width: auto; }
 }    //   +columns(2/10)

  .dropdown-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: auto; }

  label {
    margin-bottom: 0.5rem; }

  label,
  select,
  button {
    font-size: $type-size-small;

    @include screen-medium {
      font-size: $type-size-normal; } }

  button {
    @include eyebrow;
    color: white;
    background-color: black;
    height: 59px;
    padding: 19px 50px; }

  // .sort,
  // .birthday,
  // .department
  //   +screen-medium
  //     +columns(1/3)

  //   +screen-large
  //     +columns(2/10)

  .locations {
    width: auto;
    // +screen-medium
    //   +columns(4/10)

    // +screen-large
    //   +columns(3/10)

    label {
      @include screen-medium {
        display: flex;
        justify-content: center;
        // +columns(2/4)
        width: auto; }

      // +screen-large
 } }      //// +columns(1.5/3)

  .submit {
    @include screen-medium {
      @include columns(2/10);
      // +push(4/10)
      margin-left: auto;

      @include columns(1/10);
      @include push(0); }

    span {
      visibility: hidden; } }

  span.label {
    display: none; } }

section.profiles {
  padding-top: 3rem;
  padding-bottom: 4rem;

  .staff-tile {
    margin-bottom: 4rem;

    @include screen-small {
      @include columns(12/12); }

    @include screen-medium {
      @include columns(6/12); }

    @include screen-wide {
      @include columns(4/12); } }

  .name {
    margin-bottom: 1rem; }

  .polaroid {
    @include columns(4/6);
    padding-right: 1rem;
    margin-bottom: 1rem;

    @include screen-medium {
      @include columns(2.5/6); }

    img {
      border-radius: 2px;
      box-shadow: 0 0 5px rgba($color-grey-light, 0.75); } }

  .details {
    // padding-top: 4%
    font-size: 12px;
    line-height: 18px;

    @include screen-medium {
      @include columns(3.5/6); }

    a {
      text-decoration: underline; }

    h2 {
      font-size: $type-size-normal;
      line-height: inherit; }

    .ask-me-about {

      .ask-me-about__items {

        div {
          padding-left: 30px; } } }

    .datum {}

    .label {
      font-weight: $type-weight-medium;
      display: inline-block;

      // &::after
 }      //   content: ':'

    .office-list {
      display: inline-block;
      @include inline-list; }

    .bio {
      margin-top: 0.5em;
      color: $color-grey;

      p + p {
        margin-top: 0.5em; } } } }
