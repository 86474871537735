.greenhouse-form {
  margin-bottom: 47px;

  .question-label {
    @include label;
    padding: 19px 0 1px; }

  .question {
    &.single-field {
      .question-label {
        display: none; }

      &.single-textarea {
        margin-top: 2px; } }

    &.multiple-fields {
      label {
        @include screen-medium {
          padding-top: 0; } }

      span.label {
        display: none; } }

    &.required {
      .question-label,
      span.label {
        &::after {
          display: inline-block;
          content: '*';
          margin-left: 0.25em; } } } }

  .field-container {
    &.checkboxes {
      .label {
        padding-top: space(2); }

      .options {
        display: flex;
        flex-wrap: wrap; }

      .option {
        width: 100%;

        @include screen-medium {
          width: 50%;
          padding-right: space(1); } }

      label {
        padding-top: space(1); } }

    &.submit {} } }
