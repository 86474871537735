main.films,
main.shows,
main.staff, {

  .group-name {
    @include headline($light: true);
    margin-bottom: space(2);

    @include screen-medium {
      margin-bottom: 15px; } }

  .profiles,
  .features {
    .section-header {
      margin-bottom: 1px;

      @include screen-medium {
        margin-bottom: 14px; }

      @include screen-wide {
        margin-bottom: 24px; } } }

  .profiles,
  .feature-group {
    & + .feature-group {
      // Account for the space below each media tile
      padding-top: space(2.5); }

    .section-header {
      padding: 0 18px;

      @include screen-medium {
        padding: 0; }

      .title {
        @include columns(1); } } } }


main.films,
main.shows {
  margin-top: var(--header-default-clearance);

  .features,
  .all-films,
  .all-television {
    padding: 0;

    @include screen-medium {
      padding: 0 42px; }

    h3 {
      @include headline-3; }

    h6 {
      @include eyebrow;
      margin-bottom: 3px;
      color: $color-grey;

      &.date {
        @include eyebrow($mono: true); } } }

  .all-films,
  .all-television {
    border-top: 1px solid $color-black;
    padding-top: space(2.5);

    @include screen-medium {
      border-top: none; } }

  .media-tiles {
    @include fade-in;
    @include grid-gut(30px);
    margin-bottom: 33px;

    @include screen-medium {
      margin-bottom: 0; }

    &.loading {
      opacity: 0.75; } }

  .section-header {
    position: relative;
    margin-bottom: space(2);
    align-items: center;

    @include screen-medium {
      margin-bottom: 13px; }

    @include screen-wide {
      margin-bottom: 24px; }

    .title {
      @include columns(1/2);
      flex-grow: 1;

      @include screen-medium {
        @include columns(3/9); }

      &.all {
        margin: 0 18px;

        @include screen-medium {
          margin: 0; } } }

    .active-sorting {
      @include eyebrow;
      @include dropdown-filter; }

    .button-container {
      margin: 0 18px;
      color: $color-black;

      @include screen-medium {
        display: none; } }

    .controls {
      @include controls;
      display: inline-block;
      width: calc(100% - 48px);
      max-width: none;
      left: 24px;

      @include screen-medium {
        left: 0; }

      label:first-of-type {
        @include screen-medium {
          padding-top: 0; } } }

    .controls-wrapper {
      position: relative;
      @include columns(1);
      text-align: left;

      @include screen-medium {
        padding-top: 8px;
        text-align: right;
        @include columns(1/2); }

      .sort-title {
        @include eyebrow;
        display: none;
        color: $color-grey;

        @include screen-medium {
          display: inline-block; } } } }

  .show-more-wrapper {
    @include show-more-wrapper; } }

main.shows {
  .section-header {
    @include screen-medium {
      margin-bottom: space(2); } } }
