.block.instagram {
  figure {
    // padding: space(1)
    position: relative;

    // +screen-medium
 }    //   padding: space(2)

  // .icon
  //   position: absolute
  //   top: space(1)
  //   right: space(1)
  //   width: space(2)
  //
  //   svg
  //     fill: $color-grey
  //
  //   +screen-medium
  //     top: space(2)
  //     right: space(2)

  figcaption {
    margin-top: space(2);

    a,
    p {
      display: inline;
      text-transform: none;
      font-size: 18px;
      line-height: 1.4; }

    a {
      border-bottom: 1px solid black;

      &:hover {
        color: $color-grey; } } } }
