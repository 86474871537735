main.shop {
  button {}

  form {
    .form-section {
      margin-bottom: space(4.5);

      @include screen-medium {
        margin-bottom: space(8); }


      &:last-child {
        margin-bottom: 0; } }

    .field-group {
      margin-bottom: space(2);

      &:last-child {
        margin-bottom: 0; }

      &.box {
        border: 2px solid $color-black;
        margin-bottom: space(2);

        label {
          margin: 0;
          padding: space(0.5) space(0.75);

          &:not(:last-child) {
            border-bottom: inherit; } } }

      &.submit {
        label {
          display: inline-block; } } }

    .instructions {
      margin-bottom: space(2); }

    select {
      width: auto; }

    input[type="text"],
    input[type="email"] {
      border: none;
      border-bottom: 2px solid $color-black;
      padding-left: 0;
      padding-right: 0;

      &:disabled {
        border-bottom-color: $color-grey-mid; } }

    input[type="radio"] {
      width: 16px;
      height: 16px;
      padding: 0;
      border-radius: 100%;
      position: relative;
      display: inline-block;
      vertical-align: middle;

      &:checked {
        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 2px;
          left: 2px;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: $color-black; } } }

    input[type="submit"],
    button[role="submit"] {} }

  .feature-row {
    &.takeover + .feature-row {
      margin-top: space(4.5); }

    &:last-child {
      .product-tile {
        @include screen-medium {
          margin-bottom: 0; }

        &:last-child {
          margin-bottom: 0; } } }


    // Single, Split, Grid Blocks
    .product-tile {
      display: block;
      margin-bottom: space(3);
      transition: 100ms linear color;
      color: $color-grey;

      @include screen-medium {
        margin-bottom: space(1);
        color: $color-white; }

      &:hover {
        color: $color-black;

        @include screen-medium {
          color: $color-white; } }

      .tile-content {
        position: relative;
        overflow: hidden;

        @include screen-medium {
          height: 50vh; } }

      .product-image {
        padding-bottom: 75%;
        position: relative;

        @include screen-medium {
          padding-bottom: 0;
          @include fill; } }

      .responsive-image {
        @include fill; }

      .product-info {
        margin-top: space(1);

        @include screen-medium {
          @include fill;
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 0;
          text-align: center;
          color: $color-white;
          font-size: $type-size-medium;
          line-height: $type-leading-medium;
          font-weight: $type-weight-normal;
          opacity: 0;
          transition: 0.25s linear opacity;
          background-color: rgba($color-black, 0.55); }

        &:hover {
          opacity: 1; } }

      .product-title {
        font-weight: $type-weight-medium;

        @include screen-medium {
          font-weight: $type-weight-normal; } } }

    &.single {
      .tile-content {}

      .grid {
        // This height alternative is available for `single` and `split` blocks.
        &.tall {
          .tile-content {
            @include screen-medium {
              height: 75vh; } } } } }

    &.split {
      .tile-content {}

      .grid {
        // Layout options
        &.tall {
          .tile-content {
            @include screen-medium {
              height: 75vh; } } }

        &.left {
          .product-tile:first-child {
            @include screen-medium {
              @include columns(6/10); } }

          .product-tile:last-child {
            @include screen-medium {
              @include columns(4/10); } } }

        &.right {
          .product-tile:first-child {
            @include screen-medium {
              @include columns(4/10); } }

          .product-tile:last-child {
            @include screen-medium {
              @include columns(6/10); } } }

        &.center {
          .product-tile {
            @include screen-medium {
              @include columns(5/10); } } } } } } }
