.newsletter-signup,
.newsletter-confirmation {

  @include grid;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: space(2);

  &.success {
    label.email {
      display: none; }

    label.submit {
      display: none; } }

  input,
  textarea,
  button {
    font-size: $type-size-normal; }

  h1 {
    @include headline; }

  label {
    margin-bottom: 0; }

  label.email {
    width: 100%;
    padding: 0;
    margin: 0;

    @include screen-medium {
      width: 100%; }

    input {
      @include eyebrow;
      @include input;
      border: 1px solid $color-white;
      padding: 22px 18px;
      text-transform: none;

      @include screen-medium {
        font-size: $type-size-overlay-eyebrow; }

      @include screen-wide {
        font-size: $type-size-footer-copy; } }

    input::placeholder {
      color: $color-grey;
      font-size: 14px;
      text-transform: none;

      @include screen-medium {
        font-size: $type-size-footer-copy; }

      @include breakpoint($max-width: 371px) {
        font-size: 8px; } } }

  label.submit {
    position: relative;
    width: auto;
    margin: 0;
    padding: 21px 22px 22px;
    color: $color-black;
    background-color: $color-white;
    border: 1px solid $color-white;

    @include screen-medium {
      padding: 22px 64px; }

    span.label {
      visibility: hidden; }

    input {
      @include eyebrow;
      display: block;
      position: relative;
      width: 100%;
      height: auto;
      padding: 0;
      color: black;
      text-align: center;
      font-size: 14px;

      @include screen-medium {
        font-size: $type-size-overlay-eyebrow; }

      @include screen-wide {
        font-size: $type-size-footer-copy; } } }

  .messages {
    display: none;
    margin-top: space(0.5);
    text-align: left;

    span {
      display: none; }

    &.error {
      .error {
        display: block; } }

    &.success {
      .success {
        display: block; } } } }

.newsletter-signup-page-form {

  input {

    &#newsletter-signup {
      border-color: black; }

    &[type="submit"] {
      color: white !important; } }

  label {

    &.newsletter-signup-field__label.textinput__label {
      color: black; }

    &.submit.with-data {
      background-color: black;
      border-color: black; } } }
