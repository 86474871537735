// Sizes

@mixin breakpoint($min-width: false, $max-width: false, $max-height: false) {
  @if $min-width and $max-width {
    @media screen and (min-width: $min-width) and (max-width: $max-width) {
      @content; } }

  @elseif $min-width {
    @media screen and (min-width: $min-width) {
      @content; } }

  @elseif $max-width {
    @media screen and (max-width: $max-width) {
      @content; } }

  @elseif $max-height {
    @media screen and (max-height: $max-height) {
      @content; } } }

@mixin screen-small {
  @include breakpoint($max-width: $breakpoint-small) {
    @content; } }

@mixin screen-ipad {
  @include breakpoint($min-width: 769px) {
    @content; } }

@mixin screen-medium {
  @include breakpoint($min-width: $breakpoint-small) {
    @content; } }

@mixin screen-large {
  @include breakpoint($min-width: $breakpoint-medium) {
    @content; } }

@mixin screen-wide {
  @include breakpoint($min-width: $breakpoint-large) {
    @content; } }


// shop breakpoints
@mixin screen-shop-phone {
  @include breakpoint($max-width: 666px) {
    @content; } }

@mixin screen-shop-tablet {
  @include breakpoint($min-width: 667px, $max-width: 1150px) {
    @content; } }

@mixin screen-shop-full {
  @include breakpoint($min-width: 1151px) {
    @content; } }

@mixin screen-shop-full-xl {
  @include breakpoint($min-width: 1550px) {
    @content; } }

@mixin screen-shop-full-xxl {
  @include breakpoint($min-width: 2000px) {
    @content; } }


// Pixel Dentisites

@mixin hi-dpi {
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    @content; } }
