main.jobs {
  padding-bottom: 0;

  .page-header {
    h1 {
      @include headline;

      @include screen-medium {
        margin-bottom: space(4.5); } } }

  .header-image {
    // margin-bottom: space(3)
    padding: 0; }

  .content-wrapper {
    // padding: 0 17px
    margin-top: space(4.5);
    margin-bottom: space(3);

    @include screen-medium {
      // padding: 0 37px
      margin-top: space(5); } }

  .locations {
    @include grid($gutter-width: 36px); }

  .location {
    margin-bottom: space(3);

    @include screen-medium {
      @include columns(1/3); }

    h2 {
      @include eyebrow;
      margin-bottom: 10px; }

    .message {
      margin-bottom: space(4.5); } }

  .location-positions-list {
    .position {
      margin-bottom: space(1.75);

      // +screen-large
 }      //   margin-bottom: 4px

    h2 {
      margin-bottom: space(1); }

    .position-link {
      display: block; }

    .position-title {
      @include headline-3;
      display: inline-block;
      margin-right: 0.25em;
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 0.6; } }

    .location-list {
      font-weight: $type-weight-normal;
      display: inline-block;
      @include inline-list; }

    .position-learn-more {
      a {
        color: $color-grey;
        text-decoration: underline; } } }

  .sharing {
    .sharing-options {
      flex-wrap: nowrap;

      h4 {
        margin-right: 36px;
        margin-bottom: 0;
        flex-basis: auto; } } } }
