/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: "NB International Web";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/nbinternationalproreg-webfont.eot");
  src: url("../fonts/nbinternationalproreg-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalproreg-webfont.woff2") format("woff2"), url("../fonts/nbinternationalproreg-webfont.woff") format("woff"), url("../fonts/nbinternationalproreg-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Web";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/nbinternationalproita-webfont.eot");
  src: url("../fonts/nbinternationalproita-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalproita-webfont.woff2") format("woff2"), url("../fonts/nbinternationalproita-webfont.woff") format("woff"), url("../fonts/nbinternationalproita-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Web";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/nbinternationalpromed-webfont.eot");
  src: url("../fonts/nbinternationalpromed-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalpromed-webfont.woff2") format("woff2"), url("../fonts/nbinternationalpromed-webfont.woff") format("woff"), url("../fonts/nbinternationalpromed-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Web";
  font-style: italic;
  font-weight: 500;
  src: url("../fonts/nbinternationalpromedita-webfont.eot");
  src: url("../fonts/nbinternationalpromedita-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalpromedita-webfont.woff2") format("woff2"), url("../fonts/nbinternationalpromedita-webfont.woff") format("woff"), url("../fonts/nbinternationalpromedita-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Web";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/nbinternationalprobol-webfont.eot");
  src: url("../fonts/nbinternationalprobol-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalprobol-webfont.woff2") format("woff2"), url("../fonts/nbinternationalprobol-webfont.woff") format("woff"), url("../fonts/nbinternationalprobol-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Web";
  font-style: italic;
  font-weight: 700;
  src: url("../fonts/nbinternationalprobolita-webfont.eot");
  src: url("../fonts/nbinternationalprobolita-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalprobolita-webfont.woff2") format("woff2"), url("../fonts/nbinternationalprobolita-webfont.woff") format("woff"), url("../fonts/nbinternationalprobolita-webfont.ttf") format("truetype");
}

@font-face {
  font-family: "NB International Mono Web";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/nbinternationalpromono-webfont.eot");
  src: url("../fonts/nbinternationalpromono-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/nbinternationalpromono-webfont.woff2") format("woff2"), url("../fonts/nbinternationalpromono-webfont.woff") format("woff"), url("../fonts/nbinternationalpromono-webfont.ttf") format("truetype");
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  appearance: none;
  line-height: 25px;
  outline: none;
}

select::-ms-expand {
  display: none;
}

label {
  display: block;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
}

body {
  position: relative;
  transition: 0.25s cubic-bezier(0, 1, 0.25, 1) right;
  right: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  letter-spacing: -0.005em;
}

@media screen and (max-width: 640px) {
  body.mobile-menu-open {
    position: fixed;
  }
}

@media screen and (min-width: 640px) {
  body.mobile-menu-open {
    top: auto !important;
  }
}

html.featured-open,
body.featured-open {
  overflow: hidden;
  height: 100%;
}

html.featured-open.mobile-menu-open,
body.featured-open.mobile-menu-open {
  height: auto;
}

h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  h2 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  h2 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

a {
  text-decoration: inherit;
  color: inherit;
}

sup {
  position: relative;
  vertical-align: super;
  font-family: "NB International Mono Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.065em;
}

@media screen and (min-width: 1024px) {
  sup {
    margin-left: -7.2px;
    top: -10px;
  }
}

@media screen and (min-width: 1300px) {
  sup {
    margin-left: -12px;
    top: -14px;
  }
}

p {
  font-size: 27px;
  line-height: 1.33;
  letter-spacing: -0.0125em;
}

@media screen and (min-width: 640px) {
  p {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  p {
    font-size: 27px;
  }
}

::-moz-selection {
  background: black;
  color: white;
}

::selection {
  background: black;
  color: white;
}

form input,
form textarea,
form button {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
}

form input:-webkit-autofill,
form textarea:-webkit-autofill,
form button:-webkit-autofill {
  background-color: black;
  color: white;
  transition: all 5000s ease-in-out 0s;
}

form input:disabled,
form textarea:disabled,
form button:disabled {
  color: #cacaca;
  border-color: #cacaca;
}

form input:disabled:active,
form textarea:disabled:active,
form button:disabled:active {
  color: #cacaca;
}

form ::placeholder {
  color: black;
}

form button,
form input[type="submit"],
form input[type="button"] {
  cursor: pointer;
  padding: 4px 12px;
  width: auto;
  display: inline-block;
}

form button:active,
form input[type="submit"]:active,
form input[type="button"]:active {
  color: inherit;
}

form button:disabled,
form input[type="submit"]:disabled,
form input[type="button"]:disabled {
  cursor: default;
}

form button.button-with-arrow,
form input[type="submit"].button-with-arrow,
form input[type="button"].button-with-arrow {
  padding-right: 9px;
}

form button.button-with-arrow::after,
form input[type="submit"].button-with-arrow::after,
form input[type="button"].button-with-arrow::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

form button.button-with-arrow::after,
form input[type="submit"].button-with-arrow::after,
form input[type="button"].button-with-arrow::after {
  float: right;
  margin-top: 2px;
}

form button.button-with-arrow:disabled::after,
form input[type="submit"].button-with-arrow:disabled::after,
form input[type="button"].button-with-arrow:disabled::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvLWdyZXktbWlkPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICNjYWNhY2E7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

form button.search {
  padding-left: 3rem;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPnNlYXJjaDwvdGl0bGU+CiAgPHBhdGggZD0iTTEwLDZBMiwyLDAsMSwxLDgsOGEyLjAwMjI3LDIuMDAyMjcsMCwwLDEsMi0ybTAtMmE0LDQsMCwxLDAsNCw0LDQsNCwwLDAsMC00LTRaIi8+CiAgPGxpbmUgeDE9IjcuNTAwMDEiIHkxPSIxMC41IiB4Mj0iNCIgeTI9IjE0IiBzdHlsZT0ic3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  background-size: 18px 18px;
  background-position: 1rem center;
  background-repeat: no-repeat;
}

form select {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
  padding: 4px 12px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-size: 18px 18px;
  background-position: 95% center;
  background-position: right 25px center;
  background-repeat: no-repeat;
  padding: 19.2px 136.4px 19.2px 26.4px;
  border: 1px solid #000;
  width: auto;
}

@media screen and (min-width: 640px) {
  form select {
    font-size: 15px;
  }
}

form select:-webkit-autofill {
  box-shadow: 0 0 0 1000px #0FF inset;
  transition: background-color 5000s ease-in-out 0s;
}

form input[type="text"],
form input[type="email"] {
  border: none;
  border-bottom: 1px solid black;
  padding-left: 0;
  padding-right: 0;
}

form input[type="text"]:focus + span.label::before,
form input[type="email"]:focus + span.label::before {
  opacity: 0;
}

form textarea {
  padding: 19px;
  border: 1px solid black;
  height: 140px;
  resize: none;
}

form input[type="checkbox"] {
  cursor: pointer;
  display: inline-block;
  width: 27px;
  height: 27px;
  border: 1px solid black;
  appearance: none;
  vertical-align: middle;
  line-height: 1rem;
  padding: 0;
  background-size: 27px 27px;
  background-position: center center;
  background-repeat: no-repeat;
}

form input[type="checkbox"]:checked {
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNyAyNyI+CiAgPHRpdGxlPmNoZWNrLXNtYWxsPC90aXRsZT4KICA8bGluZSB4Mj0iMjciIHkyPSIyNyIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjMDAwO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDFweCIvPgogIDxsaW5lIHkxPSIyNyIgeDI9IjI3IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMXB4Ii8+Cjwvc3ZnPgo=");
}

form label {
  margin-bottom: 2rem;
}

form label:last-child {
  margin-bottom: 0;
}

form label a {
  color: #888888;
  text-decoration: underline;
}

form label.required .label::after {
  display: inline-block;
  content: '*';
  margin-left: 0.25em;
}

form label.static-label .label {
  font-size: 10px;
  top: 0;
}

form label.special-label.with-data .label, form label.static-label.with-data .label {
  display: none !important;
}

form label.special-label .label, form label.static-label .label {
  font-weight: 400;
  margin-bottom: 0;
  color: #888888;
}

form label.special-label {
  padding-top: 24px;
  position: relative;
}

form label.special-label .label {
  text-transform: none;
  position: absolute;
  top: 19px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
}

form label.special-label .label,
form label.special-label input,
form label.special-label textarea {
  font-weight: 400;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  font-size: 13px;
}

form span.label {
  font-weight: 400;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  font-size: 13px;
  display: block;
  margin-bottom: 12px;
}

form .input-type-textarea.special-label .label {
  white-space: pre-wrap;
}

form .input-type-textarea span.label {
  margin-top: 20px;
  left: 20px;
}

form span.inline-label {
  font-weight: 400;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  font-size: 13px;
  margin-left: 0.5em;
  display: inline;
  vertical-align: middle;
}

form .field-container.required span.label::after {
  display: inline-block;
  content: '*';
  margin-left: 0.25em;
}

form .textinput__label {
  font-size: 13px;
  position: absolute;
  top: 52%;
  left: 18px;
  z-index: 1;
  transition-duration: 75ms;
  transition-property: transform;
  transition-timing-function: ease-in;
  color: #f1f1f1;
  pointer-events: none;
  text-transform: uppercase;
  transform: translateY(-50%);
  transform: scale(0.7) translateY(-2em) translateY(-50%);
  transform-origin: left center;
}

form .instructions {
  color: #888888;
  margin-bottom: 12px;
}

form .messages {
  font-size: 10px;
}

@media screen and (min-width: 640px) {
  form .messages {
    font-size: 15px;
  }
}

form .errors {
  display: none;
  margin-top: 12px;
  font-style: italic;
  color: red;
}

form .errors.with-errors {
  display: block;
}

form .form-row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

form .form-row > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

form .field-container label {
  padding-top: 24px;
}

@media screen and (min-width: 640px) {
  form .field-container.half {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  form .field-container.third {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  form .field-container.quarter {
    width: 25%;
    margin-left: 0;
    margin-right: 0;
  }
}

form .field-container.file .file-field-wrapper {
  width: 0;
  height: 0;
  overflow: hidden;
}

form .field-container.file .file-control {
  font-size: 18px;
  padding: 0;
  border: none;
  color: #888888;
  text-decoration: underline;
  text-align: left;
}

form .field-container.file .file-control.file-control-remove {
  display: none;
}

form .field-container.file label.with-file .file-control-add {
  display: none;
}

form .field-container.file label.with-file .file-control-remove {
  display: block;
}

form .field-container.checkbox .label {
  display: none;
}

form .field-container.checkbox input {
  margin-right: 6px;
}

form .field-container.checkbox .instructions {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
  color: black;
}

form .field-container.checkbox_group .label {
  padding-top: 24px;
}

form .field-container.checkbox_group .options {
  display: flex;
  flex-wrap: wrap;
}

form .field-container.checkbox_group .option {
  width: 100%;
}

@media screen and (min-width: 640px) {
  form .field-container.checkbox_group .option {
    width: 50%;
    padding-right: 12px;
  }
}

form .field-container.checkbox_group label {
  padding-top: 12px;
}

form .field-container.submit {
  pointer-events: none;
}

form .field-container.submit button {
  pointer-events: auto;
}

form .field-container.submit button::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

form .field-container.submit button:disabled::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvLWdyZXktbWlkPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICNjYWNhY2E7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

form.dark input,
form.dark textarea {
  border-color: white;
  color: inherit;
}

form.dark ::placeholder {
  color: white;
}

form.dark button,
form.dark input[type="submit"],
form.dark input[type="button"] {
  color: inherit;
  border-color: transparent;
}

.site-container {
  position: relative;
  transition: 0.25s cubic-bezier(0, 1, 0.6, 1) transform;
}

section {
  padding: 0 24px;
}

section.home-content, section.hero-media {
  padding: 0;
}

@media screen and (min-width: 640px) {
  section {
    padding: 0 24px;
  }
}

main {
  display: block;
  padding-bottom: 54px;
}

.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

.grid > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

img,
svg {
  display: block;
  max-width: 100%;
  width: 100%;
}

html {
  font-size: 16px;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
}

@media screen and (min-width: 640px) {
  html {
    font-size: 15px;
  }
}

body {
  line-height: 25px;
}

html {
  --header-padding-horiz: 42px;
  --header-padding-top: calc(3vw * 1.5);
  --header-padding-bottom: 24px;
  --header-logo-width: 66px;
  --header-logo-height: calc(var(--header-logo-width) * 0.4153);
  --header-search-width: 22px;
  --header-approx-height: calc(var(--header-padding-top) + var(--header-padding-bottom) + var(--header-logo-height));
  --header-default-clearance: 108px;
}

@media screen and (max-width: 640px) {
  html {
    --header-padding-horiz: 18px;
  }
}

@media screen and (min-width: 640px) {
  html {
    --header-default-clearance: 148px;
  }
}

@media screen and (min-width: 1300px) {
  html {
    --header-default-clearance: 196px;
  }
}

@media screen and (max-width: 666px) {
  html {
    --header-padding-top: calc(3vw * 2.5);
    --header-logo-width: 49px;
    --header-search-width: 17.5px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1150px) {
  html {
    --header-logo-width: 57.75px;
    --header-search-width: 19.25px;
  }
}

@media screen and (min-width: 1550px) {
  html {
    --header-logo-width: 72.6px;
  }
}

@media screen and (min-width: 2000px) {
  html {
    --header-logo-width: 85.8px;
  }
}

header {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: white;
  user-select: none;
  transition: background-color 0.15s linear, transform 0.2s ease-in-out;
}

header .grid {
  align-items: top;
  position: relative;
  margin-left: 0;
  margin-right: 0;
}

header.hidden {
  transform: translate3d(0, -100%, 0);
}

header.hero:not(.sticky) {
  width: 100%;
  background-color: transparent;
}

header.hero:not(.sticky)::after {
  border-top-color: white;
}

header.hero:not(.sticky) nav a {
  color: white;
}

header.hero:not(.sticky) nav a:hover {
  border-bottom: 1px solid white;
}

header.hero:not(.sticky) section path,
header.hero:not(.sticky) section rect,
header.hero:not(.sticky) section circle,
header.hero:not(.sticky) section polygon {
  fill: white;
}

@media screen and (min-width: 640px) {
  header.hero:not(.sticky).dark nav a {
    color: black;
  }
  header.hero:not(.sticky).dark nav a:hover {
    border-bottom: 1px solid black;
  }
  header.hero:not(.sticky).dark path,
  header.hero:not(.sticky).dark rect,
  header.hero:not(.sticky).dark circle,
  header.hero:not(.sticky).dark polygon {
    fill: black;
  }
}

header.hero:not(.sticky) nav a {
  color: white;
}

header.hero:not(.sticky) nav a:hover {
  border-bottom: 1px solid white;
}

header.hero:not(.sticky).sticky {
  background-color: white;
}

@media screen and (min-width: 640px) {
  header.hero:not(.sticky).sticky.dark nav a {
    color: black;
  }
  header.hero:not(.sticky).sticky.dark nav a path,
  header.hero:not(.sticky).sticky.dark nav a rect,
  header.hero:not(.sticky).sticky.dark nav a circle,
  header.hero:not(.sticky).sticky.dark nav a polygon {
    fill: black;
  }
  header.hero:not(.sticky).sticky.dark nav a:hover {
    border-bottom: 1px solid black;
  }
  header.hero:not(.sticky).sticky.dark path,
  header.hero:not(.sticky).sticky.dark rect,
  header.hero:not(.sticky).sticky.dark circle,
  header.hero:not(.sticky).sticky.dark polygon {
    fill: black;
  }
}

header.hero:not(.sticky).black {
  background-color: white;
}

header::after {
  display: none;
  content: '';
  position: absolute;
  bottom: 0;
  left: var(--header-padding-horiz);
  right: var(--header-padding-horiz);
  border-top: 0.5px solid black;
  opacity: 0.333;
}

header section {
  position: relative;
  padding: var(--header-padding-top) var(--header-padding-horiz) var(--header-padding-bottom);
}

header .page-title {
  padding: 0 0 0 var(--header-search-width);
  flex-grow: 1;
  width: auto;
}

header .logo {
  width: var(--header-logo-width);
  display: block;
  margin: 0 auto;
}

header .logo .responsive-svg {
  margin-top: 0;
}

header .logo path,
header .logo rect,
header .logo circle,
header .logo polygon,
header .responsive-svg path,
header .responsive-svg rect,
header .responsive-svg circle,
header .responsive-svg polygon {
  fill: black;
}

header .responsive-svg {
  margin-top: 2px;
}

header .responsive-svg line {
  stroke: black;
}

header a {
  color: black;
  transition: 0.1s linear color;
}

header nav a {
  color: black;
}

header nav a path,
header nav a rect,
header nav a circle,
header nav a polygon {
  transition: 0.1s linear fill;
}

header nav a:hover {
  border-bottom: 1px solid black;
}

header nav.header {
  display: block;
  min-width: var(--header-search-width);
  color: #888888;
  font-size: 18px;
  line-height: 1;
  letter-spacing: -0.009em;
  width: 100%;
}

header nav.header ul {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  align-items: flex-end;
  margin-left: 0;
  margin-right: 0;
}

header nav.header ul > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

header nav.header ul li {
  text-align: left;
}

@media screen and (min-width: 769px) {
  header nav.header ul li {
    width: auto;
  }
}

header nav.header ul li.last {
  flex-grow: 1;
}

header nav.header ul .search {
  width: var(--header-search-width);
  height: var(--header-search-width);
  cursor: pointer;
  padding: 0;
  margin-left: 0;
}

header nav.header ul .search .responsive-svg {
  margin-top: 0;
}

header nav.header ul .search:hover svg path {
  fill: #888888;
}

header nav.header ul .aaa24-link {
  width: 68px;
  cursor: pointer;
}

header nav.header ul .aaa24-link .responsive-svg {
  margin-top: -2px;
}

header nav.header ul .public-access {
  width: 68px;
  cursor: pointer;
}

header nav.header ul .public-access .responsive-svg {
  margin-top: -2px;
}

header nav.header ul .a24-auctions {
  width: 102px;
  cursor: pointer;
}

header nav.header ul .a24-auctions .responsive-svg {
  margin-top: -2px;
}

header nav.header ul .screening-room {
  width: 88px;
  cursor: pointer;
}

header nav.header ul .screening-room .responsive-svg {
  margin-top: -1px;
}

header .cart-hud path,
header .cart-hud rect,
header .cart-hud circle,
header .cart-hud polygon {
  transition: 0.1s linear stroke;
  fill: none;
  stroke: #888888;
}

header .cart-hud a:hover {
  color: #888888;
}

header .cart-hud a:hover path,
header .cart-hud a:hover rect,
header .cart-hud a:hover circle,
header .cart-hud a:hover polygon {
  stroke: #888888;
}

header .menu-supplemental {
  display: flex;
  justify-content: flex-end;
  text-align: right;
  flex-direction: row;
  width: auto;
  padding-left: 0;
  padding-right: 0;
}

header .menu-supplemental .cart-hud {
  width: 25%;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

header .menu-supplemental .cart-hud .label {
  width: 16px;
  display: inline-block;
  vertical-align: text-bottom;
}

footer {
  display: flex;
  flex-flow: row;
  position: relative;
  padding: 30px 18px;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: white;
  line-height: 16px;
  transition: 150ms linear opacity;
  opacity: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  text-transform: uppercase;
}

@media screen and (min-width: 640px) {
  footer {
    display: block;
    padding: 5px 42px 32px;
  }
}

footer li {
  display: block;
  width: auto;
  font-size: 12px;
  line-height: 12px;
}

@media screen and (min-width: 640px) {
  footer li {
    font-size: 15px;
    line-height: inherit;
  }
}

footer li:not(:last-child) {
  margin-bottom: 9px;
}

footer li a:hover {
  border-bottom: 1px solid white;
}

footer.ready {
  opacity: 1;
}

footer section {
  padding: 24px 0 44px;
}

@media screen and (min-width: 640px) {
  footer section {
    padding: 54px 0;
  }
}

footer .grid {
  align-items: stretch;
  margin: 0;
}

footer .footer-grid__col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

footer .grid__col {
  flex: 1 0 0;
  padding-left: 0;
}

footer .grid__col:not(:last-child) {
  padding-right: 48px;
}

@media screen and (max-width: 922px) {
  footer .grid__col:not(:last-child) {
    margin-bottom: 54px;
    padding-right: 24px;
  }
}

@media screen and (max-width: 922px) {
  footer .grid__col {
    flex-basis: 50%;
  }
  footer .grid__col:nth-child(2n) {
    padding-right: 0;
  }
}

footer .footer-col-eyebrow {
  border-top: 1px solid white;
  font-size: 10px;
}

@media screen and (max-width: 922px) {
  footer .footer-col-eyebrow {
    margin-bottom: 18px;
  }
}

@media screen and (min-width: 640px) {
  footer .footer-col-eyebrow {
    font-size: 12.1px;
  }
}

footer .legal ul:first-of-type {
  margin-bottom: 36px;
}

footer .sharing {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  order: 2;
  display: block;
  margin-left: 6px;
  margin-bottom: 36px;
  text-align: left;
}

@media screen and (min-width: 640px) {
  footer .sharing {
    margin-bottom: 48px;
  }
}

@media screen and (min-width: 1024px) {
  footer .sharing {
    width: 30.3%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
  }
}

footer .sharing .grid {
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

footer .sharing .grid h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  width: auto;
  text-align: left;
  flex-basis: 100%;
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  footer .sharing .grid h4 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  footer .sharing .grid h4 {
    font-size: 15px;
  }
}

footer .sharing .grid nav {
  width: auto;
  text-align: left;
}

footer .sharing .grid nav ul {
  margin-left: -7px;
}

footer .sharing .grid a path,
footer .sharing .grid a rect,
footer .sharing .grid a circle,
footer .sharing .grid a polygon {
  transition: 100ms linear fill;
  fill: white;
}

footer .sharing .grid a:hover path,
footer .sharing .grid a:hover rect,
footer .sharing .grid a:hover circle,
footer .sharing .grid a:hover polygon {
  fill: #888888;
}

footer .newsletter {
  flex-grow: 2;
}

footer .newsletter .cta {
  margin-bottom: 24px;
  max-width: 23em;
  text-transform: initial;
  font-size: 12px;
}

@media screen and (min-width: 640px) {
  footer .newsletter .cta {
    font-size: inherit;
  }
}

footer .newsletter form {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

footer .newsletter form label {
  margin-bottom: 0.25rem;
}

footer .newsletter .messages {
  color: #787878;
  position: absolute;
  top: calc(100% + 0.25rem * 1);
  font-size: .875rem;
  letter-spacing: -0.01em;
  text-transform: none;
  padding: 0;
}

footer .disclaimer {
  font-size: 11px;
  line-height: 1.5;
  opacity: 0.7;
  margin-top: 36px;
  max-width: 21em;
}

html {
  --mobile-menu-width: 100vw;
}

@media screen and (min-width: 640px) {
  html {
    --mobile-menu-width: 500px;
  }
}

aside.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: var(--mobile-menu-width);
  height: 100vh;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: black;
  transition: opacity 250ms ease, left 0ms 250ms ease;
  z-index: 3;
  overflow: none;
  opacity: 0;
  left: calc(-1 * var(--mobile-menu-width) - 1px);
  pointer-events: none;
  scrollbar-color: black #444444;
  scrollbar-width: thin;
}

aside.mobile-menu::-webkit-scrollbar {
  width: 9px;
}

aside.mobile-menu::-webkit-scrollbar-track {
  background: black;
}

aside.mobile-menu::-webkit-scrollbar-thumb {
  background: #444444;
  border-radius: 9px;
}

aside.mobile-menu::-webkit-scrollbar-thumb:hover {
  background: #888888;
  opacity: 0.75;
}

@media screen and (min-width: 640px) {
  aside.mobile-menu {
    box-shadow: 2.1px 0px 2.2px rgba(0, 0, 0, 0.025), 5.1px 0px 5.3px rgba(0, 0, 0, 0.036), 9.5px 0px 9.9px rgba(0, 0, 0, 0.045), 17px 0px 17.6px rgba(0, 0, 0, 0.054), 31.8px 0px 33px rgba(0, 0, 0, 0.065), 76px 0px 79px rgba(0, 0, 0, 0.09);
  }
}

body.mobile-menu-open aside.mobile-menu {
  transition: opacity 250ms ease, left 0ms 0ms ease;
  left: 0;
  opacity: 1;
  overflow-y: scroll;
  pointer-events: auto;
}

aside.mobile-menu section {
  position: relative;
  padding: var(--header-default-clearance) 18px 12px;
}

@media screen and (max-width: 640px) {
  aside.mobile-menu section {
    padding-bottom: 72px;
  }
}

@media screen and (min-width: 640px) {
  aside.mobile-menu section {
    padding-left: 42px;
    padding-right: 42px;
  }
}

@media screen and (min-width: 640px) and (max-height: 885px) {
  aside.mobile-menu section {
    padding-top: calc(var(--header-approx-height) + 12px);
  }
}

aside.mobile-menu nav {
  margin-bottom: 24px;
}

aside.mobile-menu ul {
  display: flex;
  flex-direction: column;
  margin-top: -6px;
}

aside.mobile-menu li:last-child {
  margin-bottom: 0;
}

aside.mobile-menu li.search {
  display: none;
}

aside.mobile-menu li.aaa24-link .responsive-svg {
  width: 107.692px;
  height: 38px;
  margin: 6px 0;
  padding-bottom: 0 !important;
}

@media screen and (min-width: 1024px) {
  aside.mobile-menu li.aaa24-link .responsive-svg {
    width: 131.781px;
    height: 46.5px;
  }
}

@media screen and (min-width: 1300px) {
  aside.mobile-menu li.aaa24-link .responsive-svg {
    width: 157.287px;
    height: 55.5px;
  }
}

aside.mobile-menu li.aaa24-link a:not(:hover) .responsive-svg path {
  fill: white;
}

aside.mobile-menu li.public-access {
  width: 114px;
}

aside.mobile-menu li.a24-auctions {
  width: 163px;
}

aside.mobile-menu li.a24-auctions a {
  padding: 3px 0;
}

aside.mobile-menu li.screening-room {
  width: 118px;
}

aside.mobile-menu li.public-access path,
aside.mobile-menu li.public-access rect,
aside.mobile-menu li.public-access circle,
aside.mobile-menu li.public-access polygon, aside.mobile-menu li.a24-auctions path,
aside.mobile-menu li.a24-auctions rect,
aside.mobile-menu li.a24-auctions circle,
aside.mobile-menu li.a24-auctions polygon {
  fill: white;
}

aside.mobile-menu a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  display: block;
  padding: 9px 0;
  color: white;
}

@media screen and (min-width: 1024px) {
  aside.mobile-menu a {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  aside.mobile-menu a {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 640px) {
  aside.mobile-menu a:hover {
    color: #cacaca;
  }
  aside.mobile-menu a:hover path,
  aside.mobile-menu a:hover rect,
  aside.mobile-menu a:hover circle,
  aside.mobile-menu a:hover polygon {
    fill: #cacaca;
  }
}

.text-content .content:not(:first-child) {
  margin-top: 36px;
}

.text-content p + * {
  margin-top: 24px;
}

.text-content strong,
.text-content b {
  font-weight: 700;
}

.text-content em,
.text-content i {
  font-style: italic;
}

.text-content a {
  text-decoration: underline;
  color: #888888;
}

.text-content a:hover {
  color: black;
}

.text-content h1,
.text-content h2,
.text-content h3,
.text-content h4,
.text-content h5,
.text-content h6 {
  margin-bottom: 18px;
}

.text-content h1,
.text-content h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

@media screen and (min-width: 1024px) {
  .text-content h1,
  .text-content h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
  }
}

.text-content h1 a,
.text-content h2 a {
  text-decoration: none;
  background: linear-gradient(to bottom, transparent 86%, #888888 86%, #888888 90.5%, transparent 91.3%);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .text-content h1 a,
  .text-content h2 a {
    background: linear-gradient(to bottom, transparent 86%, #888888 86%, #888888 91%, transparent 91%);
  }
}

.text-content h1 a:hover,
.text-content h2 a:hover {
  background-image: linear-gradient(to bottom, transparent 86%, black 86%, black 90.5%, transparent 91.3%);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .text-content h1 a:hover,
  .text-content h2 a:hover {
    background-image: linear-gradient(to bottom, transparent 86%, black 86%, black 91%, transparent 91%);
  }
}

.text-content ul,
.text-content ol {
  padding-left: 24px;
  margin-top: 24px;
  margin-bottom: 0;
}

.text-content ul ul,
.text-content ul ol,
.text-content ol ul,
.text-content ol ol {
  margin-top: 6px;
}

.text-content ul li,
.text-content ol li {
  margin-bottom: 6px;
  font-size: 27px;
  line-height: 1.46;
  letter-spacing: 0.1px;
}

@media screen and (min-width: 640px) {
  .text-content ul li,
  .text-content ol li {
    font-size: 18px;
  }
}

@media screen and (min-width: 1024px) {
  .text-content ul li,
  .text-content ol li {
    font-size: 27px;
  }
}

@media screen and (max-width: 650px) {
  .text-content ul li,
  .text-content ol li {
    font-size: 20px;
    line-height: 1.3;
  }
}

.text-content ul li:last-child,
.text-content ol li:last-child {
  margin-bottom: 0;
}

.text-content ul {
  list-style-type: disc;
}

.text-content ul li {
  position: relative;
  margin-left: 24px;
}

.text-content ol {
  list-style-type: decimal;
}

.text-content.pullquote-block,
.text-content blockquote {
  position: relative;
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  font-weight: 400;
  padding-bottom: 24px;
  margin-bottom: 36px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    font-weight: 400;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    font-weight: 400;
  }
}

@media screen and (min-width: 640px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    width: 105%;
    margin-left: 0;
    margin-right: 0;
    margin-left: -5%;
  }
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    padding-bottom: 36px;
    border-bottom: 1px solid black;
  }
}

@media screen and (max-width: 650px) {
  .text-content.pullquote-block,
  .text-content blockquote {
    font-size: 28px;
  }
}

.text-content.pullquote-block p,
.text-content blockquote p {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  font-weight: 400;
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block p,
  .text-content blockquote p {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.pullquote-block p,
  .text-content blockquote p {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block p,
  .text-content blockquote p {
    font-weight: 400;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.pullquote-block p,
  .text-content blockquote p {
    font-weight: 400;
  }
}

@media screen and (max-width: 650px) {
  .text-content.pullquote-block p,
  .text-content blockquote p {
    font-size: 28px;
  }
}

.text-content.pullquote-block:not(:first-child),
.text-content blockquote:not(:first-child) {
  margin-top: 36px;
}

.text-content.pullquote-block:last-child,
.text-content blockquote:last-child {
  margin-bottom: 0;
}

.text-content.pullquote-block:before,
.text-content blockquote:before {
  content: '';
  display: block;
  position: relative;
  width: 43px;
  height: 36px;
  margin-bottom: 24px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA0MyAzNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNDMgMzY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHJlY3Qgc3R5bGU9ImZpbGw6bm9uZTsiIHdpZHRoPSI0MyIgaGVpZ2h0PSIzNiIvPgo8Zz4KCTxwYXRoIHN0eWxlPSJmaWxsOiMwMjAyMDI7IiBkPSJNMTEuOCwxOS43Yy0xLjcsMC0zLjYsMC4yLTUsMS4yQzYsMjAuMSw1LDE4LjUsNSwxNi4xYzAtNS41LDUtOS40LDEyLjctMTQuNkwxNi45LDAKCQlDMTAuMSw0LjEsMCwxMC40LDAsMjIuM0MwLDMxLjEsNC45LDM2LDExLDM2YzQuMiwwLDktMi40LDktOC4yQzIwLDIyLDE1LjEsMTkuNywxMS44LDE5Ljd6Ii8+Cgk8cGF0aCBzdHlsZT0iZmlsbDojMDIwMjAyOyIgZD0iTTM0LjgsMTkuN2MtMS43LDAtMy42LDAuMi01LDEuMkMyOSwyMC4xLDI4LDE4LjUsMjgsMTYuMWMwLTUuNSw1LTkuNCwxMi43LTE0LjZMMzkuOSwwCgkJQzMzLjEsNC4xLDIzLDEwLjQsMjMsMjIuM0MyMywzMS4xLDI3LjksMzYsMzQsMzZjNC4yLDAsOS0yLjQsOS04LjJDNDMsMjIsMzguMSwxOS43LDM0LjgsMTkuN3oiLz4KPC9nPgo8L3N2Zz4K");
  background-position: center center;
  background-repeat: no-repeat;
}

@media screen and (min-width: 640px) {
  .text-content.pullquote-block:before,
  .text-content blockquote:before {
    display: inline-block;
    position: absolute;
    top: 11px;
    left: -15%;
  }
}

@media screen and (min-width: 1024px) {
  .text-content.pullquote-block:before,
  .text-content blockquote:before {
    width: 33px;
    height: 28px;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.pullquote-block:before,
  .text-content blockquote:before {
    width: 43px;
    height: 36px;
  }
}

.text-content.large,
.text-content .big-type {
  margin-bottom: 36px;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
  line-height: 1.15;
}

.text-content.large a,
.text-content .big-type a {
  text-decoration: none;
  background: linear-gradient(to bottom, transparent 86%, #888888 86%, #888888 90.5%, transparent 91.3%);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .text-content.large a,
  .text-content .big-type a {
    background: linear-gradient(to bottom, transparent 86%, #888888 86%, #888888 91%, transparent 91%);
  }
}

.text-content.large a:hover,
.text-content .big-type a:hover {
  background-image: linear-gradient(to bottom, transparent 86%, black 86%, black 90.5%, transparent 91.3%);
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .text-content.large a:hover,
  .text-content .big-type a:hover {
    background-image: linear-gradient(to bottom, transparent 86%, black 86%, black 91%, transparent 91%);
  }
}

@media screen and (min-width: 1024px) {
  .text-content.large,
  .text-content .big-type {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.large,
  .text-content .big-type {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

@media screen and (min-width: 640px) {
  .text-content.large,
  .text-content .big-type {
    line-height: 1.15;
  }
}

@media screen and (min-width: 1024px) {
  .text-content.large,
  .text-content .big-type {
    line-height: 1.15;
  }
}

@media screen and (min-width: 1300px) {
  .text-content.large,
  .text-content .big-type {
    line-height: 1.15;
    font-size: 32px;
  }
}

.text-content.large:not(:first-child),
.text-content .big-type:not(:first-child) {
  margin-top: 36px;
}

.text-content.large:last-child,
.text-content .big-type:last-child {
  margin-bottom: 0;
}

aside.newsletter-banner {
  display: none;
  position: fixed;
  overflow: hidden;
  max-height: 0;
  max-width: 0;
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
  color: white;
  background-color: black;
  z-index: 4;
  transition: max-height 500ms ease-out;
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner {
    display: block;
  }
}

aside.newsletter-banner.active {
  max-height: 320px;
  max-width: 100vw;
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner.active {
    max-height: 250px;
  }
}

@media screen and (min-width: 1024px) {
  aside.newsletter-banner.active {
    max-height: 330px;
    width: 55.6vw;
    max-width: 530px;
  }
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner.active.no-content {
    max-height: 250px;
  }
  aside.newsletter-banner.active.no-content section {
    height: 250px;
  }
}

@media screen and (min-width: 1300px) {
  aside.newsletter-banner.active.no-content {
    max-height: 300px;
  }
  aside.newsletter-banner.active.no-content section {
    height: 300px;
  }
}

aside.newsletter-banner section {
  padding-bottom: 48px;
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner section {
    height: 250px;
    width: 75%;
    margin: 0 auto;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  aside.newsletter-banner section {
    height: 330px;
  }
}

aside.newsletter-banner .grid {
  position: relative;
  height: 100%;
  align-items: center;
  align-content: center;
  padding: 0;
  flex-wrap: wrap;
}

aside.newsletter-banner .cta {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-top: 30px;
  margin-bottom: 24px;
  font-size: 15px;
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner .cta {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  aside.newsletter-banner .cta {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner .cta {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-top: 0;
  }
}

aside.newsletter-banner .description {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

aside.newsletter-banner .description p {
  font-size: 17px;
}

@media screen and (min-width: 1024px) {
  aside.newsletter-banner .description {
    margin-bottom: 12px;
  }
  aside.newsletter-banner .description p {
    font-size: 18px;
  }
}

aside.newsletter-banner button.close-newsletter {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1;
  position: absolute;
  top: 25px;
  right: 25px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHRpdGxlPmNsb3NlLW1lZGl1bTwvdGl0bGU+CiAgPGxpbmUgeDE9IjEiIHkxPSIxIiB4Mj0iMjMiIHkyPSIyMyIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjZmZmO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgogIDxsaW5lIHgxPSIxIiB5MT0iMjMiIHgyPSIyMyIgeTI9IjEiIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogI2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
  width: 20px;
  height: 20px;
}

@media screen and (min-width: 640px) {
  aside.newsletter-banner button.close-newsletter {
    top: 32px;
    right: 32px;
    width: 30px;
    height: 30px;
  }
}

aside.newsletter-banner .newsletter-signup label.submit {
  padding: 22px 26px;
}

aside.newsletter-banner .newsletter-signup label.email {
  flex: 1;
}

aside.newsletter-banner .messages,
aside.newsletter-banner .signup-form-disclaimer {
  margin-top: 6px;
  font-size: 12px;
  line-height: 1.3;
  color: #cacaca;
  max-width: 12em;
}

aside.newsletter-banner .signup-form-disclaimer {
  margin: 12px 80px 0 0;
  max-width: 27em;
  text-align: left;
}

aside.newsletter-banner .signup-form-disclaimer a {
  text-decoration: underline;
}

aside.newsletter-banner .signup-form-disclaimer a:hover {
  text-decoration: none;
  color: white;
}

.sidebar {
  display: none;
}

@media screen and (min-width: 1024px) {
  .sidebar {
    display: block;
  }
}

.sidebar .back-link a {
  color: #888888;
  text-decoration: underline;
}

.sidebar .back-link a::before {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LWxlZnQtbWljcm88L3RpdGxlPgogIDxwb2x5bGluZSBwb2ludHM9IjEzLjMzIDE0IDQuNjcgOSAxMy4zMyA0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-right: 12px;
}

.sidebar .back-link a::before {
  vertical-align: -13%;
}

.sidebar .back-link a:hover {
  color: black;
}

main.contact .location {
  margin-bottom: 54px;
}

main.contact .location:last-child {
  margin-bottom: 12px;
}

@media screen and (min-width: 640px) {
  main.contact .location {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 12px;
  }
}

main.contact .location h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  main.contact .location h2 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.contact .location h2 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.contact .location .address,
main.contact .location .email,
main.contact .location .phone {
  color: #888888;
}

main.contact .location a {
  color: #888888;
}

main.contact .location a:hover {
  color: black;
}

main.contact .map {
  padding-bottom: 100%;
  position: relative;
  margin-top: 54px;
}

@media screen and (min-width: 1024px) {
  main.contact .map {
    padding-bottom: 65%;
  }
}

main.contact .map .map-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.single-film,
.single-television {
  padding-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .single-film,
  .single-television {
    padding-bottom: 48px;
  }
}

@media screen and (min-width: 640px) {
  .single-film section.content,
  .single-film section.share-module,
  .single-television section.content,
  .single-television section.share-module {
    padding: 0 48px;
  }
}

.single-film .hero-media.no-mobile,
.single-television .hero-media.no-mobile {
  display: none;
}

@media screen and (min-width: 640px) {
  .single-film .hero-media.no-mobile,
  .single-television .hero-media.no-mobile {
    display: block;
  }
}

.single-film .hero-media.mobile,
.single-television .hero-media.mobile {
  display: block;
}

@media screen and (min-width: 640px) {
  .single-film .hero-media.mobile,
  .single-television .hero-media.mobile {
    display: none;
  }
}

.single-film .hero-media-container,
.single-television .hero-media-container {
  position: relative;
}

@media screen and (max-width: 640px) {
  .single-film .hero-media-container,
  .single-television .hero-media-container {
    height: 100vh;
  }
}

.single-film .slideshow.space-filling,
.single-television .slideshow.space-filling {
  padding-bottom: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 640px) {
  .single-film .slideshow.space-filling,
  .single-television .slideshow.space-filling {
    padding-bottom: 56.25%;
    min-height: 0;
  }
}

@media screen and (min-width: 1024px) {
  .single-film .slideshow.space-filling,
  .single-television .slideshow.space-filling {
    padding-bottom: 47.5%;
  }
}

.single-film .slideshow button.play-video,
.single-television .slideshow button.play-video {
  transform: translate3d(-50%, -50%, 0);
}

.single-film .slideshow .slide.video img,
.single-television .slideshow .slide.video img {
  transform: scale(1);
  transition: transform 250ms ease-out;
}

.single-film .slideshow .slide.video:hover img,
.single-television .slideshow .slide.video:hover img {
  transform: scale(1.02);
}

.single-film .carousel-info__wrapper,
.single-television .carousel-info__wrapper {
  position: relative;
}

@media screen and (min-width: 640px) {
  .single-film .carousel-info__wrapper,
  .single-television .carousel-info__wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.single-film .title,
.single-television .title {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  left: 0;
  padding: 36px 18px 3px;
  margin-bottom: 151.2px;
  color: white;
  max-width: 11em;
}

@media screen and (min-width: 1024px) {
  .single-film .title,
  .single-television .title {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .title,
  .single-television .title {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 640px) {
  .single-film .title,
  .single-television .title {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-top: 0;
    margin-bottom: 0;
    padding: 36px 42px 46px;
  }
  .single-film .title::after,
  .single-television .title::after {
    border-top-color: white;
  }
}

@media screen and (min-width: 1024px) {
  .single-film .title,
  .single-television .title {
    padding: 36px 42px 46px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .title,
  .single-television .title {
    padding: 36px 42px 46px;
  }
}

.single-film .mobile-date,
.single-television .mobile-date {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: absolute;
  left: 0;
  bottom: 108px;
  padding: 18px 18px 18px 20px;
  color: white;
}

@media screen and (min-width: 640px) {
  .single-film .mobile-date,
  .single-television .mobile-date {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .mobile-date,
  .single-television .mobile-date {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .single-film .mobile-date,
  .single-television .mobile-date {
    display: none;
  }
}

.single-film section.content,
.single-television section.content {
  margin-top: 24px;
}

@media screen and (min-width: 640px) {
  .single-film section.content,
  .single-television section.content {
    margin-top: 54px;
  }
}

.single-film .film-details,
.single-television .film-details {
  padding: 24px 0 24px;
}

@media screen and (min-width: 640px) {
  .single-film .film-details,
  .single-television .film-details {
    padding: 0 0 38px;
    border-bottom: 0.5px solid black;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .film-details,
  .single-television .film-details {
    padding: 0 6px 38px;
  }
}

.single-film .credits,
.single-television .credits {
  padding: 0 3px;
}

@media screen and (min-width: 640px) {
  .single-film .credits,
  .single-television .credits {
    padding: 0;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits,
  .single-television .credits {
    padding: 0 6px;
  }
}

.single-film .credits .credits__logos,
.single-television .credits .credits__logos {
  padding-bottom: 30px;
}

.single-film .credits .credits__logos .apple-original,
.single-film .credits .credits__logos .hulu,
.single-film .credits .credits__logos .prime-video,
.single-film .credits .credits__logos .netflix,
.single-film .credits .credits__logos .hbo-max,
.single-film .credits .credits__logos .apple-tv,
.single-film .credits .credits__logos .showtime,
.single-film .credits .credits__logos .peacock,
.single-television .credits .credits__logos .apple-original,
.single-television .credits .credits__logos .hulu,
.single-television .credits .credits__logos .prime-video,
.single-television .credits .credits__logos .netflix,
.single-television .credits .credits__logos .hbo-max,
.single-television .credits .credits__logos .apple-tv,
.single-television .credits .credits__logos .showtime,
.single-television .credits .credits__logos .peacock {
  min-height: 22px;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 20px;
  opacity: 0.5;
}

.single-film .credits .credits__logos .apple-original,
.single-television .credits .credits__logos .apple-original {
  background-image: url("/assets/images/AAOF.svg");
}

.single-film .credits .credits__logos .hulu,
.single-television .credits .credits__logos .hulu {
  background-image: url("/assets/images/hulu_logo-black.svg");
}

.single-film .credits .credits__logos .prime-video,
.single-television .credits .credits__logos .prime-video {
  background-image: url("/assets/images/prime_video_logo-black.svg");
  min-height: 38px;
}

.single-film .credits .credits__logos .netflix,
.single-television .credits .credits__logos .netflix {
  background-image: url("/assets/images/netflix_logo-black.svg");
}

.single-film .credits .credits__logos .hbo-max,
.single-television .credits .credits__logos .hbo-max {
  background-image: url("/assets/images/max_logo-black.svg");
  margin: 10px 0 13px;
}

.single-film .credits .credits__logos .apple-tv,
.single-television .credits .credits__logos .apple-tv {
  background-image: url("/assets/images/apple_tv-black.svg");
  min-height: 30px;
}

.single-film .credits .credits__logos .showtime,
.single-television .credits .credits__logos .showtime {
  background-image: url("/assets/images/showtime-black.svg");
  min-height: 30px;
}

.single-film .credits .credits__logos .peacock,
.single-television .credits .credits__logos .peacock {
  background-image: url("/assets/images/peacock-black.svg");
  min-height: 35px;
}

.single-film .credits .top-content,
.single-television .credits .top-content {
  position: relative;
}

@media screen and (min-width: 769px) {
  .single-film .credits .top-content,
  .single-television .credits .top-content {
    min-height: 76px;
  }
}

@media screen and (min-width: 1024px) {
  .single-film .credits .top-content,
  .single-television .credits .top-content {
    min-height: 84px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .top-content,
  .single-television .credits .top-content {
    min-height: 108px;
  }
}

.single-film .credits .top-content .credit,
.single-television .credits .top-content .credit {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .credit,
  .single-television .credits .top-content .credit {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .single-film .credits .top-content .credit,
  .single-television .credits .top-content .credit {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .top-content .credit,
  .single-television .credits .top-content .credit {
    margin-bottom: 24px;
  }
}

.single-film .credits .top-content .release-content,
.single-television .credits .top-content .release-content {
  display: block;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .release-content,
  .single-television .credits .top-content .release-content {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 769px) {
  .single-film .credits .top-content .release-content,
  .single-television .credits .top-content .release-content {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

.single-film .credits .top-content .release-content.mobile,
.single-television .credits .top-content .release-content.mobile {
  display: block;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .release-content.mobile,
  .single-television .credits .top-content .release-content.mobile {
    display: none;
  }
}

.single-film .credits .top-content .release-content.film,
.single-television .credits .top-content .release-content.film {
  display: none;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .release-content.film,
  .single-television .credits .top-content .release-content.film {
    display: block;
  }
}

.single-film .credits .top-content .release-content.tv,
.single-television .credits .top-content .release-content.tv {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .release-content.tv,
  .single-television .credits .top-content .release-content.tv {
    margin-bottom: 0;
  }
}

.single-film .credits .top-content .release-content h6,
.single-television .credits .top-content .release-content h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  .single-film .credits .top-content .release-content h6,
  .single-television .credits .top-content .release-content h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .top-content .release-content h6,
  .single-television .credits .top-content .release-content h6 {
    font-size: 15px;
  }
}

.single-film .credits .top-content .release-content h3,
.single-television .credits .top-content .release-content h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .single-film .credits .top-content .release-content h3,
  .single-television .credits .top-content .release-content h3 {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .top-content .release-content h3,
  .single-television .credits .top-content .release-content h3 {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.single-film .credits .top-content .release-content .release-date h3,
.single-television .credits .top-content .release-content .release-date h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .single-film .credits .top-content .release-content .release-date h3,
  .single-television .credits .top-content .release-content .release-date h3 {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .top-content .release-content .release-date h3,
  .single-television .credits .top-content .release-content .release-date h3 {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.single-film .credits .top-content .watch-content,
.single-television .credits .top-content .watch-content {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  position: relative;
  padding-bottom: 9px;
}

@media screen and (min-width: 769px) {
  .single-film .credits .top-content .watch-content,
  .single-television .credits .top-content .watch-content {
    width: 30.8%;
    margin-left: 0;
    margin-right: 0;
    position: absolute;
    right: 24px;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) and (min-width: 640px) {
  .single-film .credits .top-content .watch-content,
  .single-television .credits .top-content .watch-content {
    right: calc(6px + 1%);
  }
}

@media screen and (min-width: 769px) and (min-width: 1024px) {
  .single-film .credits .top-content .watch-content,
  .single-television .credits .top-content .watch-content {
    right: calc(6px + 1.5%);
  }
}

@media screen and (min-width: 769px) and (min-width: 1300px) {
  .single-film .credits .top-content .watch-content,
  .single-television .credits .top-content .watch-content {
    right: calc(8px + 1.5%);
  }
}

@media screen and (min-width: 769px) and (min-width: 1600px) {
  .single-film .credits .top-content .watch-content,
  .single-television .credits .top-content .watch-content {
    right: calc(12px + 1.5%);
  }
}

.single-film .credits .bottom-content,
.single-television .credits .bottom-content {
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .bottom-content,
  .single-television .credits .bottom-content {
    margin-top: 15px;
  }
}

.single-film .credits .starring-content,
.single-television .credits .starring-content {
  margin-top: 24px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .starring-content,
  .single-television .credits .starring-content {
    margin-top: 15px;
  }
}

.single-film .credits .credit,
.single-television .credits .credit {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .single-film .credits .credit,
  .single-television .credits .credit {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 769px) {
  .single-film .credits .credit,
  .single-television .credits .credit {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .credit,
  .single-television .credits .credit {
    margin-bottom: 38px;
  }
}

@media screen and (min-width: 640px) {
  .single-film .credits .credit.television:not(.starring),
  .single-television .credits .credit.television:not(.starring) {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
  .single-film .credits .credit.television:not(.starring) ul,
  .single-television .credits .credit.television:not(.starring) ul {
    column-count: 1;
  }
}

.single-film .credits .credit h6,
.single-television .credits .credit h6 {
  margin-bottom: 10px;
  color: #888888;
}

.single-film .credits .credit h6 .content,
.single-television .credits .credit h6 .content {
  margin-top: 12px;
}

.single-film .credits .credit h6,
.single-television .credits .credit h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
}

@media screen and (min-width: 640px) {
  .single-film .credits .credit h6,
  .single-television .credits .credit h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .credit h6,
  .single-television .credits .credit h6 {
    font-size: 15px;
  }
}

.single-film .credits .credit h3,
.single-film .credits .credit li,
.single-television .credits .credit h3,
.single-television .credits .credit li {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .single-film .credits .credit h3,
  .single-film .credits .credit li,
  .single-television .credits .credit h3,
  .single-television .credits .credit li {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .credits .credit h3,
  .single-film .credits .credit li,
  .single-television .credits .credit h3,
  .single-television .credits .credit li {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.single-film .credits .credit ul,
.single-television .credits .credit ul {
  list-style: none;
}

@media screen and (min-width: 769px) {
  .single-film .credits .credit ul,
  .single-television .credits .credit ul {
    column-count: 3;
    column-gap: 20px;
  }
}

.single-film .credits .credit li,
.single-television .credits .credit li {
  margin-bottom: 9px;
  -webkit-column-break-inside: avoid;
  page-break-inside: avoid;
  break-inside: avoid;
  orphans: 3;
}

@media screen and (min-width: 1024px) {
  .single-film .credits .credit li,
  .single-television .credits .credit li {
    margin-bottom: 4px;
  }
}

.single-film .credits .credit li:first-of-type,
.single-television .credits .credit li:first-of-type {
  margin-top: 0;
}

.single-film .film-description,
.single-television .film-description {
  margin-top: 0;
  padding: 0;
}

@media screen and (min-width: 640px) {
  .single-film .film-description,
  .single-television .film-description {
    margin-top: 52px;
    padding-left: 12px;
  }
}

@media screen and (min-width: 640px) {
  .single-film .film-description .meta,
  .single-television .film-description .meta {
    width: 33.333%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
  }
}

.single-film .film-description .meta.links,
.single-television .film-description .meta.links {
  display: none;
}

.single-film .film-description .meta.links.links-exist,
.single-television .film-description .meta.links.links-exist {
  display: block;
}

@media screen and (min-width: 640px) {
  .single-film .film-description .meta.links,
  .single-television .film-description .meta.links {
    display: block;
  }
}

.single-film .film-description .synopsis-wrapper,
.single-television .film-description .synopsis-wrapper {
  padding: 0;
}

@media screen and (min-width: 640px) {
  .single-film .film-description .synopsis-wrapper,
  .single-television .film-description .synopsis-wrapper {
    width: 51%;
    margin-left: 0;
    margin-right: 0;
  }
}

.single-film .film-description .button-container,
.single-television .film-description .button-container {
  position: relative;
  display: block;
  height: auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0;
  border-bottom: none;
}

@media screen and (min-width: 640px) {
  .single-film .film-description .button-container,
  .single-television .film-description .button-container {
    border: none;
  }
}

.single-film .film-description .button-container:first-of-type,
.single-television .film-description .button-container:first-of-type {
  border-bottom: none;
}

.single-film .film-description .button-container.margin,
.single-television .film-description .button-container.margin {
  margin: 0 13px;
}

.single-film .film-description .button-container .button,
.single-television .film-description .button-container .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  .single-film .film-description .button-container .button,
  .single-television .film-description .button-container .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .film-description .button-container .button,
  .single-television .film-description .button-container .button {
    font-size: 15px;
  }
}

.single-film .film-description .button-container .button:after,
.single-television .film-description .button-container .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.single-film .film-description .button-container .button.active:after,
.single-television .film-description .button-container .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  .single-film .film-description .button-container .button,
  .single-television .film-description .button-container .button {
    display: none;
  }
}

.single-film .film-description .button-container a.button,
.single-television .film-description .button-container a.button {
  cursor: pointer;
}

@media screen and (max-width: 640px) {
  .single-film .film-description .button-container .synopsis p,
  .single-television .film-description .button-container .synopsis p {
    font-size: 22px;
    line-height: 1.59;
  }
}

.single-film .film-description .button-container .synopsis,
.single-film .film-description .button-container .awards,
.single-television .film-description .button-container .synopsis,
.single-television .film-description .button-container .awards {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

.single-film .film-description .button-container .synopsis.active,
.single-film .film-description .button-container .awards.active,
.single-television .film-description .button-container .synopsis.active,
.single-television .film-description .button-container .awards.active {
  max-height: none;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  .single-film .film-description .button-container .synopsis,
  .single-film .film-description .button-container .awards,
  .single-television .film-description .button-container .synopsis,
  .single-television .film-description .button-container .awards {
    overflow: visible;
    max-height: none;
    margin-bottom: 36px;
  }
}

.single-film .text-content,
.single-television .text-content {
  margin-bottom: 36px;
}

.single-film .ways-to-watch,
.single-television .ways-to-watch {
  margin-bottom: 24px;
  margin-top: -1px;
}

.single-film .ways-to-watch .link,
.single-television .ways-to-watch .link {
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  border-bottom: 1px solid black;
  transition: 100ms linear border-bottom-color;
  margin-left: 21.6px;
  display: inline-block;
  margin-bottom: 14.4px;
}

@media screen and (min-width: 640px) {
  .single-film .ways-to-watch .link,
  .single-television .ways-to-watch .link {
    font-size: 15px;
    border-bottom: 1px solid transparent;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .ways-to-watch .link,
  .single-television .ways-to-watch .link {
    font-size: 15px;
  }
}

.single-film .ways-to-watch .link:hover,
.single-television .ways-to-watch .link:hover {
  border-bottom: 1px solid black;
}

.single-film .ways-to-watch .link:before,
.single-television .ways-to-watch .link:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 3px;
  left: -2px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNSAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTUgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPuKGkjwvdGl0bGU+CjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgo8cG9seWdvbiBwb2ludHM9IjEzLjgsOC4yIDguMywxMy4yIDguMywwIDYuNywwIDYuNywxMy4yIDEuMiw4LjIgMCw5LjMgNy41LDE2IDE1LDkuMyAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(-135deg);
}

@media screen and (min-width: 640px) {
  .single-film .ways-to-watch .link:before,
  .single-television .ways-to-watch .link:before {
    width: 14px;
    height: 12px;
    top: 6px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .ways-to-watch .link:before,
  .single-television .ways-to-watch .link:before {
    width: 16px;
    height: 15px;
    top: 4px;
  }
}

.single-film .ways-to-watch .button-container,
.single-television .ways-to-watch .button-container {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  max-width: 380px;
  border-top: none;
}

@media screen and (min-width: 640px) {
  .single-film .ways-to-watch .button-container,
  .single-television .ways-to-watch .button-container {
    margin-bottom: 0;
  }
}

.single-film .ways-to-watch .button-container .button,
.single-television .ways-to-watch .button-container .button {
  position: relative;
  width: 100%;
  max-width: 380px;
}

@media screen and (min-width: 769px) {
  .single-film .ways-to-watch .button-container .button,
  .single-television .ways-to-watch .button-container .button {
    width: 85%;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .ways-to-watch .button-container .button,
  .single-television .ways-to-watch .button-container .button {
    width: 100%;
  }
}

.single-film .ways-to-watch .button-container.in-theaters-container,
.single-television .ways-to-watch .button-container.in-theaters-container {
  margin-bottom: 24px;
}

.single-film .ways-to-watch .button:after,
.single-television .ways-to-watch .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.single-film .ways-to-watch .button.active:after,
.single-television .ways-to-watch .button.active:after {
  transform: rotate(180deg);
}

.single-film .ways-to-watch .button:after,
.single-television .ways-to-watch .button:after {
  right: 24px;
  top: 24px;
}

@media screen and (min-width: 640px) {
  .single-film .ways-to-watch .button:after,
  .single-television .ways-to-watch .button:after {
    top: 21px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .ways-to-watch .button:after,
  .single-television .ways-to-watch .button:after {
    left: 332px;
    top: 23px;
  }
}

.single-film .ways-to-watch .button.active:after,
.single-television .ways-to-watch .button.active:after {
  transform: rotate(180deg);
}

.single-film .ways-to-watch .watch-now-links li,
.single-television .ways-to-watch .watch-now-links li {
  margin: 0 12px 6px 0;
}

@media screen and (min-width: 1024px) {
  .single-film .ways-to-watch .watch-now-links li,
  .single-television .ways-to-watch .watch-now-links li {
    display: block;
  }
}

.single-film .meta-group.links,
.single-television .meta-group.links {
  padding-top: 52px;
  padding-bottom: 34px;
  margin-bottom: 52px;
  border-top: 0.5px solid black;
}

@media screen and (min-width: 640px) {
  .single-film .meta-group.links,
  .single-television .meta-group.links {
    padding-top: 0;
    padding-bottom: 0;
    border-top: none;
  }
}

.single-film .meta-group:last-child,
.single-television .meta-group:last-child {
  margin-bottom: 0;
}

.single-film .meta-group .group-label,
.single-film .meta-group .group-content,
.single-television .meta-group .group-label,
.single-television .meta-group .group-content {
  display: inline;
}

.single-film .meta-group h6,
.single-television .meta-group h6 {
  display: none;
}

@media screen and (min-width: 640px) {
  .single-film .meta-group h6,
  .single-television .meta-group h6 {
    display: block;
  }
}

.single-film .release-date h3,
.single-film .seasons h3,
.single-television .release-date h3,
.single-television .seasons h3 {
  display: inline-block;
}

.single-film .seasons,
.single-television .seasons {
  margin-bottom: 24px;
}

.single-film .awards h6,
.single-television .awards h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-bottom: 26.4px;
}

@media screen and (min-width: 640px) {
  .single-film .awards h6,
  .single-television .awards h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .awards h6,
  .single-television .awards h6 {
    font-size: 15px;
  }
}

.single-film .awards p + p,
.single-television .awards p + p {
  margin-top: 24px;
}

.single-film .awards strong,
.single-television .awards strong {
  font-weight: 500;
}

.single-film .posters,
.single-television .posters {
  display: none;
}

@media screen and (min-width: 640px) {
  .single-film .posters,
  .single-television .posters {
    display: block;
    margin-bottom: 36px;
  }
}

.single-film .posters .poster,
.single-television .posters .poster {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .single-film .posters .poster,
  .single-television .posters .poster {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

.single-film .button-container.poster,
.single-television .button-container.poster {
  position: relative;
  display: block;
  height: auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0;
  border-bottom: none;
  margin: 0 18px;
}

@media screen and (min-width: 640px) {
  .single-film .button-container.poster,
  .single-television .button-container.poster {
    border: none;
  }
}

.single-film .button-container.poster:first-of-type,
.single-television .button-container.poster:first-of-type {
  border-bottom: none;
}

.single-film .button-container.poster .button,
.single-television .button-container.poster .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  .single-film .button-container.poster .button,
  .single-television .button-container.poster .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .button-container.poster .button,
  .single-television .button-container.poster .button {
    font-size: 15px;
  }
}

.single-film .button-container.poster .button:after,
.single-television .button-container.poster .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.single-film .button-container.poster .button.active:after,
.single-television .button-container.poster .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  .single-film .button-container.poster .button,
  .single-television .button-container.poster .button {
    display: none;
  }
}

.single-film section.mobile-posters,
.single-television section.mobile-posters {
  display: block;
  max-height: 0;
  overflow: hidden;
  padding: 0;
}

.single-film section.mobile-posters.active,
.single-television section.mobile-posters.active {
  max-height: none;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  .single-film section.mobile-posters,
  .single-television section.mobile-posters {
    display: none;
  }
}

.single-film section.mobile-posters .posters,
.single-television section.mobile-posters .posters {
  display: block;
}

.single-film section.mobile-posters .poster,
.single-television section.mobile-posters .poster {
  margin-top: 24px;
}

.single-film section.sharing,
.single-television section.sharing {
  margin: 0 12px;
}

@media screen and (min-width: 640px) {
  .single-film section.sharing,
  .single-television section.sharing {
    margin-top: 54px;
  }
}

.single-film .explore,
.single-television .explore {
  padding-bottom: 0;
}

.single-film .explore .button-container,
.single-television .explore .button-container {
  position: relative;
  display: block;
  height: auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0;
  border-bottom: none;
}

@media screen and (min-width: 640px) {
  .single-film .explore .button-container,
  .single-television .explore .button-container {
    border: none;
  }
}

.single-film .explore .button-container:first-of-type,
.single-television .explore .button-container:first-of-type {
  border-bottom: none;
}

.single-film .explore .button-container.margin,
.single-television .explore .button-container.margin {
  margin: 0 18px;
}

@media screen and (min-width: 640px) {
  .single-film .explore .button-container.margin,
  .single-television .explore .button-container.margin {
    margin: 0;
  }
}

.single-film .explore .button-container .button,
.single-television .explore .button-container .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
  margin: 0;
  border-top: none;
}

@media screen and (min-width: 640px) {
  .single-film .explore .button-container .button,
  .single-television .explore .button-container .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .single-film .explore .button-container .button,
  .single-television .explore .button-container .button {
    font-size: 15px;
  }
}

.single-film .explore .button-container .button:after,
.single-television .explore .button-container .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.single-film .explore .button-container .button.active:after,
.single-television .explore .button-container .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  .single-film .explore .button-container .button,
  .single-television .explore .button-container .button {
    display: none;
  }
}

.single-television .credit {
  margin-bottom: 24px;
}

.single-television .past-date {
  display: none;
}

@media screen and (min-width: 640px) {
  .single-television .past-date {
    display: block;
  }
}

main.films .group-name,
main.shows .group-name,
main.staff .group-name {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  font-weight: 400;
  margin-bottom: 24px;
}

@media screen and (min-width: 1024px) {
  main.films .group-name,
  main.shows .group-name,
  main.staff .group-name {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.films .group-name,
  main.shows .group-name,
  main.staff .group-name {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 1024px) {
  main.films .group-name,
  main.shows .group-name,
  main.staff .group-name {
    font-weight: 400;
  }
}

@media screen and (min-width: 1300px) {
  main.films .group-name,
  main.shows .group-name,
  main.staff .group-name {
    font-weight: 400;
  }
}

@media screen and (min-width: 640px) {
  main.films .group-name,
  main.shows .group-name,
  main.staff .group-name {
    margin-bottom: 15px;
  }
}

main.films .profiles .section-header,
main.films .features .section-header,
main.shows .profiles .section-header,
main.shows .features .section-header,
main.staff .profiles .section-header,
main.staff .features .section-header {
  margin-bottom: 1px;
}

@media screen and (min-width: 640px) {
  main.films .profiles .section-header,
  main.films .features .section-header,
  main.shows .profiles .section-header,
  main.shows .features .section-header,
  main.staff .profiles .section-header,
  main.staff .features .section-header {
    margin-bottom: 14px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .profiles .section-header,
  main.films .features .section-header,
  main.shows .profiles .section-header,
  main.shows .features .section-header,
  main.staff .profiles .section-header,
  main.staff .features .section-header {
    margin-bottom: 24px;
  }
}

main.films .profiles + .feature-group,
main.films .feature-group + .feature-group,
main.shows .profiles + .feature-group,
main.shows .feature-group + .feature-group,
main.staff .profiles + .feature-group,
main.staff .feature-group + .feature-group {
  padding-top: 30px;
}

main.films .profiles .section-header,
main.films .feature-group .section-header,
main.shows .profiles .section-header,
main.shows .feature-group .section-header,
main.staff .profiles .section-header,
main.staff .feature-group .section-header {
  padding: 0 18px;
}

@media screen and (min-width: 640px) {
  main.films .profiles .section-header,
  main.films .feature-group .section-header,
  main.shows .profiles .section-header,
  main.shows .feature-group .section-header,
  main.staff .profiles .section-header,
  main.staff .feature-group .section-header {
    padding: 0;
  }
}

main.films .profiles .section-header .title,
main.films .feature-group .section-header .title,
main.shows .profiles .section-header .title,
main.shows .feature-group .section-header .title,
main.staff .profiles .section-header .title,
main.staff .feature-group .section-header .title {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

main.films,
main.shows {
  margin-top: var(--header-default-clearance);
}

main.films .features,
main.films .all-films,
main.films .all-television,
main.shows .features,
main.shows .all-films,
main.shows .all-television {
  padding: 0;
}

@media screen and (min-width: 640px) {
  main.films .features,
  main.films .all-films,
  main.films .all-television,
  main.shows .features,
  main.shows .all-films,
  main.shows .all-television {
    padding: 0 42px;
  }
}

main.films .features h3,
main.films .all-films h3,
main.films .all-television h3,
main.shows .features h3,
main.shows .all-films h3,
main.shows .all-television h3 {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  main.films .features h3,
  main.films .all-films h3,
  main.films .all-television h3,
  main.shows .features h3,
  main.shows .all-films h3,
  main.shows .all-television h3 {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  main.films .features h3,
  main.films .all-films h3,
  main.films .all-television h3,
  main.shows .features h3,
  main.shows .all-films h3,
  main.shows .all-television h3 {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

main.films .features h6,
main.films .all-films h6,
main.films .all-television h6,
main.shows .features h6,
main.shows .all-films h6,
main.shows .all-television h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-bottom: 3px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.films .features h6,
  main.films .all-films h6,
  main.films .all-television h6,
  main.shows .features h6,
  main.shows .all-films h6,
  main.shows .all-television h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .features h6,
  main.films .all-films h6,
  main.films .all-television h6,
  main.shows .features h6,
  main.shows .all-films h6,
  main.shows .all-television h6 {
    font-size: 15px;
  }
}

main.films .features h6.date,
main.films .all-films h6.date,
main.films .all-television h6.date,
main.shows .features h6.date,
main.shows .all-films h6.date,
main.shows .all-television h6.date {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  font-family: "NB International Mono Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.065em;
}

@media screen and (min-width: 640px) {
  main.films .features h6.date,
  main.films .all-films h6.date,
  main.films .all-television h6.date,
  main.shows .features h6.date,
  main.shows .all-films h6.date,
  main.shows .all-television h6.date {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .features h6.date,
  main.films .all-films h6.date,
  main.films .all-television h6.date,
  main.shows .features h6.date,
  main.shows .all-films h6.date,
  main.shows .all-television h6.date {
    font-size: 15px;
  }
}

main.films .all-films,
main.films .all-television,
main.shows .all-films,
main.shows .all-television {
  border-top: 1px solid black;
  padding-top: 30px;
}

@media screen and (min-width: 640px) {
  main.films .all-films,
  main.films .all-television,
  main.shows .all-films,
  main.shows .all-television {
    border-top: none;
  }
}

main.films .media-tiles,
main.shows .media-tiles {
  transition: 150ms linear opacity;
  margin-left: -15px;
  margin-right: -15px;
  margin-bottom: 33px;
}

main.films .media-tiles > *,
main.shows .media-tiles > * {
  padding-left: 15px;
  padding-right: 15px;
}

@media screen and (min-width: 640px) {
  main.films .media-tiles,
  main.shows .media-tiles {
    margin-bottom: 0;
  }
}

main.films .media-tiles.loading,
main.shows .media-tiles.loading {
  opacity: 0.75;
}

main.films .section-header,
main.shows .section-header {
  position: relative;
  margin-bottom: 24px;
  align-items: center;
}

@media screen and (min-width: 640px) {
  main.films .section-header,
  main.shows .section-header {
    margin-bottom: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .section-header,
  main.shows .section-header {
    margin-bottom: 24px;
  }
}

main.films .section-header .title,
main.shows .section-header .title {
  width: 50%;
  margin-left: 0;
  margin-right: 0;
  flex-grow: 1;
}

@media screen and (min-width: 640px) {
  main.films .section-header .title,
  main.shows .section-header .title {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.films .section-header .title.all,
main.shows .section-header .title.all {
  margin: 0 18px;
}

@media screen and (min-width: 640px) {
  main.films .section-header .title.all,
  main.shows .section-header .title.all {
    margin: 0;
  }
}

main.films .section-header .active-sorting,
main.shows .section-header .active-sorting {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  width: 100%;
  padding: 19.2px 26.4px;
  border: 1px solid black;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  main.films .section-header .active-sorting,
  main.shows .section-header .active-sorting {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .section-header .active-sorting,
  main.shows .section-header .active-sorting {
    font-size: 15px;
  }
}

main.films .section-header .active-sorting:after,
main.shows .section-header .active-sorting:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

main.films .section-header .active-sorting.active:after,
main.shows .section-header .active-sorting.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  main.films .section-header .active-sorting,
  main.shows .section-header .active-sorting {
    display: none;
  }
}

main.films .section-header .active-sorting a.button,
main.shows .section-header .active-sorting a.button {
  cursor: pointer;
  transition: 100ms linear color, 100ms linear background-color;
}

main.films .section-header .button-container,
main.shows .section-header .button-container {
  margin: 0 18px;
  color: black;
}

@media screen and (min-width: 640px) {
  main.films .section-header .button-container,
  main.shows .section-header .button-container {
    display: none;
  }
}

main.films .section-header .controls,
main.shows .section-header .controls {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  max-height: 0;
  list-style-type: none;
  padding: 0 26.4px;
  margin: 0;
  background-color: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  transition: max-height 150ms ease-out;
  z-index: 1;
  display: inline-block;
  width: calc(100% - 48px);
  max-width: none;
  left: 24px;
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls,
  main.shows .section-header .controls {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 0;
    border: none;
    overflow: visible;
    max-width: none;
    max-height: none;
  }
}

main.films .section-header .controls.active,
main.shows .section-header .controls.active {
  overflow: visible;
  max-height: 280px;
  padding: 0 26.4px 26.4px 26.4px;
  border-bottom: 1px solid black;
}

main.films .section-header .controls label:first-of-type,
main.shows .section-header .controls label:first-of-type {
  padding-top: 26.4px;
}

main.films .section-header .controls a,
main.shows .section-header .controls a {
  color: #888888;
  border-bottom: 1px solid #888888;
  transition: 100ms linear color, 100ms linear border-bottom-color;
}

main.films .section-header .controls label,
main.shows .section-header .controls label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls label,
  main.shows .section-header .controls label {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .section-header .controls label,
  main.shows .section-header .controls label {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls label,
  main.shows .section-header .controls label {
    display: inline-block;
    margin-left: 36px;
    margin-bottom: 0;
  }
}

main.films .section-header .controls label:last-of-type,
main.shows .section-header .controls label:last-of-type {
  margin-bottom: 0;
}

main.films .section-header .controls input,
main.shows .section-header .controls input {
  position: absolute;
  opacity: 1;
  padding: 0;
}

main.films .section-header .controls span,
main.shows .section-header .controls span {
  position: absolute;
  width: 100%;
  left: 0;
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls input:checked,
  main.shows .section-header .controls input:checked {
    border-bottom: 0.5px solid black;
  }
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls,
  main.shows .section-header .controls {
    left: 0;
  }
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls label:first-of-type,
  main.shows .section-header .controls label:first-of-type {
    padding-top: 0;
  }
}

main.films .section-header .controls-wrapper,
main.shows .section-header .controls-wrapper {
  position: relative;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls-wrapper,
  main.shows .section-header .controls-wrapper {
    padding-top: 8px;
    text-align: right;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.films .section-header .controls-wrapper .sort-title,
main.shows .section-header .controls-wrapper .sort-title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: none;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls-wrapper .sort-title,
  main.shows .section-header .controls-wrapper .sort-title {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .section-header .controls-wrapper .sort-title,
  main.shows .section-header .controls-wrapper .sort-title {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.films .section-header .controls-wrapper .sort-title,
  main.shows .section-header .controls-wrapper .sort-title {
    display: inline-block;
  }
}

main.films .show-more-wrapper,
main.shows .show-more-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0 18px;
  margin-top: 80px;
  transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  main.films .show-more-wrapper,
  main.shows .show-more-wrapper {
    padding: 0;
  }
}

main.films .show-more-wrapper:hover,
main.shows .show-more-wrapper:hover {
  opacity: 0.6;
}

main.films .show-more-wrapper h4,
main.shows .show-more-wrapper h4 {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  main.films .show-more-wrapper h4,
  main.shows .show-more-wrapper h4 {
    line-height: 1;
  }
}

@media screen and (min-width: 1024px) {
  main.films .show-more-wrapper h4,
  main.shows .show-more-wrapper h4 {
    line-height: 1;
    letter-spacing: 0.3px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .show-more-wrapper h4,
  main.shows .show-more-wrapper h4 {
    line-height: 1;
  }
}

main.films .show-more-wrapper h4 span,
main.shows .show-more-wrapper h4 span {
  display: inline-block;
  transition: transform 200ms ease-in-out 25ms;
  transform: translate3d(0, -3px, 0);
}

@media screen and (max-width: 640px) {
  main.films .show-more-wrapper h4 span,
  main.shows .show-more-wrapper h4 span {
    transform: none;
  }
}

main.films .show-more-wrapper h4:before,
main.shows .show-more-wrapper h4:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 41px;
  height: 15px;
  margin-right: 33px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTUgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgcG9pbnRzPSI0My4zLDAgNTUsMTAgNDMuMywyMCA0Mi40LDE5LjEgNTIuMiwxMC43IDAsMTAuNyAwLDkuMyA1Mi4yLDkuMyAKCTQyLjQsMC45IDQzLjMsMCAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  main.films .show-more-wrapper h4:before,
  main.shows .show-more-wrapper h4:before {
    width: 34px;
    height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  main.films .show-more-wrapper h4:before,
  main.shows .show-more-wrapper h4:before {
    width: 45px;
    height: 17px;
  }
}

@media screen and (min-width: 1300px) {
  main.films .show-more-wrapper h4:before,
  main.shows .show-more-wrapper h4:before {
    width: 55px;
    height: 20px;
  }
}

main.films .show-more-wrapper h4:before,
main.shows .show-more-wrapper h4:before {
  width: 18px;
  height: 18px;
  top: 1px;
  margin-right: 27px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMiAyMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjIgMjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPnBsdXM8L3RpdGxlPgo8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KPHBvbHlnb24gcG9pbnRzPSIyMS44LDEwLjIgMTEuOCwxMC4yIDExLjgsMC4yIDEwLjIsMC4yIDEwLjIsMTAuMiAwLjIsMTAuMiAwLjIsMTEuOCAxMC4yLDExLjggMTAuMiwyMS44IDExLjgsMjEuOCAxMS44LDExLjggCgkyMS44LDExLjggIi8+Cjwvc3ZnPgo=");
}

@media screen and (min-width: 1024px) {
  main.films .show-more-wrapper h4:before,
  main.shows .show-more-wrapper h4:before {
    top: 0;
  }
}

@media screen and (min-width: 1300px) {
  main.films .show-more-wrapper h4:before,
  main.shows .show-more-wrapper h4:before {
    width: 31px;
    height: 31px;
  }
}

@media screen and (min-width: 640px) {
  main.shows .section-header {
    margin-bottom: 24px;
  }
}

main.home {
  padding-bottom: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

@media screen and (min-width: 640px) {
  main.home {
    margin-top: 0;
    padding-bottom: 102px;
    max-width: none;
  }
}

main.home .featured {
  display: none;
  position: relative;
  height: 540px;
  max-height: 85vh;
  margin-bottom: 50px;
  overflow: hidden;
  background-color: black;
}

main.home .featured .play-video {
  top: 40%;
}

main.home .featured .arrow-svg {
  display: none;
  position: absolute;
  bottom: 54px;
  right: -24px;
  width: 45px;
  height: 17px;
  transform: rotate(90deg);
  transform-origin: center center;
  z-index: 1;
}

main.home .featured .arrow-svg svg path,
main.home .featured .arrow-svg svg rect,
main.home .featured .arrow-svg svg circle,
main.home .featured .arrow-svg svg polygon {
  fill: white;
  stroke: white;
}

@media screen and (min-width: 640px) {
  main.home .featured {
    display: flex;
    height: 560px;
  }
  main.home .featured .arrow-svg {
    display: block;
  }
}

@media screen and (min-width: 1024px) {
  main.home .featured {
    height: 698px;
    margin-bottom: 62px;
  }
}

@media screen and (min-width: 1300px) {
  main.home .featured {
    height: 860px;
  }
}

@media screen and (max-height: 1600px) {
  main.home .featured {
    max-height: 100vh;
  }
  main.home .featured .featured-item,
  main.home .featured .image,
  main.home .featured figure {
    height: 100vh;
  }
  main.home .featured img {
    min-height: 100vh;
    width: auto;
    max-width: none;
  }
}

main.home .featured-item {
  margin-bottom: 12px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

main.home .featured-item:before, main.home .featured-item:after {
  box-sizing: inherit;
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  width: 0;
  height: 0;
  z-index: 1;
  transition: border 300ms ease-out;
}

main.home .featured-item:before {
  top: 0;
  left: 0;
}

main.home .featured-item:after {
  bottom: 0;
  right: 0;
}

main.home .featured-item.loading:before, main.home .featured-item.loading:after {
  width: 100%;
  height: 100%;
}

main.home .featured-item.loading:before {
  transition: width 0.6s ease-out, height 0.6s ease-out 0.6s, border-right-color 0s ease-out 0.6s;
}

main.home .featured-item.loading:after {
  transition: border-bottom-color 0s ease-out 1.2s, width 0.6s ease-out 1.2s, border-left-color 0s ease-out 1.8s, height 0.6s ease-out 1.8s;
}

main.home .featured-item.cyan:before {
  border-top-color: cyan;
  border-right-color: cyan;
}

main.home .featured-item.cyan:after {
  border-bottom-color: cyan;
  border-left-color: cyan;
}

main.home .featured-item.yellow:before {
  border-top-color: yellow;
  border-right-color: yellow;
}

main.home .featured-item.yellow:after {
  border-bottom-color: yellow;
  border-left-color: yellow;
}

main.home .featured-item.magenta:before {
  border-top-color: magenta;
  border-right-color: magenta;
}

main.home .featured-item.magenta:after {
  border-bottom-color: magenta;
  border-left-color: magenta;
}

main.home .featured-item.cymg:before {
  border-top-color: cyan;
  border-right-color: yellow;
}

main.home .featured-item.cymg:after {
  border-bottom-color: magenta;
  border-left-color: lime;
}

@media screen and (min-width: 1024px) {
  main.home .featured-item.small {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.home .featured-item.small .image {
    padding-bottom: 55%;
  }
}

main.home .featured-item .image {
  padding-bottom: 55%;
  position: relative;
  overflow: hidden;
}

main.home .featured-item .image img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

@media screen and (min-width: 640px) {
  main.home .featured-item .image {
    background-color: black;
  }
}

@media screen and (min-width: 1024px) {
  main.home .featured-item .image {
    padding-bottom: 35%;
  }
}

main.home .featured-item .image img {
  transition: 150ms linear opacity;
  opacity: 0;
}

main.home .featured-item .image img.loaded {
  opacity: 1;
}

main.home .featured-item figure {
  position: relative;
  transition: filter 0.6s ease-in, transform 0.6s ease-in;
}

main.home .featured-item figure.loading:not(.isFF) {
  transition: filter 0.1s ease-out, transform 0.3s ease-out;
  filter: blur(28px) saturate(110%);
  transform: scale(1.04);
}

main.home .featured-item figure.loading:not(.isFF) img {
  opacity: 0.8 !important;
}

main.home .featured-item figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 12px;
}

@media screen and (min-width: 640px) {
  main.home .featured-item figcaption {
    padding: 24px;
  }
}

main.home .featured-mobile {
  display: flex;
  position: absolute;
  top: 0;
  min-width: 100vw;
  height: 100vh;
  margin-bottom: 0;
  z-index: 2;
  transition: transform 600ms ease-out;
}

@media screen and (min-width: 640px) {
  main.home .featured-mobile {
    display: none;
  }
}

main.home .featured-mobile.hide {
  transform: translate3d(0, -160%, 0);
}

main.home .featured-mobile .featured-item {
  position: absolute;
  height: 100vh;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  background-color: #646464;
}

main.home .featured-mobile .featured-item figure .image {
  opacity: 0.9;
  transform: scale(1);
  transition: transform 250ms ease-out, opacity 150ms ease-in-out;
}

main.home .featured-mobile .featured-item.active {
  opacity: 1;
  visibility: visible;
}

main.home .featured-mobile .featured-item.active figure .image {
  opacity: 1;
  transform: scale(1.02);
}

main.home .featured-mobile figure {
  height: 100vh;
}

main.home .featured-mobile .mobile-image {
  height: 100vh;
}

main.home .featured-mobile .mobile-image img {
  width: auto;
}

main.home .featured-mobile .titles-wrapper {
  color: white;
  bottom: 105px;
}

main.home .featured-mobile .titles-wrapper figcaption {
  width: 350px;
  padding: 12px 18px;
}

main.home .featured-mobile .titles-wrapper h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  margin-bottom: 6px;
}

@media screen and (min-width: 1024px) {
  main.home .featured-mobile .titles-wrapper h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.home .featured-mobile .titles-wrapper h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.home .featured-mobile time {
  position: relative;
  vertical-align: super;
  font-family: "NB International Mono Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 15px;
  letter-spacing: -0.065em;
  margin-left: 2px;
}

@media screen and (min-width: 1024px) {
  main.home .featured-mobile time {
    margin-left: -7.2px;
    top: -10px;
  }
}

@media screen and (min-width: 1300px) {
  main.home .featured-mobile time {
    margin-left: -12px;
    top: -14px;
  }
}

main.home .featured-mobile .indicator-wrapper {
  position: absolute;
  bottom: 105px;
  padding: 12px 24px;
  right: 0;
  color: white;
  z-index: 1;
  text-align: center;
}

main.home .featured-mobile .indicator-wrapper span {
  display: block;
}

main.home .featured-mobile .indicator-wrapper .indicator-line {
  width: 1px;
  height: 80px;
  margin: 8px auto;
  background-color: white;
  transition: height 275ms cubic-bezier(0.645, 0.045, 0.355, 1);
}

main.home .featured-mobile .indicator-wrapper .indicator-total.arrow:after {
  content: '';
  display: block;
  position: relative;
  width: 18px;
  height: 18px;
  right: 0;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIHN0eWxlPSJmaWxsOndoaXRlOyIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjEuNCAxMi40IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPGc+Cgk8cG9seWdvbiBwb2ludHM9IjEwLjcsMTIuNCAwLDEuNCAxLjQsMCAxMC43LDkuNSAyMCwwIDIxLjQsMS40IAkiLz4KPC9nPgo8L3N2Zz4K");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
}

main.home .titles-wrapper {
  position: absolute;
  bottom: 60px;
  padding: 0 24px 0 42px;
  z-index: 1;
  opacity: 0;
  transition: opacity 120ms ease-out;
}

main.home .titles-wrapper.loaded {
  opacity: 1;
}

@media screen and (min-width: 640px) {
  main.home .titles-wrapper {
    bottom: 52px;
  }
}

@media screen and (min-width: 1024px) {
  main.home .titles-wrapper {
    width: 90%;
  }
}

main.home .featured-item-title {
  display: block;
  transition: opacity 200ms ease-in-out;
}

main.home .featured-item-title .hero-title span {
  display: inline-block;
  white-space: nowrap;
}

main.home .featured-item-title ul, main.home .featured-item-title li {
  display: inline-block;
  list-style: none;
}

main.home .featured-item-title.active h1 {
  opacity: 0.6;
}

main.home .eyebrow {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-top: 9.6px;
  margin-bottom: 9.6px;
}

@media screen and (min-width: 640px) {
  main.home .eyebrow {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.home .eyebrow {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.home .eyebrow {
    margin-top: 0;
    margin-bottom: 12px;
  }
}

main.home h1 {
  font-weight: 500;
  font-size: 38px;
  line-height: 1;
  letter-spacing: -0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  margin-bottom: 0;
}

@media screen and (min-width: 1024px) {
  main.home h1 {
    font-size: 62px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 1300px) {
  main.home h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (max-width: 360px) {
  main.home h1 {
    font-size: 6vw;
    line-height: 8vw;
  }
}

@media screen and (min-width: 640px) {
  main.home h1 {
    margin-bottom: 0;
  }
}

main.home h1 a:hover {
  opacity: 0.8;
}

main.home h1 sup {
  margin-left: 0.33em;
}

@media screen and (max-width: 640px) {
  main.home .home-stack {
    margin-top: 78px;
  }
}

main.jobs {
  padding-bottom: 0;
}

main.jobs .page-header h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  main.jobs .page-header h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.jobs .page-header h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 640px) {
  main.jobs .page-header h1 {
    margin-bottom: 54px;
  }
}

main.jobs .header-image {
  padding: 0;
}

main.jobs .content-wrapper {
  margin-top: 54px;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  main.jobs .content-wrapper {
    margin-top: 60px;
  }
}

main.jobs .locations {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -18px;
  margin-right: -18px;
}

main.jobs .locations > * {
  padding-left: 18px;
  padding-right: 18px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

main.jobs .location {
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  main.jobs .location {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.jobs .location h2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-bottom: 10px;
}

@media screen and (min-width: 640px) {
  main.jobs .location h2 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.jobs .location h2 {
    font-size: 15px;
  }
}

main.jobs .location .message {
  margin-bottom: 54px;
}

main.jobs .location-positions-list .position {
  margin-bottom: 21px;
}

main.jobs .location-positions-list h2 {
  margin-bottom: 12px;
}

main.jobs .location-positions-list .position-link {
  display: block;
}

main.jobs .location-positions-list .position-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
  display: inline-block;
  margin-right: 0.25em;
  transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 1024px) {
  main.jobs .location-positions-list .position-title {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  main.jobs .location-positions-list .position-title {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

main.jobs .location-positions-list .position-title:hover {
  opacity: 0.6;
}

main.jobs .location-positions-list .location-list {
  font-weight: 400;
  display: inline-block;
}

main.jobs .location-positions-list .location-list li {
  display: inline-block;
}

main.jobs .location-positions-list .position-learn-more a {
  color: #888888;
  text-decoration: underline;
}

main.jobs .sharing .sharing-options {
  flex-wrap: nowrap;
}

main.jobs .sharing .sharing-options h4 {
  margin-right: 36px;
  margin-bottom: 0;
  flex-basis: auto;
}

section.login form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  justify-content: center;
}

section.login form > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  section.login fieldset {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.login .forgot-password {
  text-align: center;
  color: grey;
  font-size: 10px;
  margin-top: 2rem;
}

section.login .forgot-password a {
  text-decoration: underline;
}

.newsletter-signup,
.newsletter-confirmation {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 24px;
}

.newsletter-signup > *,
.newsletter-confirmation > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.newsletter-signup.success label.email,
.newsletter-confirmation.success label.email {
  display: none;
}

.newsletter-signup.success label.submit,
.newsletter-confirmation.success label.submit {
  display: none;
}

.newsletter-signup input,
.newsletter-signup textarea,
.newsletter-signup button,
.newsletter-confirmation input,
.newsletter-confirmation textarea,
.newsletter-confirmation button {
  font-size: 15px;
}

.newsletter-signup h1,
.newsletter-confirmation h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  .newsletter-signup h1,
  .newsletter-confirmation h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .newsletter-signup h1,
  .newsletter-confirmation h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

.newsletter-signup label,
.newsletter-confirmation label {
  margin-bottom: 0;
}

.newsletter-signup label.email,
.newsletter-confirmation label.email {
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.email,
  .newsletter-confirmation label.email {
    width: 100%;
  }
}

.newsletter-signup label.email input,
.newsletter-confirmation label.email input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
  border: 1px solid white;
  padding: 22px 18px;
  text-transform: none;
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.email input,
  .newsletter-confirmation label.email input {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .newsletter-signup label.email input,
  .newsletter-confirmation label.email input {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.email input,
  .newsletter-confirmation label.email input {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .newsletter-signup label.email input,
  .newsletter-confirmation label.email input {
    font-size: 15px;
  }
}

.newsletter-signup label.email input::placeholder,
.newsletter-confirmation label.email input::placeholder {
  color: #888888;
  font-size: 14px;
  text-transform: none;
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.email input::placeholder,
  .newsletter-confirmation label.email input::placeholder {
    font-size: 15px;
  }
}

@media screen and (max-width: 371px) {
  .newsletter-signup label.email input::placeholder,
  .newsletter-confirmation label.email input::placeholder {
    font-size: 8px;
  }
}

.newsletter-signup label.submit,
.newsletter-confirmation label.submit {
  position: relative;
  width: auto;
  margin: 0;
  padding: 21px 22px 22px;
  color: black;
  background-color: white;
  border: 1px solid white;
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.submit,
  .newsletter-confirmation label.submit {
    padding: 22px 64px;
  }
}

.newsletter-signup label.submit span.label,
.newsletter-confirmation label.submit span.label {
  visibility: hidden;
}

.newsletter-signup label.submit input,
.newsletter-confirmation label.submit input {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  padding: 0;
  color: black;
  text-align: center;
  font-size: 14px;
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.submit input,
  .newsletter-confirmation label.submit input {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .newsletter-signup label.submit input,
  .newsletter-confirmation label.submit input {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .newsletter-signup label.submit input,
  .newsletter-confirmation label.submit input {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .newsletter-signup label.submit input,
  .newsletter-confirmation label.submit input {
    font-size: 15px;
  }
}

.newsletter-signup .messages,
.newsletter-confirmation .messages {
  display: none;
  margin-top: 6px;
  text-align: left;
}

.newsletter-signup .messages span,
.newsletter-confirmation .messages span {
  display: none;
}

.newsletter-signup .messages.error .error,
.newsletter-confirmation .messages.error .error {
  display: block;
}

.newsletter-signup .messages.success .success,
.newsletter-confirmation .messages.success .success {
  display: block;
}

.newsletter-signup-page-form input#newsletter-signup {
  border-color: black;
}

.newsletter-signup-page-form input[type="submit"] {
  color: white !important;
}

.newsletter-signup-page-form label.newsletter-signup-field__label.textinput__label {
  color: black;
}

.newsletter-signup-page-form label.submit.with-data {
  background-color: black;
  border-color: black;
}

main.notes {
  margin-top: calc(var(--header-default-clearance) - 22px);
}

@media screen and (min-width: 640px) {
  main.notes {
    margin-top: calc(var(--header-default-clearance) - 48px);
  }
}

main.notes .filter-wrapper {
  position: relative;
  margin: 0 18px;
  padding-top: 22px;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper {
    padding-bottom: 24px;
    text-align: center;
    margin: 0 42px;
  }
}

main.notes .filter-wrapper .sort-title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: none;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .sort-title {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .filter-wrapper .sort-title {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .sort-title {
    display: inline-block;
  }
}

main.notes .filter-wrapper .active-filter {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  width: 100%;
  padding: 19.2px 26.4px;
  border: 1px solid black;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .active-filter {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .filter-wrapper .active-filter {
    font-size: 15px;
  }
}

main.notes .filter-wrapper .active-filter:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

main.notes .filter-wrapper .active-filter.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .active-filter {
    display: none;
  }
}

main.notes .filter-wrapper .active-filter a.button {
  cursor: pointer;
  transition: 100ms linear color, 100ms linear background-color;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .button-container {
    display: none;
  }
}

main.notes .filter-wrapper .controls {
  display: block;
  position: absolute;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  max-height: 0;
  list-style-type: none;
  padding: 0 26.4px;
  margin: 0;
  background-color: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  transition: max-height 150ms ease-out;
  z-index: 1;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .controls {
    display: inline-block;
    position: relative;
    width: auto;
    padding: 0;
    border: none;
    overflow: visible;
    max-width: none;
    max-height: none;
  }
}

main.notes .filter-wrapper .controls.active {
  overflow: visible;
  max-height: 280px;
  padding: 0 26.4px 26.4px 26.4px;
  border-bottom: 1px solid black;
}

main.notes .filter-wrapper .controls label:first-of-type {
  padding-top: 26.4px;
}

main.notes .filter-wrapper .controls a {
  color: #888888;
  border-bottom: 1px solid #888888;
  transition: 100ms linear color, 100ms linear border-bottom-color;
}

main.notes .filter-wrapper .controls label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  margin-bottom: 24px;
  cursor: pointer;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .controls label {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .filter-wrapper .controls label {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .controls label {
    display: inline-block;
    margin-left: 36px;
    margin-bottom: 0;
  }
}

main.notes .filter-wrapper .controls label:last-of-type {
  margin-bottom: 0;
}

main.notes .filter-wrapper .controls input {
  position: absolute;
  opacity: 1;
  padding: 0;
}

main.notes .filter-wrapper .controls span {
  position: absolute;
  width: 100%;
  left: 0;
}

@media screen and (min-width: 640px) {
  main.notes .filter-wrapper .controls input:checked {
    border-bottom: 0.5px solid black;
  }
}

main.notes .show-more-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0 18px;
  margin-top: 80px;
  transition: opacity 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  main.notes .show-more-wrapper {
    padding: 0;
  }
}

main.notes .show-more-wrapper:hover {
  opacity: 0.6;
}

main.notes .show-more-wrapper h4 {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  main.notes .show-more-wrapper h4 {
    line-height: 1;
  }
}

@media screen and (min-width: 1024px) {
  main.notes .show-more-wrapper h4 {
    line-height: 1;
    letter-spacing: 0.3px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .show-more-wrapper h4 {
    line-height: 1;
  }
}

main.notes .show-more-wrapper h4 span {
  display: inline-block;
  transition: transform 200ms ease-in-out 25ms;
  transform: translate3d(0, -3px, 0);
}

@media screen and (max-width: 640px) {
  main.notes .show-more-wrapper h4 span {
    transform: none;
  }
}

main.notes .show-more-wrapper h4:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 41px;
  height: 15px;
  margin-right: 33px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTUgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgcG9pbnRzPSI0My4zLDAgNTUsMTAgNDMuMywyMCA0Mi40LDE5LjEgNTIuMiwxMC43IDAsMTAuNyAwLDkuMyA1Mi4yLDkuMyAKCTQyLjQsMC45IDQzLjMsMCAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  main.notes .show-more-wrapper h4:before {
    width: 34px;
    height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  main.notes .show-more-wrapper h4:before {
    width: 45px;
    height: 17px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .show-more-wrapper h4:before {
    width: 55px;
    height: 20px;
  }
}

main.notes .show-more-wrapper h4:before {
  width: 18px;
  height: 18px;
  top: 1px;
  margin-right: 27px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMiAyMiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMjIgMjI7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPnBsdXM8L3RpdGxlPgo8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KPHBvbHlnb24gcG9pbnRzPSIyMS44LDEwLjIgMTEuOCwxMC4yIDExLjgsMC4yIDEwLjIsMC4yIDEwLjIsMTAuMiAwLjIsMTAuMiAwLjIsMTEuOCAxMC4yLDExLjggMTAuMiwyMS44IDExLjgsMjEuOCAxMS44LDExLjggCgkyMS44LDExLjggIi8+Cjwvc3ZnPgo=");
}

@media screen and (min-width: 1024px) {
  main.notes .show-more-wrapper h4:before {
    top: 0;
  }
}

@media screen and (min-width: 1300px) {
  main.notes .show-more-wrapper h4:before {
    width: 31px;
    height: 31px;
  }
}

@media screen and (max-width: 640px) {
  main.notes .show-more-wrapper {
    margin-bottom: 33px;
  }
}

main.notes section.archive {
  padding: 0;
}

@media screen and (min-width: 640px) {
  main.notes section.archive {
    padding: 0 24px;
  }
}

main.notes section.archive .show-more-wrapper {
  padding: 0 24px;
}

main.notes section.archive .archive-preview {
  margin-bottom: 5px;
}

@media screen and (min-width: 640px) {
  main.notes section.archive .archive-preview {
    margin-bottom: 96px;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.notes section.archive .archive-preview {
    margin-bottom: 144px;
  }
}

@media screen and (min-width: 1300px) {
  main.notes section.archive .archive-preview {
    width: 88.5%;
  }
}

main.notes section.archive .archive-preview:first-of-type {
  margin-top: 38px;
}

@media screen and (min-width: 640px) {
  main.notes section.archive .archive-preview:first-of-type {
    margin-top: 36px;
  }
}

main.notes section.archive .archive-preview:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  main.notes section.archive .archive-preview:last-of-type {
    margin-bottom: 100px;
  }
}

main.notes section.archive .archive-preview h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  margin-bottom: 30px;
}

@media screen and (min-width: 1024px) {
  main.notes section.archive .archive-preview h2 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.notes section.archive .archive-preview h2 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.notes section.archive .archive-preview h2 em {
  font-style: italic;
}

main.notes section.archive .archive-preview h2 strong {
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  main.notes section.archive .archive-preview h2 {
    margin-bottom: 60px;
  }
}

main.notes section.archive .story-image {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  main.notes section.archive .story-image {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.notes section.archive .story-image a {
  display: block;
}

main.notes section.archive .story-intro p {
  display: inline;
  margin-right: 3px;
}

main.notes section.archive .story-intro .read-more {
  text-decoration: underline;
  color: #888888;
  display: inline-block;
  margin-top: 0;
}

main.notes section.archive .story-intro .read-more:hover {
  color: black;
}

main.login {
  margin-top: 140px;
}

@media screen and (min-width: 1024px) {
  main.login {
    margin-top: 200px;
  }
}

main.page section.header-image .responsive-image {
  padding-bottom: 100%;
  min-height: 100vh;
}

@media screen and (min-width: 640px) {
  main.page section.header-image .responsive-image {
    padding-bottom: 56.25%;
    min-height: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.page section.header-image .responsive-image {
    padding-bottom: 40%;
  }
}

@media screen and (max-width: 640px) {
  main.page.jobs section.header-image .responsive-image {
    padding-bottom: 0;
    min-height: unset;
  }
}

@media screen and (max-width: 640px) {
  main.page.jobs section.header-image .responsive-image img {
    position: unset;
  }
}

main.page section.page-content {
  margin-top: var(--header-default-clearance);
}

@media screen and (max-width: 640px) {
  main.page section.page-content {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 1024px) {
  main.page section.page-content .sidebar {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.page section.page-content .primary-content {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.page section.page-content .primary-content {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.page section.page-content .primary-content.narrow {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.page section.page-content .primary-content h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  font-weight: 400;
}

@media screen and (min-width: 1024px) {
  main.page section.page-content .primary-content h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.page section.page-content .primary-content h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 1024px) {
  main.page section.page-content .primary-content h1 {
    font-weight: 400;
  }
}

@media screen and (min-width: 1300px) {
  main.page section.page-content .primary-content h1 {
    font-weight: 400;
  }
}

main.page.about .header-image {
  padding: 0;
}

@media screen and (min-width: 640px) {
  main.page.about .primary-content {
    width: 100% !important;
  }
}

main.page.about .text-content {
  color: black;
}

@media screen and (min-width: 1300px) {
  main.page.error {
    padding-top: 48px;
  }
}

main.page.error .title h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  main.page.error .title h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.page.error .title h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.page.error .text-content {
  padding-top: 0;
}

main.page.error .error-image figure {
  position: relative;
  overflow: hidden;
  margin-bottom: 12px;
}

main.page.error .error-image figure img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

main.page.error .error-image img {
  transition: 150ms linear opacity;
  opacity: 0;
}

main.page.error .error-image img.loaded {
  opacity: 1;
}

main.page section.success-image,
main.page section.error-image {
  margin-top: 54px;
}

.related-products.explore {
  padding-bottom: 0;
}

@media screen and (min-width: 640px) {
  .related-products.explore {
    margin-top: 54px;
  }
}

.related-products.explore .button-container {
  position: relative;
  display: block;
  height: auto;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 0;
  border-bottom: none;
}

@media screen and (min-width: 640px) {
  .related-products.explore .button-container {
    border: none;
  }
}

.related-products.explore .button-container:first-of-type {
  border-bottom: none;
}

.related-products.explore .button-container.margin {
  margin: 0 18px;
}

@media screen and (min-width: 640px) {
  .related-products.explore .button-container.margin {
    margin: 0;
  }
}

.related-products.explore .button-container .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
  margin: 0;
  border-top: none;
}

@media screen and (min-width: 640px) {
  .related-products.explore .button-container .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .related-products.explore .button-container .button {
    font-size: 15px;
  }
}

.related-products.explore .button-container .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.related-products.explore .button-container .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  .related-products.explore .button-container .button {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .related-products.explore .secondary-features.full {
    margin: 0 -21px;
    width: auto;
  }
}

@media screen and (min-width: 640px) {
  .related-products.explore .secondary-features.full .related-title {
    padding-left: 21px;
    padding-right: 21px;
  }
}

@media screen and (min-width: 640px) {
  .related-products.explore .secondary-features.full .feature-tile {
    padding: 0 21px !important;
  }
}

.related-products.explore .feature-image .responsive-image {
  position: relative;
}

main.position {
  margin-top: 116px;
}

@media screen and (min-width: 1300px) {
  main.position {
    margin-top: 160px;
  }
}

main.position .position-title {
  margin-bottom: 48px;
}

@media screen and (min-width: 640px) {
  main.position .position-title {
    width: 70%;
    margin-left: 0;
    margin-right: 0;
    margin: 22px auto 24px 17.5%;
    padding: 0;
  }
}

main.position .position-title h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  text-indent: -2px;
  margin-bottom: 23px;
}

@media screen and (min-width: 1024px) {
  main.position .position-title h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.position .position-title h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.position .position-title h2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  margin-bottom: 29px;
  font-size: 17px;
}

@media screen and (min-width: 640px) {
  main.position .position-title h2 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.position .position-title h2 {
    font-size: 15px;
  }
}

main.position .position-title .location-list {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  text-indent: 0;
  display: block;
}

main.position .position-title .location-list li {
  display: inline-block;
}

main.position .position-title .location-list li::after {
  content: ', ';
  display: inline;
}

main.position .position-title .location-list li:nth-last-of-type(2)::after {
  content: " and ";
}

main.position .position-title .location-list li:last-of-type::after {
  display: none;
}

main.position .text-content ul {
  padding-left: 0;
}

main.position .position-description {
  margin-bottom: 54px;
}

main.position .position-description .position-text h3 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin: 86px 0 34px;
}

@media screen and (min-width: 640px) {
  main.position .position-description .position-text h3 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.position .position-description .position-text h3 {
    font-size: 15px;
  }
}

main.position .position-description .position-text strong {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  main.position .position-description .position-text strong {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  main.position .position-description .position-text strong {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

main.position .position-description .position-text p {
  margin-top: 38px;
  font-size: 18px;
  line-height: 1.2 !important;
}

main.position .position-description .position-text p span {
  font-size: 24px;
  line-height: 1.2;
}

main.position .position-description .position-text strong {
  font-weight: 400;
}

main.position .position-description .position-text li {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  margin-bottom: 6px;
  margin-left: 21px;
}

main.position .position-application h2 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  margin-bottom: 22px;
}

@media screen and (min-width: 640px) {
  main.position .position-application h2 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.position .position-application h2 {
    font-size: 15px;
  }
}

main.position .position-application .flash {
  margin-top: 24px;
}

main.position form .field-container {
  margin-bottom: 24px;
}

main.position .sharing {
  padding-top: 36px;
}

main.position form .field-container.submit button {
  background: #000;
  height: 62px;
  padding: 0 40px;
  border: none;
  color: #fff;
  text-transform: uppercase;
  font-family: akzidenz-grotesk-extended;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: .1px;
}

main.position form .field-container.submit button:after {
  display: none;
}

main.position .position-description > *,
main.position .position-application > * {
  padding-left: 3px;
  padding-right: 3px;
}

@media screen and (min-width: 640px) {
  main.position .position-description > *,
  main.position .position-application > * {
    width: 54%;
    margin-left: auto;
    margin-right: auto;
  }
}

.page.search .site-container {
  margin-top: var(--header-approx-height);
}

.page.search .site-container .search-wrapper .search-bar {
  padding: 26.6px 4.33%;
}

@media screen and (min-width: 640px) {
  .page.search .site-container .search-wrapper .search-bar {
    padding: 20px 42px 20px 42px;
  }
}

@media screen and (min-width: 1024px) {
  .page.search .site-container .search-wrapper .search-bar {
    padding: 42px 42px 45px 42px;
  }
}

.page.search .site-container h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
}

@media screen and (min-width: 640px) {
  .page.search .site-container h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .page.search .site-container h6 {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .page.search .site-container .results-wrapper {
    margin: 0 42px 0 42px;
  }
}

.page.search .site-container .results-wrapper h6 {
  width: 91.667%;
  margin: 0 auto;
  padding: 29px 7px 39px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 640px) {
  .page.search .site-container .results-wrapper h6 {
    width: 100%;
    margin: 0;
  }
}

.page.search .site-container .search-form input {
  color: white;
  border-bottom: none;
  font-weight: 300;
}

.page.search .site-container .stack-item:first-of-type {
  margin-top: 24px;
}

@media screen and (min-width: 640px) {
  .page.search .site-container .stack-item:first-of-type {
    margin-top: 82px;
  }
}

.page.search .site-container .stack-item:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  .page.search .site-container .stack-item:last-of-type {
    margin-bottom: 116px;
  }
}

@media screen and (min-width: 1300px) {
  .page.search .site-container .stack-item {
    width: 90.8%;
    margin: 0 auto 80px;
  }
}

.page.search .site-container .stack-image img {
  opacity: 1;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 1024px) {
  main.cart .line-items {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

main.cart .line-item {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 54px;
}

main.cart .line-item > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

main.cart .line-item:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  main.cart .line-item-image {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.cart .line-item-details {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

main.cart .line-item-details .label,
main.cart .line-item-details .value {
  display: inline-block;
}

main.cart .line-item-details .label::after {
  content: ':';
}

main.cart .line-item-details .line-item-price,
main.cart .line-item-details .line-item-variant,
main.cart .line-item-details .line-item-quatity {
  margin-bottom: 12px;
}

@media screen and (min-width: 640px) {
  main.cart .line-item-details .line-item-price,
  main.cart .line-item-details .line-item-variant,
  main.cart .line-item-details .line-item-quatity {
    margin-bottom: 24px;
  }
}

main.cart .line-item-details .line-item-price:last-child,
main.cart .line-item-details .line-item-variant:last-child,
main.cart .line-item-details .line-item-quatity:last-child {
  margin-bottom: 0;
}

main.cart .line-item-details .line-item-price {
  font-weight: 500;
}

main.cart .line-item-controls {
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  main.cart .line-item-controls {
    margin-top: 0;
    text-align: right;
    width: 16.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.cart .line-item-controls button.remove {
  border: none;
  padding: 0;
  color: #888888;
  text-decoration: underline;
}

main.cart .line-item-controls button.remove:hover {
  color: black;
}

@media screen and (min-width: 640px) {
  main.cart .line-item-controls button.remove {
    text-indent: -9999px;
    background-repeat: no-repeat;
    border: 0;
    background-color: transparent;
    background-position: center center;
    outline: none;
    background-size: 100% 100%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    transition: 150ms linear opacity;
    display: block;
    line-height: 1;
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHRpdGxlPmNsb3NlLW1lZGl1bS1ibGFjazwvdGl0bGU+CiAgPGxpbmUgeDE9IjEiIHkxPSIxIiB4Mj0iMjMiIHkyPSIyMyIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjMDAwO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgogIDxsaW5lIHgxPSIxIiB5MT0iMjMiIHgyPSIyMyIgeTI9IjEiIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogIzAwMDtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
  }
}

main.cart .checkout {
  margin-top: 54px;
}

@media screen and (min-width: 640px) {
  main.cart .checkout .totals {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 50%;
  }
}

@media screen and (min-width: 1024px) {
  main.cart .checkout .totals {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 50%;
  }
}

main.cart .checkout .totals-entry .label,
main.cart .checkout .totals-entry .value {
  display: inline-block;
}

main.cart .checkout .totals-entry .label::after {
  content: ':';
}

main.cart .checkout .discount-base,
main.cart .checkout .total-discount,
main.cart .checkout .total-price {
  margin-top: 12px;
}

main.cart .checkout .total-price {
  font-weight: 500;
}

main.cart .checkout .next-step {
  margin-top: 36px;
}

@media screen and (min-width: 640px) {
  main.cart .checkout .next-step {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 50%;
  }
}

@media screen and (min-width: 1024px) {
  main.cart .checkout .next-step {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 50%;
  }
}

@media screen and (min-width: 640px) {
  main.cart .cart-empty .cart-empty-message {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 1024px) {
  main.cart .cart-empty .cart-empty-message {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

@media screen and (min-width: 640px) {
  main.checkout .checkout-container {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 1024px) {
  main.checkout .checkout-container {
    width: 30%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

main.checkout .checkout-sidebar {
  margin-top: 54px;
}

@media screen and (min-width: 640px) {
  main.checkout .checkout-sidebar {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 1024px) {
  main.checkout .checkout-sidebar {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 30%;
    margin-top: 0;
  }
}

main.checkout.address form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

main.checkout.address form > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

main.checkout.shipping .shipping-options .price::before {
  content: '(';
}

main.checkout.shipping .shipping-options .price::after {
  content: ')';
}

@media screen and (min-width: 640px) {
  main.checkout.shipping .shipping-options .price {
    float: right;
    margin-top: 1px;
  }
  main.checkout.shipping .shipping-options .price::before, main.checkout.shipping .shipping-options .price::after {
    content: none;
  }
}

main.checkout.payment .billing-address-config .form-section {
  margin-bottom: 24px;
}

main.checkout.payment .billing-address-config .form-section:last-child {
  margin-bottom: 0;
}

main.checkout.payment .billing-address-config .form-section.billing-address {
  display: none;
}

main.checkout.payment .billing-address-config .form-section.shipping-address {
  display: none;
}

main.checkout.payment .billing-address-config.billing-address-required .form-section.billing-address {
  display: block;
}

main.checkout.payment .payment-create {
  margin-top: 54px;
}

@media screen and (min-width: 640px) {
  main.checkout.payment .payment-create {
    margin-top: 96px;
  }
}

main.checkout.payment .stripe-element-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

main.checkout.payment .stripe-element-group > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

main.checkout.payment .stripe-element {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  border: none;
  border-bottom: 2px solid black;
}

main.checkout.payment .stripe-element-container:not(:first-child) {
  margin-top: 12px;
}

main.checkout.payment .stripe-element-container-card-expiry {
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}

main.checkout.payment .stripe-element-container-card-cvc {
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}

main.checkout.complete .next {
  margin-top: 24px;
}

main.checkout.complete .next a {
  text-decoration: underline;
}

main.checkout.complete .celebration {
  margin-top: 54px;
}

main.shop form .form-section {
  margin-bottom: 54px;
}

@media screen and (min-width: 640px) {
  main.shop form .form-section {
    margin-bottom: 96px;
  }
}

main.shop form .form-section:last-child {
  margin-bottom: 0;
}

main.shop form .field-group {
  margin-bottom: 24px;
}

main.shop form .field-group:last-child {
  margin-bottom: 0;
}

main.shop form .field-group.box {
  border: 2px solid black;
  margin-bottom: 24px;
}

main.shop form .field-group.box label {
  margin: 0;
  padding: 6px 9px;
}

main.shop form .field-group.box label:not(:last-child) {
  border-bottom: inherit;
}

main.shop form .field-group.submit label {
  display: inline-block;
}

main.shop form .instructions {
  margin-bottom: 24px;
}

main.shop form select {
  width: auto;
}

main.shop form input[type="text"],
main.shop form input[type="email"] {
  border: none;
  border-bottom: 2px solid black;
  padding-left: 0;
  padding-right: 0;
}

main.shop form input[type="text"]:disabled,
main.shop form input[type="email"]:disabled {
  border-bottom-color: #cacaca;
}

main.shop form input[type="radio"] {
  width: 16px;
  height: 16px;
  padding: 0;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

main.shop form input[type="radio"]:checked::after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 2px;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  background-color: black;
}

main.shop .feature-row.takeover + .feature-row {
  margin-top: 54px;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row:last-child .product-tile {
    margin-bottom: 0;
  }
}

main.shop .feature-row:last-child .product-tile:last-child {
  margin-bottom: 0;
}

main.shop .feature-row .product-tile {
  display: block;
  margin-bottom: 36px;
  transition: 100ms linear color;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile {
    margin-bottom: 12px;
    color: white;
  }
}

main.shop .feature-row .product-tile:hover {
  color: black;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile:hover {
    color: white;
  }
}

main.shop .feature-row .product-tile .tile-content {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile .tile-content {
    height: 50vh;
  }
}

main.shop .feature-row .product-tile .product-image {
  padding-bottom: 75%;
  position: relative;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile .product-image {
    padding-bottom: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

main.shop .feature-row .product-tile .responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

main.shop .feature-row .product-tile .product-info {
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile .product-info {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 0;
    text-align: center;
    color: white;
    font-size: 28px;
    line-height: 36px;
    font-weight: 400;
    opacity: 0;
    transition: 0.25s linear opacity;
    background-color: rgba(0, 0, 0, 0.55);
  }
}

main.shop .feature-row .product-tile .product-info:hover {
  opacity: 1;
}

main.shop .feature-row .product-tile .product-title {
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  main.shop .feature-row .product-tile .product-title {
    font-weight: 400;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.single .grid.tall .tile-content {
    height: 75vh;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.tall .tile-content {
    height: 75vh;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.left .product-tile:first-child {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.left .product-tile:last-child {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.right .product-tile:first-child {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.right .product-tile:last-child {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  main.shop .feature-row.split .grid.center .product-tile {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.order section.order-details .order-detail-group {
  margin-bottom: 54px;
}

@media screen and (min-width: 1024px) {
  main.order section.order-details .order-detail-group {
    margin-bottom: 0;
  }
}

main.order section.order-details h2 {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  main.order section.order-details .details,
  main.order section.order-details .history,
  main.order section.order-details .payments {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  main.order section.order-details .details,
  main.order section.order-details .history,
  main.order section.order-details .payments {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

main.order section.order-details .order-number {
  margin-bottom: 24px;
}

main.order section.order-details .order-items .line-item {
  margin-bottom: 24px;
}

main.order section.order-details .order-items .line-item-info-group .label,
main.order section.order-details .order-items .line-item-info-group .value {
  display: inline-block;
}

main.order section.order-details .order-items .line-item-info-group .label::after {
  content: ':';
}

main.order section.order-details .order-totals .totals-entry .label,
main.order section.order-details .order-totals .totals-entry .value {
  display: inline-block;
}

main.order section.order-details .order-totals .totals-entry .label::after {
  content: ':';
}

main.order section.order-details .order-totals .totals-entry.total {
  margin-top: 12px;
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  main.order section.order-details .history {
    margin-left: 0%;
  }
}

main.order section.order-details .order-status-event {
  margin-bottom: 24px;
}

main.order section.order-details .order-status-event:last-child {
  margin-bottom: 0;
}

main.order section.order-details .order-status-event .label,
main.order section.order-details .order-status-event .value {
  display: inline-block;
}

main.order section.order-details .order-status-event .label::after {
  content: ':';
}

@media screen and (min-width: 1024px) {
  main.order section.order-details .payments {
    margin-left: 0%;
  }
}

main.order section.order-details .transaction {
  margin-bottom: 24px;
}

main.order section.order-details .transaction:last-child {
  margin-bottom: 0;
}

main.order section.order-details .transaction.failed {
  color: #888888;
}

main.order section.order-details .transaction-message {
  font-style: italic;
}

section.staff-filters form select {
  padding-right: 50px;
}

section.staff-filters form button.button-with-arrow::after {
  margin-left: 0;
}

section.staff-filters .filter-groups {
  padding-top: 110px;
  align-items: center;
}

section.staff-filters fieldset {
  margin-bottom: 1rem;
}

@media screen and (min-width: 1024px) {
  section.staff-filters fieldset {
    width: auto;
  }
}

section.staff-filters .dropdown-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  width: auto;
}

section.staff-filters label {
  margin-bottom: 0.5rem;
}

section.staff-filters label,
section.staff-filters select,
section.staff-filters button {
  font-size: 10px;
}

@media screen and (min-width: 640px) {
  section.staff-filters label,
  section.staff-filters select,
  section.staff-filters button {
    font-size: 15px;
  }
}

section.staff-filters button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  color: white;
  background-color: black;
  height: 59px;
  padding: 19px 50px;
}

@media screen and (min-width: 640px) {
  section.staff-filters button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  section.staff-filters button {
    font-size: 15px;
  }
}

section.staff-filters .locations {
  width: auto;
}

@media screen and (min-width: 640px) {
  section.staff-filters .locations label {
    display: flex;
    justify-content: center;
    width: auto;
  }
}

@media screen and (min-width: 640px) {
  section.staff-filters .submit {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: auto;
    width: 10%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 0%;
  }
}

section.staff-filters .submit span {
  visibility: hidden;
}

section.staff-filters span.label {
  display: none;
}

section.profiles {
  padding-top: 3rem;
  padding-bottom: 4rem;
}

section.profiles .staff-tile {
  margin-bottom: 4rem;
}

@media screen and (max-width: 640px) {
  section.profiles .staff-tile {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  section.profiles .staff-tile {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1300px) {
  section.profiles .staff-tile {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.profiles .name {
  margin-bottom: 1rem;
}

section.profiles .polaroid {
  width: 66.66667%;
  margin-left: 0;
  margin-right: 0;
  padding-right: 1rem;
  margin-bottom: 1rem;
}

@media screen and (min-width: 640px) {
  section.profiles .polaroid {
    width: 41.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.profiles .polaroid img {
  border-radius: 2px;
  box-shadow: 0 0 5px rgba(238, 238, 238, 0.75);
}

section.profiles .details {
  font-size: 12px;
  line-height: 18px;
}

@media screen and (min-width: 640px) {
  section.profiles .details {
    width: 58.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.profiles .details a {
  text-decoration: underline;
}

section.profiles .details h2 {
  font-size: 15px;
  line-height: inherit;
}

section.profiles .details .ask-me-about .ask-me-about__items div {
  padding-left: 30px;
}

section.profiles .details .label {
  font-weight: 500;
  display: inline-block;
}

section.profiles .details .office-list {
  display: inline-block;
}

section.profiles .details .office-list li {
  display: inline-block;
}

section.profiles .details .bio {
  margin-top: 0.5em;
  color: #888888;
}

section.profiles .details .bio p + p {
  margin-top: 0.5em;
}

@media screen and (min-width: 640px) {
  main.story {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 650px) {
  main.story p {
    font-size: 18px;
    line-height: 1.58;
    letter-spacing: .1px;
  }
}

main.story .header {
  margin-bottom: 47px;
  max-height: 100vh;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
  main.story .header {
    max-height: 85vh;
  }
}

main.story .header .story-title h1 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  color: black;
  margin-bottom: 0;
  margin-top: 24px;
}

@media screen and (min-width: 1024px) {
  main.story .header .story-title h1 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.story .header .story-title h1 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.story .header .story-title h1 em {
  font-style: italic;
}

main.story .header .story-title h1 strong {
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  main.story .header .story-title h1 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    padding: 24px;
  }
}

@media screen and (min-width: 640px) {
  main.story .header .story-title.light h1 {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
  }
  main.story .header .story-title.light h1::after {
    border-top-color: white;
  }
}

main.story .header .story-title br {
  display: none;
}

@media screen and (min-width: 640px) {
  main.story .header .story-title br {
    display: inline;
  }
}

main.story .header .featured-image {
  position: relative;
  height: 100vh;
  background-color: black;
}

@media screen and (min-width: 640px) {
  main.story .header .featured-image {
    height: auto;
    background-color: none;
  }
}

main.story .header .featured-image.no-mobile {
  display: none;
}

@media screen and (min-width: 640px) {
  main.story .header .featured-image.no-mobile {
    display: block;
  }
}

main.story .header .featured-image.mobile {
  display: block;
}

@media screen and (min-width: 640px) {
  main.story .header .featured-image.mobile {
    display: none;
  }
}

main.story .header .responsive-image {
  padding-bottom: 56.25%;
  height: 100vh;
  opacity: 0.7;
}

@media screen and (min-width: 640px) {
  main.story .header .responsive-image {
    height: auto;
    opacity: 1;
  }
}

@media screen and (min-width: 1024px) {
  main.story .header .responsive-image {
    padding-bottom: 40%;
  }
}

main.story .header figcaption {
  margin-top: 8px;
  margin-right: 12px;
}

main.story section.title {
  display: flex;
  position: absolute;
  top: 0;
  height: calc(100vh - 76px);
  color: white;
}

@media screen and (max-width: 640px) {
  main.story section.title {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 640px) {
  main.story section.title {
    display: block;
    position: relative;
    height: auto;
    color: black;
  }
}

main.story section.title.slides {
  display: block;
  position: relative;
  height: auto;
  color: black;
}

main.story section.title .grid {
  align-items: flex-end;
  align-content: flex-end;
}

@media screen and (min-width: 640px) {
  main.story section.title .grid {
    align-items: flex-start;
  }
}

main.story section.title .metadata {
  margin-bottom: 48px;
}

@media screen and (min-width: 640px) {
  main.story section.title .metadata {
    width: 70%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 17.5%;
    margin-right: auto;
    margin-bottom: 24px;
    padding: 0;
  }
}

main.story section.title time {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  margin-bottom: 12px;
  font-size: 17px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.story section.title time {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.story section.title time {
    font-size: 15px;
  }
}

main.story section.title h2 p {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  main.story section.title h2 p {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  main.story section.title h2 p {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

main.story section.title h2 p em {
  font-style: italic;
}

main.story section.intro {
  margin-bottom: 48px;
}

@media screen and (max-width: 640px) {
  main.story section.intro {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 640px) {
  main.story section.intro .text-content.outer {
    width: 65%;
    margin-left: 0;
    margin-right: 0;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 36px;
  }
}

main.story section.intro .top {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  main.story section.intro .top {
    margin-bottom: 0;
  }
}

main.story section.intro .contents {
  margin-bottom: 24px;
}

main.story section.intro .contents a {
  color: #888888;
  border-bottom: 1px solid #888888;
}

main.story section.intro .contents a:hover {
  color: black;
  border-bottom-color: black;
}

@media screen and (max-width: 640px) {
  main.story section.intro .big-type {
    margin-bottom: 0;
  }
}

main.story section.intro .contributors {
  margin-bottom: 12px;
}

main.story section.intro .contributors p {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  font-size: 17px;
  text-transform: none;
}

@media screen and (min-width: 640px) {
  main.story section.intro .contributors p {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.story section.intro .contributors p {
    font-size: 15px;
  }
}

main.story section.intro .contributors p a,
main.story section.intro .contributors p span {
  text-transform: uppercase;
}

@media screen and (min-width: 640px) {
  main.story .header.slideshow {
    max-height: 85vh;
  }
}

main.story section.story-content {
  position: relative;
  padding-bottom: 90px;
}

main.story figcaption.text-content p {
  line-height: 1.2;
  font-size: 17px;
  padding-right: 10px;
}

main.story figcaption.text-content p.image-title {
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
}

main.story .text-content.attribution {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: none;
  font-size: 14px;
  display: block;
  min-height: 25px;
  text-transform: none;
  font-size: 12px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  main.story .text-content.attribution {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  main.story .text-content.attribution {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  main.story .text-content.attribution {
    display: block;
    font-size: 14px;
  }
}

main.story .text-content.attribution.masthead {
  display: block;
  position: absolute;
  right: 12px;
  text-align: right;
}

@media screen and (min-width: 640px) {
  main.story .text-content.attribution.masthead {
    position: relative;
  }
}

@media screen and (min-width: 640px) {
  main.story .text-content .content p:not(.big-type) {
    font-size: 18px;
  }
}

main.story .sharing {
  margin: 0;
  padding: 0;
  justify-content: flex-start;
}

main.story .sharing li {
  margin-bottom: 0;
  margin-left: 0;
}

main.story .sharing li:before {
  display: none;
}

main.story .sharing ul {
  padding-left: 0;
}

main.story .explore {
  display: none;
}

@media screen and (min-width: 640px) {
  main.story .explore {
    display: flex;
    padding-bottom: 54px;
  }
}

main.story .explore .explore-wrapper {
  display: flex;
}

main.zine section.header .pagination .prev {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+CiAgPHRpdGxlPmFycm93LWxlZnQtc21hbGwtYmxhY2s8L3RpdGxlPgogIDxnIGlkPSJJY29uIj4KICAgIDxwb2x5bGluZSBwb2ludHM9IjQzLjYyNSA0Ny4zMzYgNC45MzkgMjUgNDMuNjI1IDIuNjY0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMyMzFmMjA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+CiAgPC9nPgo8L3N2Zz4K");
}

@media screen and (min-width: 640px) {
  main.zine section.header .pagination .prev {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4KICA8dGl0bGU+YXJyb3ctbGVmdC1ibGFjazwvdGl0bGU+CiAgPGcgaWQ9Ikljb24iPgogICAgPHBvbHlsaW5lIHBvaW50cz0iODcuNSA2LjY5OSAxMi41IDUwIDg3LjUgOTMuMzAxIiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMyMzFmMjA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+CiAgPC9nPgo8L3N2Zz4K");
  }
}

main.zine section.header .pagination .next {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LXNtYWxsLWJsYWNrPC90aXRsZT4KICA8ZyBpZD0iSWNvbiI+CiAgICA8cG9seWxpbmUgcG9pbnRzPSI2LjM3NSAyLjY2NCA0NS4wNjEgMjUgNi4zNzUgNDcuMzM2IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMyMzFmMjA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+CiAgPC9nPgo8L3N2Zz4K");
}

@media screen and (min-width: 640px) {
  main.zine section.header .pagination .next {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMDAgMTAwIj4KICA8dGl0bGU+YXJyb3ctcmlnaHQtYmxhY2s8L3RpdGxlPgogIDxnIGlkPSJJY29uIj4KICAgIDxwb2x5bGluZSBwb2ludHM9IjEyLjUgNi42OTkgODcuNSA1MCAxMi41IDkzLjMwMSIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjMjMxZjIwO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgogIDwvZz4KPC9zdmc+Cg==");
  }
}

@media screen and (min-width: 640px) {
  address .street,
  address .region {
    display: block;
  }
}

address .street .route::after {
  content: ', ';
}

@media screen and (min-width: 640px) {
  address .street .route::after {
    display: none;
  }
}

.media-metadata .meta-group {
  margin-bottom: 0.5em;
}

.media-metadata .group-label {
  display: inline;
  font-weight: bold;
  margin-right: 0.15em;
}

.media-metadata .group-content {
  display: inline;
}

ul.links {
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;
}

ul.links .link {
  display: inline-block;
  margin-right: 0.5em;
}

ul.links li {
  position: relative;
  margin-bottom: 11px;
}

@media screen and (min-width: 640px) {
  ul.links li {
    margin-bottom: 4.8px;
  }
}

ul.links a {
  font-size: 15px;
  text-transform: uppercase;
  color: black;
  border-bottom: 1px solid black;
  transition: 100ms linear border-bottom-color;
  margin-left: 21.6px;
}

@media screen and (min-width: 640px) {
  ul.links a {
    font-size: 15px;
    border-bottom: 1px solid transparent;
  }
}

@media screen and (min-width: 1300px) {
  ul.links a {
    font-size: 15px;
  }
}

ul.links a:hover {
  border-bottom: 1px solid black;
}

ul.links a:before {
  content: '';
  display: inline-block;
  position: absolute;
  width: 17px;
  height: 17px;
  top: 3px;
  left: -2px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNSAxNiIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMTUgMTY7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHRpdGxlPuKGkjwvdGl0bGU+CjxkZXNjPkNyZWF0ZWQgd2l0aCBTa2V0Y2guPC9kZXNjPgo8cG9seWdvbiBwb2ludHM9IjEzLjgsOC4yIDguMywxMy4yIDguMywwIDYuNywwIDYuNywxMy4yIDEuMiw4LjIgMCw5LjMgNy41LDE2IDE1LDkuMyAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transform: rotate(-135deg);
}

@media screen and (min-width: 640px) {
  ul.links a:before {
    width: 14px;
    height: 12px;
    top: 6px;
  }
}

@media screen and (min-width: 1300px) {
  ul.links a:before {
    width: 16px;
    height: 15px;
    top: 4px;
  }
}

img.lazy {
  transition: 150ms linear opacity;
  opacity: 0;
}

img.lazy.loaded {
  opacity: 1;
}

.responsive-image {
  background-color: #eeeeee;
  position: relative;
  overflow: hidden;
}

.responsive-image img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

.responsive-image.with-ratio-auto img {
  max-width: 100%;
  backface-visibility: hidden;
}

.responsive-svg {
  width: 100%;
  position: relative;
}

.responsive-svg svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video {
  padding-bottom: 56.25%;
  width: 100%;
  border: none;
  position: relative;
}

.modal-scrollable .responsive-video {
  margin-top: 10vh;
}

.responsive-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.hero-media .slideshow .attribution {
  display: none !important;
}

.hero-media .slideshow figcaption {
  display: none !important;
}

.slideshow {
  position: relative;
  overflow: hidden;
}

.slideshow.space-filling {
  padding-bottom: 100%;
}

.slideshow.space-filling .slides {
  position: absolute;
}

.slideshow.single-slide .pagination .image-button,
.slideshow.single-slide .indicators {
  display: none;
}

.slideshow.touch .slides {
  transition: none;
}

.slideshow .slides {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.slideshow .slides .slide {
  float: left;
  overflow: hidden;
  background: none;
  padding: 0;
  position: relative;
  transition: 150ms linear opacity;
  opacity: 0;
}

.slideshow .slides .slide.loaded {
  opacity: 1;
}

.slideshow .slides img {
  display: block;
  max-width: none;
  transition: 150ms linear opacity;
}

.slideshow .slides img.loaded {
  opacity: 1;
}

.slideshow .slides .text-content {
  position: relative;
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  .slideshow .slides .text-content {
    margin-top: 24px;
  }
}

.slideshow .slides .text-content.attribution {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: none;
  font-size: 14px;
  min-height: 25px;
  text-align: right;
  text-transform: none;
  margin-bottom: 12px;
  font-size: 14px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  .slideshow .slides .text-content.attribution {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .slideshow .slides .text-content.attribution {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .slideshow .slides .text-content.attribution {
    display: block;
    font-size: 14px;
  }
}

.slideshow .slides .text-content .slide-title {
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  font-size: 18px;
}

.slideshow .image-button__wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.slideshow .image-button__wrapper .image-button__inner-wrapper {
  transform: translate3d(0, 0, 0);
  flex: 1;
  margin-top: 48px;
  width: 100%;
}

@media screen and (min-width: 640px) {
  .slideshow .image-button__wrapper .image-button__inner-wrapper {
    margin-top: 0;
  }
}

.slideshow .image-button__wrapper .carousel-info__wrapper {
  opacity: 0;
}

.slideshow .pagination {
  position: relative;
  width: 100%;
  flex: 1;
  margin-top: 50px;
  z-index: 1;
  pointer-events: none;
}

@media screen and (min-width: 640px) {
  .slideshow .pagination {
    position: absolute;
    height: 100%;
    top: 50%;
    margin-top: 0;
    transform: translate3d(0, -50%, 0);
  }
}

.slideshow .pagination .prev,
.slideshow .pagination .next {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1;
  position: absolute;
  width: 40px;
  height: 100%;
  pointer-events: auto;
  background-size: 40px 40px;
}

@media screen and (min-width: 640px) {
  .slideshow .pagination .prev,
  .slideshow .pagination .next {
    width: 25px;
    background-size: 25px 45px;
  }
}

.slideshow .pagination .prev {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMyA0MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjMgNDEiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+VHJpYW5nbGU8L3RpdGxlPgo8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KPHBvbHlnb24gZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIyMi43LDEuNyAyMS4zLDAuMyAwLjYsMjAuNSAyMS4zLDQwLjYgMjIuNywzOS4yIDMuNCwyMC41ICIvPgo8L3N2Zz4K");
  left: 0;
  margin-left: 12px;
}

@media screen and (min-width: 640px) {
  .slideshow .pagination .prev {
    padding-left: 76px;
    margin-left: 0;
  }
}

.slideshow .pagination .next {
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMyA0MSIgZW5hYmxlLWJhY2tncm91bmQ9Im5ldyAwIDAgMjMgNDEiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8dGl0bGU+VHJpYW5nbGU8L3RpdGxlPgo8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KPHBvbHlnb24gZmlsbD0iI0ZGRkZGRiIgcG9pbnRzPSIwLjYsMzkuMiAyLDQwLjYgMjIuNywyMC40IDIsMC4zIDAuNiwxLjcgMTkuOSwyMC40ICIvPgo8L3N2Zz4K");
  right: 0;
  margin-right: 12px;
}

@media screen and (min-width: 640px) {
  .slideshow .pagination .next {
    padding-right: 76px;
    margin-right: 0;
  }
}

.slideshow .indicators {
  position: absolute;
  bottom: 12px;
  left: 0;
  width: 100%;
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .slideshow .indicators {
    bottom: 24px;
  }
}

.slideshow .indicators .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  margin: 0 0.5em;
}

.slideshow .indicators .dot:first-of-type, .slideshow .indicators .dot:last-of-type {
  display: none;
}

.slideshow .indicators .dot.on {
  background-color: rgba(255, 255, 255, 0.75);
}

.stills {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: 150ms linear opacity 350ms;
}

.stills.playing {
  transition: 150ms linear opacity;
  opacity: 1;
}

.stills .still {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

button.play-video {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1;
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;
  width: 79px;
  height: 91px;
  transform: translate3d(-50%, -50%, 0);
}

@media screen and (min-width: 640px) {
  button.play-video {
    width: 99px;
    height: 114px;
  }
}

.video-preview {
  position: relative;
  cursor: pointer;
}

.video-preview.aspect-ratio-letterbox .responsive-image {
  padding-bottom: 42.85714%;
}

.video-preview.aspect-ratio-widescreen .responsive-image {
  padding-bottom: 56.25%;
}

.video-preview.aspect-ratio-fullscreen .responsive-image {
  padding-bottom: 75%;
}

.video-preview.aspect-ratio-square .responsive-image {
  padding-bottom: 100%;
}

.block.headline .headline-container {
  padding: 24px 0;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
}

.block.headline .headline-image {
  margin-bottom: 18px;
}

.block.headline .headline-title {
  margin-bottom: 18px;
}

.block.headline .headline-title h2 {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
}

@media screen and (min-width: 1024px) {
  .block.headline .headline-title h2 {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
  }
}

.block.headline .headline-summary {
  font-size: 18px;
  line-height: 1.4;
}

.block.headline .headline-summary a {
  text-decoration: underline;
  color: black;
  display: inline-block;
}

.block.headline .headline-summary a:hover {
  color: #888888;
}

.block.headline .headline-attribution {
  font-size: 18px;
  line-height: 1.4;
  font-weight: 500;
}

@media screen and (min-width: 640px) {
  .block.text .text-content p,
  .block.text .text-content li {
    font-size: 22px;
  }
}

.block.text .text-content.large,
.block.text .text-content .big-type {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
  line-height: 1.15;
}

@media screen and (min-width: 1024px) {
  .block.text .text-content.large,
  .block.text .text-content .big-type {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .block.text .text-content.large,
  .block.text .text-content .big-type {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

@media screen and (min-width: 640px) {
  .block.text .text-content.large,
  .block.text .text-content .big-type {
    line-height: 1.15;
  }
}

@media screen and (min-width: 1024px) {
  .block.text .text-content.large,
  .block.text .text-content .big-type {
    line-height: 1.15;
  }
}

@media screen and (min-width: 1300px) {
  .block.text .text-content.large,
  .block.text .text-content .big-type {
    line-height: 1.15;
    font-size: 32px;
  }
}

.block.instagram figure {
  position: relative;
}

.block.instagram figcaption {
  margin-top: 24px;
}

.block.instagram figcaption a,
.block.instagram figcaption p {
  display: inline;
  text-transform: none;
  font-size: 18px;
  line-height: 1.4;
}

.block.instagram figcaption a {
  border-bottom: 1px solid black;
}

.block.instagram figcaption a:hover {
  color: #888888;
}

.block.review .review-headline {
  margin-bottom: 18px;
}

.block.review .review-headline h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  .block.review .review-headline h2 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .block.review .review-headline h2 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

.block.review .review-attribution {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
}

@media screen and (min-width: 640px) {
  .block.review .review-attribution {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .block.review .review-attribution {
    font-size: 15px;
  }
}

.block.review .review-link {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
  overflow: hidden;
  margin-top: 48px;
}

@media screen and (min-width: 640px) {
  .block.review .review-link {
    line-height: 1;
  }
}

@media screen and (min-width: 1024px) {
  .block.review .review-link {
    line-height: 1;
    letter-spacing: 0.3px;
  }
}

@media screen and (min-width: 1300px) {
  .block.review .review-link {
    line-height: 1;
  }
}

.block.review .review-link span {
  display: inline-block;
  transition: transform 200ms ease-in-out 25ms;
  transform: translate3d(0, -3px, 0);
}

@media screen and (max-width: 640px) {
  .block.review .review-link span {
    transform: none;
  }
}

.block.review .review-link:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 41px;
  height: 15px;
  margin-right: 33px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTUgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgcG9pbnRzPSI0My4zLDAgNTUsMTAgNDMuMywyMCA0Mi40LDE5LjEgNTIuMiwxMC43IDAsMTAuNyAwLDkuMyA1Mi4yLDkuMyAKCTQyLjQsMC45IDQzLjMsMCAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  .block.review .review-link:before {
    width: 34px;
    height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .block.review .review-link:before {
    width: 45px;
    height: 17px;
  }
}

@media screen and (min-width: 1300px) {
  .block.review .review-link:before {
    width: 55px;
    height: 20px;
  }
}

.block.review .review-link:hover span {
  transform: translate3d(-25px, -3px, 0);
}

@media screen and (max-width: 640px) {
  .block.review .review-link:hover span {
    transform: translate3d(-25px, 0, 0);
  }
}

.block.review .review-link:hover:before {
  transform: translate3d(-50%, 0, 0);
}

.block.tweet .tweet {
  position: relative;
}

.block.tweet .tweet-image {
  margin-top: 24px;
}

.block.tweet .tweet-text {
  word-wrap: break-word;
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .block.tweet .tweet-text {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .block.tweet .tweet-text {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.block.tweet .tweet-text a {
  color: black;
  border-bottom: 1px solid black;
}

.block.tweet .tweet-text a:hover {
  color: #888888;
  border-bottom: 1px solid #888888;
}

.block.tweet .tweet-text a.is-hashtag {
  border-bottom: 1px solid black;
}

.block.tweet .tweet-attribution {
  margin-top: 6px;
  color: #888888;
}

@media screen and (min-width: 1024px) {
  .block.tweet .tweet-attribution {
    margin-top: 12px;
  }
}

.block.tweet .tweet-attribution a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  color: black;
  transition: all 100ms ease-in-out;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 640px) {
  .block.tweet .tweet-attribution a {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .block.tweet .tweet-attribution a {
    font-size: 15px;
  }
}

.block.tweet .tweet-attribution a:hover {
  color: #888888;
  border-bottom: 1px solid #888888;
}

.block.tweet .tweet-attribution a.is-hashtag {
  border-bottom: 1px solid black;
}

.block.website .browser-frame {
  display: block;
}

.block.website .toolbar {
  padding: 5px;
}

.block.website .buttons {
  width: 25px;
}

.block.website .browser-content {
  position: relative;
}

.block.website .link {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
  overflow: hidden;
  margin-top: 24px;
}

@media screen and (min-width: 640px) {
  .block.website .link {
    line-height: 1;
  }
}

@media screen and (min-width: 1024px) {
  .block.website .link {
    line-height: 1;
    letter-spacing: 0.3px;
  }
}

@media screen and (min-width: 1300px) {
  .block.website .link {
    line-height: 1;
  }
}

.block.website .link span {
  display: inline-block;
  transition: transform 200ms ease-in-out 25ms;
  transform: translate3d(0, -3px, 0);
}

@media screen and (max-width: 640px) {
  .block.website .link span {
    transform: none;
  }
}

.block.website .link:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 41px;
  height: 15px;
  margin-right: 33px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTUgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgcG9pbnRzPSI0My4zLDAgNTUsMTAgNDMuMywyMCA0Mi40LDE5LjEgNTIuMiwxMC43IDAsMTAuNyAwLDkuMyA1Mi4yLDkuMyAKCTQyLjQsMC45IDQzLjMsMCAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  .block.website .link:before {
    width: 34px;
    height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .block.website .link:before {
    width: 45px;
    height: 17px;
  }
}

@media screen and (min-width: 1300px) {
  .block.website .link:before {
    width: 55px;
    height: 20px;
  }
}

.block.website .link:hover span {
  transform: translate3d(-25px, -3px, 0);
}

@media screen and (max-width: 640px) {
  .block.website .link:hover span {
    transform: translate3d(-25px, 0, 0);
  }
}

.block.website .link:hover:before {
  transform: translate3d(-50%, 0, 0);
}

aside.shop-hud .back-to-shop {
  display: none;
}

@media screen and (min-width: 1024px) {
  aside.shop-hud .back-to-shop {
    display: block;
  }
}

aside.shop-hud .back-to-shop a {
  color: #888888;
  text-decoration: underline;
}

aside.shop-hud .back-to-shop a::before {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LWxlZnQtbWljcm88L3RpdGxlPgogIDxwb2x5bGluZSBwb2ludHM9IjEzLjMzIDE0IDQuNjcgOSAxMy4zMyA0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-right: 12px;
}

aside.shop-hud .back-to-shop a::before {
  vertical-align: -13%;
}

aside.shop-hud .back-to-shop a:hover {
  color: black;
}

.checkout-sidebar .sidebar-section:not(:first-child) {
  margin-top: 24px;
}

.checkout-sidebar .sidebar-section h3 {
  margin-bottom: 12px;
}

.checkout-sidebar .sidebar-attribute-group .label,
.checkout-sidebar .sidebar-attribute-group .value {
  display: inline-block;
}

.checkout-sidebar .sidebar-attribute-group .label {
  margin-right: 2px;
}

.checkout-sidebar .sidebar-attribute-group .label::after {
  content: ':';
}

.checkout-sidebar .cart-quick-view .cart-item:not(:first-child) {
  margin-top: 24px;
}

.checkout-sidebar .cart-quick-view .item-title .label {
  display: none;
}

.checkout-sidebar .totals .total:not(:first-child) {
  margin-top: 24px;
}

.checkout-sidebar .totals .total-price .label {
  font-weight: 500;
}

.flash {
  padding: 12px 15px;
  background-color: #eeeeee;
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  .flash {
    padding: 18px 21px;
  }
}

.flash:first-child {
  margin-top: 0;
}

.flash.error {
  color: white;
  background-color: black;
}

.flash.size-compact {
  padding: 9px 12px;
  margin-bottom: 12px;
}

.flash.size-inline {
  padding: 0;
  background-color: transparent;
  color: red;
  font-style: italic;
}

.how-to-print {
  margin-top: 36px;
}

.how-to-print button {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
  cursor: pointer;
  padding: 4px 12px;
  width: auto;
  display: inline-block;
  min-width: 160px;
  margin-bottom: 24px;
}

.how-to-print button::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LWRvd24tbWljcm88L3RpdGxlPgogIDxwb2x5bGluZSBwb2ludHM9IjE0IDQuNjcgOSAxMy4zMyA0IDQuNjciIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogIzAwMDtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
  margin-left: 24px;
}

.how-to-print button::after {
  float: right;
  margin-top: 2px;
}

.how-to-print button.open::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXVwLW1pY3JvPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0IDEzLjMzIDkgNC42NyAxNCAxMy4zMyIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjMDAwO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgo8L3N2Zz4K");
  margin-left: 24px;
}

.how-to-print .printing-options {
  display: none;
}

.how-to-print .printing-options a {
  color: #888888;
  text-decoration: underline;
}

.how-to-print .printing-options a:hover {
  color: black;
}

aside.shop-hud .shop-hud-content-margin > :last-child,
aside.shop-hud .shop-hud-content-center > :last-child {
  margin-bottom: 54px;
}

aside.shop-hud .shop-hud-content-margin {
  display: none;
}

@media screen and (min-width: 1024px) {
  aside.shop-hud .shop-hud-content-margin {
    display: block;
    width: 20%;
    margin-left: 0;
    margin-right: 0;
  }
}

aside.shop-hud .shop-hud-content-center {
  margin-bottom: 12px;
}

@media screen and (min-width: 1024px) {
  aside.shop-hud .shop-hud-content-center {
    margin-bottom: 0;
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

aside.shop-hud .shop-hud-checkout-progress {
  margin-bottom: 24px;
}

aside.shop-hud .shop-hud-checkout-progress .cart-hud {
  display: inline-block;
}

aside.shop-hud .shop-hud-checkout-progress ol {
  display: inline-block;
}

@media screen and (min-width: 640px) {
  aside.shop-hud .shop-hud-checkout-progress ol li {
    display: inline-block;
  }
}

aside.shop-hud .shop-hud-checkout-progress .step {
  color: #888888;
}

@media screen and (min-width: 640px) {
  aside.shop-hud .shop-hud-checkout-progress .step:not(:last-child)::after {
    content: '>';
    margin: 0 6px;
    color: #888888;
    font-weight: 400;
  }
}

aside.shop-hud .shop-hud-checkout-progress .step.current {
  font-weight: 500;
  color: black;
}

aside.shop-hud .shop-hud-checkout-progress .step.bag {
  margin-bottom: 12px;
}

@media screen and (min-width: 640px) {
  aside.shop-hud .shop-hud-checkout-progress .step.bag {
    margin-bottom: 0;
  }
}

aside.shop-hud .shop-hud-checkout-progress a:hover {
  color: black;
}

.option-selection .label {
  display: inline-block;
  vertical-align: top;
  line-height: 1;
  margin-right: 12px;
}

.option-selection .label::after {
  content: ':';
}

.option-selection .variant-selector {
  display: inline-block;
  cursor: default;
}

.option-selection .variant-selector li {
  display: inline-block;
  margin-right: 6px;
}

.option-selection .variant-selector li.unavailable {
  pointer-events: none;
}

.option-selection .variant-selector li.unavailable label {
  border-color: #cacaca;
  color: #cacaca;
}

.option-selection .variant-selector label {
  border: 2px solid black;
  transition: 0.1s linear background-color, 0.1s linear color;
  padding: 2px 9px 0px;
  margin: 0;
  cursor: pointer;
}

.option-selection .variant-selector input:checked + label {
  background-color: black;
  color: white;
}

.option-selection .variant-selector input[type="radio"] {
  display: none;
}

.product-detail .product-title {
  margin-bottom: 24px;
}

.product-detail .product-title h1 {
  font-size: 40px;
  line-height: 50px;
  font-weight: 400;
}

@media screen and (min-width: 640px) {
  .product-detail .product-title h1 {
    font-size: 100px;
    line-height: 110px;
  }
}

.product-detail .product-image {
  margin-bottom: 54px;
}

@media screen and (min-width: 1024px) {
  .product-detail .product-image figure {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

.product-detail .product-image figcaption {
  display: none;
}

.product-detail .product-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

.product-detail .product-info > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.product-detail .product-info .product-text {
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .product-detail .product-info .product-text {
    margin-bottom: 0;
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .product-info .product-text {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 10%;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .product-info .product-purchase {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .product-info .product-purchase {
    width: 20%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 10%;
  }
}

.product-detail .product-supplemental {
  margin-bottom: 36px;
}

.product-detail .product-supplemental .text-content {
  color: #888888;
}

.product-detail .product-supplemental .size-chart-table {
  display: none;
}

.product-detail .product-price {
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  margin-bottom: 36px;
}

@media screen and (min-width: 1024px) {
  .product-detail .product-price {
    font-weight: 400;
    font-size: 40px;
    line-height: 50px;
  }
}

.product-detail .option-selection {
  margin-bottom: 36px;
}

.product-detail .quantity-select {
  margin-bottom: 36px;
}

.product-detail .waitlist {
  margin-top: 24px;
}

.product-detail .sharing {
  margin-top: 54px;
}

.product-detail .additional-images {
  margin-top: 54px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

.product-detail .additional-images > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.product-detail .additional-images .additional-image-container {
  margin-bottom: 12px;
}

@media screen and (min-width: 640px) {
  .product-detail .additional-images .additional-image-container {
    position: relative;
    overflow: hidden;
  }
  .product-detail .additional-images .additional-image-container img {
    position: absolute;
    top: 0;
    left: 0;
    max-width: none;
  }
}

.product-detail .additional-images figcaption {
  display: none;
}

@media screen and (min-width: 1024px) {
  .product-detail .image-row {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 10%;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .image-row.double .additional-image-container {
    height: 50vw;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .image-row.double .additional-image-container {
    height: 30vw;
  }
}

.product-detail .image-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

.product-detail .image-group > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .product-detail .image-group .additional-image {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .image-group.left .additional-image:first-child {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .image-group.left .additional-image:first-child {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .image-group.left .additional-image:last-child {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .image-group.left .additional-image:last-child {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .image-group.right .additional-image:first-child {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .image-group.right .additional-image:first-child {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .product-detail .image-group.right .additional-image:last-child {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .product-detail .image-group.right .additional-image:last-child {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

form.product-download .loader {
  margin-left: 12px;
}

form.product-download .messages {
  margin-top: 12px;
}

form.product-download button {
  min-width: 160px;
}

.quantity-select button {
  font-size: inherit;
}

.quantity-select .quantity-label {
  display: inline-block;
}

.quantity-select .quantity-label::after {
  content: ':';
}

.quantity-select .quantity-ui {
  display: inline-block;
}

.quantity-select .quantity-ui > * {
  display: inline-block;
  width: 24px;
  text-align: center;
}

.quantity-select .quantity-ui-button {
  border: none;
  padding: 0;
  line-height: inherit;
}

.quantity-select .quantity-ui-button:hover {
  background-color: #eeeeee;
}

.quantity-select .quantity-submit {
  padding: 0;
  width: auto;
  color: #888888;
  text-decoration: underline;
  margin-left: 12px;
}

.quantity-select .quantity-submit:hover {
  background-color: transparent;
  color: black;
}

.size-chart-table {
  max-width: 500px;
  margin: 15vh auto;
  padding: 12px;
  border: 2px solid white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media screen and (min-width: 640px) {
  .size-chart-table {
    padding: 36px;
  }
}

.size-chart-table .size-chart-row {
  display: flex;
  flex-wrap: none;
  width: 100%;
  margin-bottom: 12px;
}

.size-chart-table .size-chart-row:last-child {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  .size-chart-table .size-chart-row {
    margin-bottom: 24px;
  }
}

.size-chart-table .size-chart-datum {
  width: 10%;
  flex-grow: 1;
}

.modal .size-chart-table {
  color: white;
}

.waitlist .email {
  position: relative;
}

.waitlist .email .label {
  padding-right: 24px;
}

.waitlist .waitlist-email {
  padding-right: 24px;
}

.waitlist .waitlist-submit {
  position: absolute;
  top: 32px;
  right: 0;
  border: 0;
  padding: 0;
}

.waitlist .waitlist-submit::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

.waitlist .waitlist-submit:disabled::after {
  content: '';
  width: 1em;
  height: 1em;
  display: inline-block;
  vertical-align: -17%;
  background-size: 18px 18px;
  background-position: center center;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxOCAxOCI+CiAgPHRpdGxlPmFycm93LXJpZ2h0LW1pY3JvLWdyZXktbWlkPC90aXRsZT4KICA8cG9seWxpbmUgcG9pbnRzPSI0LjY3IDQgMTMuMzMgOSA0LjY3IDE0IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICNjYWNhY2E7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  margin-left: 24px;
}

html {
  --burger-width: 1.8vw;
  --burger-height: 10px;
}

@media screen and (max-width: 666px) {
  html {
    --burger-width: 21.875px;
    --burger-height: 8px;
  }
}

@media screen and (min-width: 667px) and (max-width: 1150px) {
  html {
    --burger-width: 2.7vw;
    --burger-height: 8px;
  }
}

.burger {
  --burger-color: black;
  --burger-color-current-page: black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  position: fixed;
  top: var(--header-padding-top);
  left: var(--header-padding-horiz);
  z-index: 4;
  height: var(--burger-height);
  margin: -6px 0  0 0;
  padding: 6px;
  box-sizing: content-box;
  cursor: pointer;
  color: var(--burger-color);
  line-height: 1;
  transition: 0.25s cubic-bezier(0, 1, 0.25, 1) width, transform 0.2s ease-in-out;
}

@media screen and (min-width: 640px) {
  .burger {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .burger {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .burger:not(.active):hover {
    --burger-color: #888888;
  }
  .burger:not(.active):hover .bun.top {
    transform: translate(0, calc(-0.5 * (var(--burger-height) + 2px)));
  }
  .burger:not(.active):hover .bun.bottom {
    transform: translate(0, calc(0.5 * (var(--burger-height) + 2px)));
  }
}

.burger.active {
  --burger-color: white;
}

.burger.active .bun.top {
  transform: rotate(45deg);
}

.burger.active .bun.bottom {
  transform: rotate(-45deg);
}

.burger.active .burger-label__breadcrumbs::before {
  content: 'Close';
}

.burger.active .burger-label__breadcrumbs::after {
  display: none;
}

.burger.hidden:not(:hover) .burger-label {
  opacity: 0;
}

.burger .piece {
  position: absolute;
  top: 50%;
  left: 0;
  width: var(--burger-width);
  border-bottom: 1px solid var(--burger-color);
  transition: 0.25s cubic-bezier(0, 1, 0.25, 1) all;
}

.burger .bun.top {
  transform: translate(0, calc(-0.5 * var(--burger-height)));
}

.burger .bun.bottom {
  transform: translate(0, calc(0.5 * var(--burger-height)));
}

.burger .burger-label {
  height: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: calc(var(--burger-width) * 1.333);
  transition: 0.5s ease opacity;
}

.burger .burger-label__breadcrumbs {
  display: flex;
}

.burger .burger-label__breadcrumbs::before {
  content: 'Menu';
}

.burger .burger-label__breadcrumbs::after {
  color: var(--burger-color-current-page);
}

@media screen and (min-width: 640px) {
  body.films .burger .burger-label__breadcrumbs::after {
    content: '\00a0/\00a0 Films';
  }
}

@media screen and (min-width: 640px) {
  body.television .burger .burger-label__breadcrumbs::after {
    content: '\00a0/\00a0 Television';
  }
}

@media screen and (min-width: 640px) {
  body.docs .burger .burger-label__breadcrumbs::after {
    content: '\00a0/\00a0 Docs';
  }
}

@media screen and (min-width: 640px) {
  body.about .burger .burger-label__breadcrumbs::after {
    content: '\00a0/\00a0 About';
  }
}

@media screen and (min-width: 640px) {
  body.notes .burger .burger-label__breadcrumbs::after {
    content: '\00a0/\00a0 Notes';
  }
}

header.home:not(.sticky) ~ .burger,
header.hero:not(.sticky) ~ .burger {
  --burger-color: white;
  --burger-color-current-page: white;
}

.divider {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-bottom: 2rem;
}

.divider::before {
  content: '';
  display: block;
  border-top: 1px solid #888888;
}

.explore {
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 640px) {
  .explore {
    margin: 0 38px;
    padding: 32px 0 48px;
    border-top: 0.5px solid black;
  }
}

.explore .button-container {
  position: relative;
  display: block;
  height: auto;
  padding: 0;
}

.explore .button-container .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
  border-top: 1px solid black;
}

@media screen and (min-width: 640px) {
  .explore .button-container .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .explore .button-container .button {
    font-size: 15px;
  }
}

.explore .button-container .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

.explore .button-container .button.active:after {
  transform: rotate(180deg);
}

.explore .button-container .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  .explore .button-container .button {
    display: none;
  }
}

.explore .button-container a.button {
  width: calc(100% - 30px);
  margin: 0 17px;
  cursor: pointer;
}

.explore .explore-wrapper {
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

.explore .explore-wrapper.active {
  max-height: none;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  .explore .explore-wrapper {
    overflow: visible;
    max-height: none;
  }
}

.explore h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  padding: 0 0 24px;
  margin-bottom: 33px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 640px) {
  .explore h6 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .explore h6 {
    font-size: 15px;
  }
}

.explore .secondary-features {
  padding: 0;
}

@media screen and (min-width: 640px) {
  .explore .secondary-features {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 40px 0 0;
  }
}

.explore .secondary-features.full {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  padding: 0;
}

.explore .secondary-features.full .related-title {
  display: none;
  padding: 24px 0 20px;
  border-bottom: none;
}

@media screen and (min-width: 640px) {
  .explore .secondary-features.full .related-title {
    display: block;
  }
}

@media screen and (min-width: 640px) {
  .explore .secondary-features.full .feature-tile {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    padding: 0 42px 0 0;
    margin-bottom: 24px;
  }
  .explore .secondary-features.full .feature-tile:nth-child(3) {
    padding: 0;
  }
  .explore .secondary-features.full .feature-tile:last-of-type {
    display: none;
    padding: 0;
  }
}

@media screen and (min-width: 1024px) {
  .explore .secondary-features.full .feature-tile {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
  }
  .explore .secondary-features.full .feature-tile:nth-child(3) {
    padding: 0 42px 0 0;
  }
  .explore .secondary-features.full .feature-tile:last-of-type {
    display: block;
  }
}

.explore .secondary-features.full .related-eyebow {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  font-family: "NB International Mono Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: -0.065em;
  border-bottom: 0;
  padding: 0;
  margin-bottom: 3px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  .explore .secondary-features.full .related-eyebow {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .explore .secondary-features.full .related-eyebow {
    font-size: 15px;
  }
}

.explore .primary-features {
  padding: 24px 0 0;
  margin-top: 24px;
  border-top: 1px solid black;
}

@media screen and (min-width: 640px) {
  .explore .primary-features {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin-top: 0;
    border-top: none;
  }
}

.explore .feature-tile {
  margin-bottom: 54px;
  padding: 0;
}

@media screen and (min-width: 640px) {
  .explore .feature-tile {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.secondary:first-of-type {
    padding: 0 42px 0 0;
  }
}

.explore .feature-tile.secondary:last-of-type {
  margin-bottom: 0;
}

@media screen and (min-width: 640px) {
  .explore .feature-tile.secondary:last-of-type {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.secondary:last-of-type {
    display: block;
  }
}

.explore .feature-tile.ready .responsive-image {
  background-color: black;
}

.explore .feature-tile.primary {
  margin-bottom: 12px;
}

.explore .feature-tile.primary h4 {
  margin-bottom: 12px;
}

.explore .feature-tile.primary h3,
.explore .feature-tile.primary h3 p {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.primary h3,
  .explore .feature-tile.primary h3 p {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .explore .feature-tile.primary h3,
  .explore .feature-tile.primary h3 p {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.explore .feature-tile.primary h3 em,
.explore .feature-tile.primary h3 p em {
  font-style: italic;
}

.explore .feature-tile.primary h3 strong,
.explore .feature-tile.primary h3 p strong {
  font-weight: 500;
}

.explore .feature-tile.primary h3 br,
.explore .feature-tile.primary h3 p br {
  display: none;
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.primary h3 br,
  .explore .feature-tile.primary h3 p br {
    display: block;
  }
}

.explore .feature-tile.primary .feature-titling {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.explore .feature-tile.primary .feature-image {
  min-height: 288px;
}

@media screen and (min-width: 640px) {
  .explore .feature-tile.secondary {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.secondary {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

.explore .feature-tile.secondary h3,
.explore .feature-tile.secondary h3 p {
  font-weight: 500;
  font-size: 24px;
  line-height: 1;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 1024px) {
  .explore .feature-tile.secondary h3,
  .explore .feature-tile.secondary h3 p {
    font-size: 28px;
    letter-spacing: -0.025em;
    line-height: 1;
  }
}

@media screen and (min-width: 1300px) {
  .explore .feature-tile.secondary h3,
  .explore .feature-tile.secondary h3 p {
    font-size: 38px;
    letter-spacing: -0.03em;
    line-height: 1;
  }
}

.explore .feature-tile.secondary h3 em,
.explore .feature-tile.secondary h3 p em {
  font-style: italic;
}

.explore .feature-tile.secondary h3 strong,
.explore .feature-tile.secondary h3 p strong {
  font-weight: 500;
}

.explore .feature-tile.secondary .feature-titling {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0 16px;
}

@media screen and (min-width: 640px) {
  .explore .feature-tile.secondary .feature-titling {
    margin: 0;
  }
}

.explore .feature-tile.secondary .feature-image {
  width: 100%;
  min-height: 288px;
}

.explore img {
  backface-visibility: hidden;
}

.explore .tile-content {
  display: block;
  position: relative;
}

.explore .feature-titling {
  padding-top: 18px;
}

.explore .feature-image {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  overflow: hidden;
}

.explore .feature-image img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

.explore .feature-image .responsive-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.explore .feature-image .image-link {
  overflow: hidden;
}

.explore .feature-image img {
  transition: transform 500ms ease-out;
  transform-origin: center center;
}

.explore .feature-image:hover img {
  transform: scale(1.04);
}

.greenhouse-form {
  margin-bottom: 47px;
}

.greenhouse-form .question-label {
  font-weight: 400;
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  font-size: 13px;
  padding: 19px 0 1px;
}

.greenhouse-form .question.single-field .question-label {
  display: none;
}

.greenhouse-form .question.single-field.single-textarea {
  margin-top: 2px;
}

@media screen and (min-width: 640px) {
  .greenhouse-form .question.multiple-fields label {
    padding-top: 0;
  }
}

.greenhouse-form .question.multiple-fields span.label {
  display: none;
}

.greenhouse-form .question.required .question-label::after,
.greenhouse-form .question.required span.label::after {
  display: inline-block;
  content: '*';
  margin-left: 0.25em;
}

.greenhouse-form .field-container.checkboxes .label {
  padding-top: 24px;
}

.greenhouse-form .field-container.checkboxes .options {
  display: flex;
  flex-wrap: wrap;
}

.greenhouse-form .field-container.checkboxes .option {
  width: 100%;
}

@media screen and (min-width: 640px) {
  .greenhouse-form .field-container.checkboxes .option {
    width: 50%;
    padding-right: 12px;
  }
}

.greenhouse-form .field-container.checkboxes label {
  padding-top: 12px;
}

aside.loader.inline {
  display: inline-block;
  vertical-align: middle;
  pading-left: 15px;
}

aside.loader.inline::after {
  content: '';
  width: 16px;
  height: 16px;
  display: block;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxNiAxNiI+CiAgPHRpdGxlPmxvYWRlci1zbWFsbDwvdGl0bGU+CiAgPHBhdGggZD0iTTgsMWE3LDcsMCwxLDAsNyw3IiBzdHlsZT0iZmlsbDogbm9uZTtzdHJva2U6ICMwMDA7c3Ryb2tlLW1pdGVybGltaXQ6IDEwO3N0cm9rZS13aWR0aDogMnB4Ii8+Cjwvc3ZnPgo=");
  background-size: 16px 16px;
  background-position: center center;
  animation: spin 2s linear infinite;
}

aside.loader.fullscreen {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
}

aside.loader.fullscreen::after {
  width: 50px;
  height: 50px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MCA1MCI+CiAgPHRpdGxlPmxvYWRlcjwvdGl0bGU+CiAgPHBhdGggZD0iTTI1LDNBMjIsMjIsMCwxLDAsNDcsMjUiIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogIzAwMDtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
  background-size: 50px 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.marquee {
  white-space: nowrap;
  opacity: 0;
  transition: 0.5s linear opacity;
  position: relative;
  font-size: 0;
}

.marquee.ready {
  opacity: 1;
}

.marquee .marquee-segment {
  white-space: nowrap;
  display: inline;
  width: auto;
  padding: 0 15px;
}

@media screen and (min-width: 640px) {
  .marquee .marquee-segment {
    padding: 0 45px;
  }
}

section.media {
  padding: 0 14px;
}

@media screen and (min-width: 640px) {
  section.media {
    margin-top: 54px;
    padding: 0 24px;
  }
}

section.media .button-container {
  position: relative;
  display: block;
  height: auto;
}

section.media .button-container .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  position: relative;
  padding: 26.4px 0;
  width: 100%;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  section.media .button-container .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  section.media .button-container .button {
    font-size: 15px;
  }
}

section.media .button-container .button:after {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  right: 26.4px;
  transform-origin: center center;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
  background-position: center;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  transition: transform 250ms ease-out;
}

section.media .button-container .button.active:after {
  transform: rotate(180deg);
}

section.media .button-container .button.active:after {
  transform: rotate(180deg);
}

@media screen and (min-width: 640px) {
  section.media .button-container .button {
    display: none;
  }
}

section.media .button-container a.button {
  cursor: pointer;
}

section.media .media-columns {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -20px;
  margin-right: -20px;
  max-height: 0;
  overflow: hidden;
  margin-bottom: 0;
}

section.media .media-columns > * {
  padding-left: 20px;
  padding-right: 20px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

section.media .media-columns.active {
  max-height: none;
  margin-bottom: 36px;
}

@media screen and (min-width: 640px) {
  section.media .media-columns {
    overflow: visible;
    max-height: none;
  }
}

@media screen and (min-width: 1300px) {
  section.media .media-columns {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    margin-left: -49px;
    margin-right: -49px;
  }
  section.media .media-columns > * {
    padding-left: 49px;
    padding-right: 49px;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  section.media .column.first {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  section.media .column.first {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 10%;
  }
  section.media .column.first .block.medium {
    margin-left: 25%;
  }
  section.media .column.first .block.small {
    margin-left: 50%;
  }
}

@media screen and (min-width: 640px) {
  section.media .column.last {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  section.media .column.last {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.media .block {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  margin-bottom: 36px;
}

section.media .block > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  section.media .block {
    margin-bottom: 12px;
  }
}

@media screen and (min-width: 1024px) {
  section.media .block {
    margin-bottom: 54px;
  }
}

@media screen and (min-width: 1024px) {
  section.media .block.small {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  section.media .block.medium {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  section.media .block.large {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

section.media .block.image figcaption {
  margin-top: 12px;
  margin-bottom: 0;
}

section.media .block.image figcaption p {
  font-size: 18px;
}

section.media .block.image figcaption a {
  color: black;
}

section.media .block.image figcaption a:hover {
  color: #888888;
}

.media-list-wrapper {
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 640px) {
  .media-list-wrapper {
    margin-top: 0;
  }
}

.media-list {
  padding: 0 18px;
  margin-bottom: 9.6px;
  color: black;
  position: relative;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .media-list {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
    margin-bottom: 4px;
  }
}

@media screen and (min-width: 640px) {
  .media-list.inactive a {
    cursor: default;
  }
}

.media-list .media-list-title .title {
  margin-bottom: 0.15em;
  font-weight: 500;
  font-size: 62px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (max-width: 640px) {
  .media-list .media-list-title .title {
    font-size: 42px;
    margin-bottom: 0.3em;
  }
}

@media screen and (min-width: 1024px) {
  .media-list .media-list-title .title {
    font-size: 96px;
  }
}

@media screen and (min-width: 1300px) {
  .media-list .media-list-title .title {
    font-size: 120px;
  }
}

.media-list a {
  display: inline-block;
  transition: color 200ms ease-in-out;
}

.media-list a:hover {
  color: #888888;
}

.media-list sup {
  display: none;
  margin-left: 0.25em;
  white-space: nowrap;
}

@media screen and (min-width: 640px) {
  .media-list sup {
    display: inline;
  }
}

.media-list-image {
  width: 58.33333%;
  margin-left: 0;
  margin-right: 0;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 60vh;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  transition: opacity 100ms ease-in;
}

@media screen and (min-width: 640px) {
  .media-list-image {
    display: block;
  }
}

.media-list-image.sticky {
  position: fixed;
  margin-top: 20vh;
}

.media-list-image.active {
  opacity: 1;
}

.media-list-image .responsive-image {
  padding-bottom: 56.25%;
}

@media screen and (min-width: 640px) {
  .media-list-image .responsive-image {
    padding-bottom: 70%;
  }
}

.media-list-image .image {
  position: relative;
  margin-bottom: 10px;
}

.media-list-image img {
  position: absolute;
  top: 0;
  left: 0;
  transition: 150ms linear opacity;
  opacity: 0;
}

.media-list-image img.loaded {
  opacity: 1;
}

.media-tile {
  margin-bottom: 36px;
  color: black;
  position: relative;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  transition: margin 600ms ease-out;
}

@media screen and (min-width: 640px) {
  .media-tile {
    width: 33.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 50px;
  }
  .media-tile:nth-child(3) .responsive-image, .media-tile:nth-child(4) .responsive-image, .media-tile:nth-child(9) .responsive-image, .media-tile:nth-child(10) .responsive-image {
    padding-bottom: 65.5%;
  }
  .media-tile:nth-child(1) .responsive-image, .media-tile:nth-child(6n + 1) .responsive-image, .media-tile:nth-child(6n) .responsive-image {
    padding-bottom: 117.5%;
  }
}

@media screen and (min-width: 1024px) {
  .media-tile {
    margin-bottom: 80px;
  }
}

@media screen and (min-width: 640px) {
  .media-tile.mobile {
    display: none;
  }
}

@media screen and (min-width: 640px) {
  .media-tile.inactive a {
    cursor: default;
  }
}

.media-tile.no-thumb .responsive-image img {
  display: none;
}

.media-tile.no-thumb .responsive-image {
  background-color: transparent;
}

.media-tile.no-thumb figure .image {
  background-color: black;
}

@media screen and (min-width: 640px) {
  .media-tile:hover .overlay {
    opacity: 0.75;
  }
}

.media-tile a {
  display: block;
  transition: 100ms linear color;
}

.media-tile .responsive-image {
  padding-bottom: 56.25%;
}

@media screen and (min-width: 640px) {
  .media-tile .responsive-image {
    padding-bottom: 77%;
  }
}

.media-tile .image {
  position: relative;
  margin-bottom: 15px;
}

@media screen and (max-width: 640px) {
  .media-tile .image {
    margin: 0 18px 10px 18px;
  }
}

@media screen and (min-width: 1024px) {
  .media-tile .image {
    margin-bottom: 20px;
  }
}

.media-tile .image .thumb.no-mobile {
  display: none;
}

@media screen and (min-width: 640px) {
  .media-tile .image .thumb.no-mobile {
    display: block;
  }
}

.media-tile .image .mobile-thumb {
  display: block;
}

@media screen and (min-width: 640px) {
  .media-tile .image .mobile-thumb {
    display: none;
  }
}

.media-tile img {
  position: absolute;
  top: 0;
  left: 0;
  transition: 150ms linear opacity;
  opacity: 0;
}

.media-tile img.loaded {
  opacity: 1;
}

.media-tile figcaption {
  padding: 0 18px;
  line-height: 18px;
}

@media screen and (max-width: 640px) {
  .media-tile figcaption {
    display: flex;
    flex-direction: row-reverse;
  }
}

@media screen and (min-width: 640px) {
  .media-tile figcaption {
    padding: 0;
  }
}

.media-tile h3 {
  margin-bottom: 3px;
}

@media screen and (max-width: 640px) {
  .media-tile h3 {
    flex: 1;
    margin-right: 20px;
  }
}

@media screen and (min-width: 1024px) {
  .media-tile h3 {
    margin-bottom: 0;
  }
}

.media-tile .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 24px;
  opacity: 0;
  transition: 100ms linear opacity;
}

.media-tile .overlay::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 54px;
  background-image: linear-gradient(transparent 0%, black 90%);
}

.media-tile .overlay h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  font-size: 12px;
  letter-spacing: 0.6px;
  color: rgba(255, 255, 255, 0.54);
}

@media screen and (min-width: 640px) {
  .media-tile .overlay h4 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .media-tile .overlay h4 {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .media-tile .overlay h4 {
    font-size: 12px;
  }
}

@media screen and (min-width: 1300px) {
  .media-tile .overlay h4 {
    font-size: 13px;
  }
}

.media-tile .overlay .datum {
  font-size: 18px;
  line-height: 1.1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.media-tile .overlay-data-group {
  margin-bottom: 12px;
}

.media-tile .overlay-data-group.partner-logos {
  margin-bottom: 24px;
}

.media-tile .overlay-data-group.partner-logos .apple-original,
.media-tile .overlay-data-group.partner-logos .hulu,
.media-tile .overlay-data-group.partner-logos .prime-video,
.media-tile .overlay-data-group.partner-logos .netflix,
.media-tile .overlay-data-group.partner-logos .hbo-max,
.media-tile .overlay-data-group.partner-logos .apple-tv,
.media-tile .overlay-data-group.partner-logos .showtime,
.media-tile .overlay-data-group.partner-logos .peacock {
  min-height: 17px;
  background-size: contain;
  background-repeat: no-repeat;
}

.media-tile .overlay-data-group.partner-logos .apple-original {
  background-image: url("/assets/images/AAOF-white.svg");
  margin: 10px 0 10px;
}

.media-tile .overlay-data-group.partner-logos .hulu {
  background-image: url("/assets/images/hulu_logo-white.svg");
  margin: 10px 0 13px;
}

.media-tile .overlay-data-group.partner-logos .prime-video {
  background-image: url("/assets/images/prime_video_logo-white.svg");
  margin: 10px 0 13px;
  min-height: 31px;
}

.media-tile .overlay-data-group.partner-logos .netflix {
  background-image: url("/assets/images/netflix_logo-white.svg");
  margin: 10px 0 13px;
}

.media-tile .overlay-data-group.partner-logos .hbo-max {
  background-image: url("/assets/images/max_logo-white.svg");
  margin: 10px 0 13px;
}

.media-tile .overlay-data-group.partner-logos .apple-tv {
  background-image: url("/assets/images/apple_tv-white.svg");
  min-height: 18px;
}

.media-tile .overlay-data-group.partner-logos .showtime {
  background-image: url("/assets/images/showtime-white.svg");
  min-height: 20px;
}

.media-tile .overlay-data-group.partner-logos .peacock {
  background-image: url("/assets/images/peacock-white.svg");
  min-height: 20px;
}

aside.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 0 24px 54px 24px;
  z-index: 4;
  overflow: auto;
}

aside.modal .modal-wrap {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  justify-content: center;
}

aside.modal .modal-wrap > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 1024px) {
  aside.modal .modal-content {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

aside.modal .modal-controls {
  padding-top: 24px;
  padding-bottom: 54px;
  text-align: right;
}

aside.modal button.close {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1;
  margin: 0 0 0 auto;
  width: 35px;
  height: 35px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB2aWV3Qm94PSIwIDAgNTAgNTAiPgogIDx0aXRsZT5jbG9zZTwvdGl0bGU+CiAgPGxpbmUgeDE9IjIiIHkxPSIyIiB4Mj0iNDgiIHkyPSI0OCIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjZmZmO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgogIDxsaW5lIHgxPSIyIiB5MT0iNDgiIHgyPSI0OCIgeTI9IjIiIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogI2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
}

@media screen and (min-width: 1024px) {
  aside.modal button.close {
    width: 40px;
    height: 40px;
  }
}

aside.search-wrapper {
  position: fixed;
  max-height: 0;
  height: 100vh;
  min-width: 100vw;
  top: 0;
  color: white;
  background-color: black;
  overflow: hidden;
  z-index: 5;
}

@media screen and (min-width: 640px) {
  aside.search-wrapper {
    position: relative;
    height: 60vh;
  }
}

aside.search-wrapper.active {
  max-height: 100vh;
  height: 100vh;
}

@media screen and (min-width: 769px) {
  aside.search-wrapper.active {
    height: 60vh;
  }
}

aside.search-wrapper.active button.close {
  top: 2px;
  opacity: 1;
}

@media screen and (min-width: 640px) {
  aside.search-wrapper.active button.close {
    top: 60px;
  }
}

@media screen and (min-width: 640px) {
  aside.search-wrapper {
    transition: max-height 500ms ease-in-out;
  }
}

aside.search-wrapper section {
  position: relative;
  height: 100%;
  padding-top: 18px;
  padding-bottom: 24px;
  padding-left: 18px;
  padding-right: 18px;
}

@media screen and (min-width: 640px) {
  aside.search-wrapper section {
    padding-top: 9px;
    padding-bottom: 9px;
    padding-left: 42px;
    padding-right: 42px;
  }
}

aside.search-wrapper section .grid {
  align-content: center;
}

aside.search-wrapper section label {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

aside.search-wrapper section button.close {
  top: 2px;
}

@media screen and (min-width: 640px) {
  aside.search-wrapper section button.close {
    top: 60px;
  }
}

.search-wrapper .search-bar {
  background-color: black;
  padding: 42px 13px 45px 45px;
  margin-bottom: 24px;
}

.search-wrapper .grid {
  position: relative;
  height: 100%;
  align-items: center;
}

.search-wrapper .search-form {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
}

@media screen and (min-width: 1024px) {
  .search-wrapper .search-form {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .search-wrapper .search-form {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (min-width: 640px) {
  .search-wrapper .search-form {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search-wrapper form {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
  align-items: center;
}

.search-wrapper form > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.search-wrapper form input[type="text"],
.search-wrapper form input[type="email"] {
  padding-right: 50px;
}

@media screen and (min-width: 1024px) {
  .search-wrapper form input[type="text"],
  .search-wrapper form input[type="email"] {
    padding-right: 0;
  }
}

.search-wrapper label {
  margin-bottom: 0;
}

.search-wrapper label.search {
  width: 75%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .search-wrapper label.search {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .search-wrapper label.search {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
  }
}

.search-wrapper label.search input {
  padding-bottom: 8px;
  display: block;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  line-height: normal;
  text-align: left;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  border: none;
  border-bottom: 2px solid white;
  font-size: 10px;
}

.search-wrapper label.submit {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  width: 54px;
  height: 45px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAxNSAxNiIgc3R5bGU9ImZpbGw6IHdoaXRlOyBlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDE1IDE2OyB0cmFuc2Zvcm06cm90YXRlKC05MGRlZykiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8cG9seWdvbiBwb2ludHM9IjEzLjgsOC4yIDguMywxMy4yIDguMywwIDYuNywwIDYuNywxMy4yIDEuMiw4LjIgMCw5LjMgNy41LDE2IDE1LDkuMyAiLz4KPC9zdmc+Cg==");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  background-color: black;
}

@media screen and (min-width: 640px) {
  .search-wrapper label.submit {
    width: 38px;
    height: 27px;
  }
}

@media screen and (min-width: 1024px) {
  .search-wrapper label.submit {
    width: 46px;
    height: 36px;
  }
}

@media screen and (min-width: 1300px) {
  .search-wrapper label.submit {
    top: 51%;
    width: 54px;
    height: 45px;
  }
}

.search-wrapper label.submit input {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  font-size: 10px;
}

.search-wrapper button.close {
  text-indent: -9999px;
  background-repeat: no-repeat;
  border: 0;
  background-color: transparent;
  background-position: center center;
  outline: none;
  background-size: 100% 100%;
  padding: 0;
  margin: 0;
  cursor: pointer;
  transition: 150ms linear opacity;
  display: block;
  line-height: 1;
  position: absolute;
  top: 60px;
  right: 9.6px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+CiAgPHRpdGxlPmNsb3NlLW1lZGl1bTwvdGl0bGU+CiAgPGxpbmUgeDE9IjEiIHkxPSIxIiB4Mj0iMjMiIHkyPSIyMyIgc3R5bGU9ImZpbGw6IG5vbmU7c3Ryb2tlOiAjZmZmO3N0cm9rZS1taXRlcmxpbWl0OiAxMDtzdHJva2Utd2lkdGg6IDJweCIvPgogIDxsaW5lIHgxPSIxIiB5MT0iMjMiIHgyPSIyMyIgeTI9IjEiIHN0eWxlPSJmaWxsOiBub25lO3N0cm9rZTogI2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDogMTA7c3Ryb2tlLXdpZHRoOiAycHgiLz4KPC9zdmc+Cg==");
  width: 34px;
  height: 34px;
  opacity: 0;
  transition: opacity 100ms ease-out 350ms;
}

@media screen and (min-width: 640px) {
  .search-wrapper button.close {
    top: 6px;
  }
}

.sharing {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0 30px;
  margin: 0 24px;
  text-align: center;
}

.sharing .grid {
  justify-content: center;
}

@media screen and (min-width: 640px) {
  .sharing.footer {
    display: inline-block;
    padding: 0;
    border: none;
  }
}

.sharing h4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  flex-basis: 100%;
  position: relative;
  top: 0px;
  margin-right: 36px;
  margin-bottom: 6px;
  font-size: 13px;
}

@media screen and (min-width: 640px) {
  .sharing h4 {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .sharing h4 {
    font-size: 15px;
  }
}

@media screen and (min-width: 1024px) {
  .sharing h4 {
    flex-basis: auto;
    margin-right: 18px;
    margin-bottom: 0;
    font-size: 15px;
    margin-right: 36px;
  }
}

.sharing .sharing-options {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  text-align: left;
  cursor: default;
}

.sharing .sharing-options li {
  display: inline-block;
}

@media screen and (min-width: 1024px) {
  .sharing .sharing-options {
    flex-wrap: nowrap;
    justify-content: center;
  }
}

.sharing .sharing-options .sharing-option {
  margin-right: 14.4px;
}

.sharing .sharing-options .sharing-option.email {
  margin-left: 8px;
}

.sharing .sharing-options .sharing-option:last-of-type {
  margin-right: 0;
}

.sharing .sharing-options a {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.sharing .sharing-options a path,
.sharing .sharing-options a rect,
.sharing .sharing-options a circle,
.sharing .sharing-options a polygon {
  transition: 100ms linear fill;
  fill: black;
}

.sharing .sharing-options a:hover path,
.sharing .sharing-options a:hover rect,
.sharing .sharing-options a:hover circle,
.sharing .sharing-options a:hover polygon {
  fill: #888888;
}

main.single-film .sharing,
main.single-television .sharing {
  display: block;
  width: 100%;
  justify-content: flex-start;
  margin: 0;
  border-top: 1px solid black;
  border-bottom: none;
}

@media screen and (min-width: 640px) {
  main.single-film .sharing,
  main.single-television .sharing {
    border-top: none;
    width: 51%;
    margin-left: 0;
    margin-right: 0;
  }
}

main.single-film .sharing-options,
main.single-television .sharing-options {
  justify-content: center;
}

@media screen and (min-width: 640px) {
  main.single-film .sharing-options,
  main.single-television .sharing-options {
    justify-content: flex-start;
  }
}

main.single-film .share-spacer,
main.single-television .share-spacer {
  display: none;
}

@media screen and (min-width: 640px) {
  main.single-film .share-spacer,
  main.single-television .share-spacer {
    display: inherit;
    width: 34%;
    margin-left: 0;
    margin-right: 0;
    padding-left: 12px;
  }
}

@media screen and (max-width: 640px) {
  main.single-film .share-wrapper,
  main.single-television .share-wrapper {
    padding: 0;
  }
}

@media screen and (min-width: 640px) {
  main.single-film .share-wrapper,
  main.single-television .share-wrapper {
    padding-left: 12px;
  }
}

main.single-film h4,
main.single-television h4 {
  flex-basis: auto;
  margin-right: 18px;
  margin-bottom: 0;
  margin-right: 36px;
}

nav.social-links ul {
  line-height: 0;
  cursor: default;
}

nav.social-links ul li {
  display: inline-block;
}

@media screen and (min-width: 640px) {
  nav.social-links li {
    margin-right: 14.4px;
  }
  nav.social-links li:last-of-type {
    margin-right: 0;
  }
}

nav.social-links a {
  display: block;
  width: 1.5rem;
  height: 1.5rem;
}

.stack-item {
  width: 91.66667%;
  margin-left: 0;
  margin-right: 0;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 25px;
  margin-top: 33px;
  border-bottom: 1px solid black;
}

@media screen and (max-width: 640px) {
  .stack-item {
    width: auto;
    margin-left: 24px;
    margin-right: 24px;
  }
}

@media screen and (min-width: 640px) {
  .stack-item {
    width: 85.7%;
    margin-left: 0;
    margin-right: 0;
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0;
    margin: 0 auto 90px;
    border-bottom: none;
  }
  .stack-item:nth-child(odd) .copy-wrapper {
    order: 3;
    padding: 0 0 0 56px;
  }
  .stack-item:nth-child(even) .copy-wrapper {
    order: 2;
    padding: 0 56px 0 0;
  }
  .stack-item:nth-child(odd) figure {
    order: 2;
  }
  .stack-item:nth-child(even) figure {
    order: 3;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item {
    margin: 0 auto 120px;
  }
}

.stack-item.film, .stack-item.show {
  cursor: pointer;
}

.stack-item .eyebrow.mobile {
  display: block;
  margin-top: 0;
}

@media screen and (min-width: 640px) {
  .stack-item .eyebrow.mobile {
    display: none;
    order: 1;
  }
}

.stack-item:first-of-type {
  margin-top: 36px;
}

.stack-item:last-of-type {
  border: none;
  margin-bottom: 0;
}

.stack-item.full-width {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  max-height: 80vh;
  height: 80vh;
  overflow: hidden;
  border-bottom: none;
  margin-bottom: 20px;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width {
    height: auto;
    margin-bottom: 90px;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item.full-width {
    margin-bottom: 120px;
  }
}

@media screen and (max-height: 800px) {
  .stack-item.full-width {
    max-height: 85vh;
  }
}

.stack-item.full-width figure {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  height: 100%;
  max-height: 80vh;
}

@media screen and (max-height: 800px) {
  .stack-item.full-width figure {
    max-height: 85vh;
  }
}

.stack-item.full-width figure .responsive-image,
.stack-item.full-width figure img {
  min-height: 80vh;
  width: auto;
  min-width: 100%;
}

@media screen and (max-height: 800px) {
  .stack-item.full-width figure .responsive-image,
  .stack-item.full-width figure img {
    min-height: 85vh;
  }
}

.stack-item.full-width figure .mobile .responsive-image {
  min-height: none;
  max-height: 100vh;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width figure .mobile .responsive-image {
    max-height: none;
  }
}

.stack-item.full-width figure img {
  left: 50%;
  transform: translate3d(-50%, 0, 0);
}

.stack-item.full-width figure .image-link img,
.stack-item.full-width figure .image-link .stack-image img {
  transform-origin: left center;
}

.stack-item.full-width figure .image-link:hover img,
.stack-item.full-width figure .image-link:hover .stack-image img {
  transform: scale(1.04) translate3d(-50%, 0, 0);
}

.stack-item.full-width figure .stack-image.no-mobile {
  display: none;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width figure .stack-image.no-mobile {
    display: block;
  }
}

.stack-item.full-width figure .stack-image.mobile {
  display: block;
}

.stack-item.full-width figure .stack-image.mobile img {
  width: auto !important;
  height: 100%;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width figure .stack-image.mobile {
    display: none;
  }
}

.stack-item.full-width figcaption {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 0px 17px;
  color: white;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width figcaption {
    padding: 0 42px;
  }
}

.stack-item.full-width figcaption h2 {
  color: white;
  margin-bottom: 22px;
}

@media screen and (min-width: 640px) {
  .stack-item.full-width figcaption h2 {
    margin-bottom: 51px;
  }
}

.stack-item.full-width figcaption .eyebrow {
  display: block;
  margin-bottom: 2px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  .stack-item.archive-preview:nth-child(odd) .copy-wrapper {
    order: 2;
    padding: 0 0 0 56px;
  }
  .stack-item.archive-preview:nth-child(even) .copy-wrapper {
    order: 1;
    padding: 0 56px 0 0;
  }
  .stack-item.archive-preview:nth-child(odd) .story-image {
    order: 2;
  }
  .stack-item.archive-preview:nth-child(even) .story-image {
    order: 3;
  }
}

.stack-item .image-link {
  overflow: hidden;
}

.stack-item .image-link img,
.stack-item .image-link .stack-image img {
  transition: transform 500ms ease-out;
  transform-origin: center center;
}

.stack-item .image-link:hover img,
.stack-item .image-link:hover .stack-image img {
  transform: scale(1.04);
}

.stack-item .stack-image {
  padding-bottom: 56.25%;
  background-color: black;
  position: relative;
  overflow: hidden;
}

.stack-item .stack-image img {
  position: absolute;
  top: 0;
  left: 0;
  max-width: none;
}

.stack-item .stack-image.note, .stack-item .stack-image.show, .stack-item .stack-image.product {
  padding-bottom: 0;
}

.stack-item .stack-image img {
  transition: 150ms linear opacity;
  opacity: 0;
}

.stack-item .stack-image img.loaded {
  opacity: 1;
}

.stack-item figure {
  position: relative;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (min-width: 640px) {
  .stack-item figure {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

.stack-item .copy-wrapper {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  position: relative;
}

@media screen and (min-width: 640px) {
  .stack-item .copy-wrapper {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

.stack-item .copy-wrapper .link {
  text-transform: uppercase;
  font-size: 21px;
  font-weight: 500;
  overflow: hidden;
  position: relative;
  margin-top: 24px;
  margin-bottom: 24px;
}

@media screen and (min-width: 640px) {
  .stack-item .copy-wrapper .link {
    line-height: 1;
  }
}

@media screen and (min-width: 1024px) {
  .stack-item .copy-wrapper .link {
    line-height: 1;
    letter-spacing: 0.3px;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item .copy-wrapper .link {
    line-height: 1;
  }
}

.stack-item .copy-wrapper .link span {
  display: inline-block;
  transition: transform 200ms ease-in-out 25ms;
  transform: translate3d(0, -3px, 0);
}

@media screen and (max-width: 640px) {
  .stack-item .copy-wrapper .link span {
    transform: none;
  }
}

.stack-item .copy-wrapper .link:before {
  content: '';
  display: inline-block;
  position: relative;
  width: 41px;
  height: 15px;
  margin-right: 33px;
  background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1NSAyMCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNTUgMjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHBvbHlnb24gc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkOyIgcG9pbnRzPSI0My4zLDAgNTUsMTAgNDMuMywyMCA0Mi40LDE5LjEgNTIuMiwxMC43IDAsMTAuNyAwLDkuMyA1Mi4yLDkuMyAKCTQyLjQsMC45IDQzLjMsMCAiLz4KPC9zdmc+Cg==");
  background-position: center center;
  background-repeat: no-repeat;
  transition: transform 200ms ease-in-out;
}

@media screen and (min-width: 640px) {
  .stack-item .copy-wrapper .link:before {
    width: 34px;
    height: 12px;
  }
}

@media screen and (min-width: 1024px) {
  .stack-item .copy-wrapper .link:before {
    width: 45px;
    height: 17px;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item .copy-wrapper .link:before {
    width: 55px;
    height: 20px;
  }
}

.stack-item .copy-wrapper .link:hover span {
  transform: translate3d(-25px, -3px, 0);
}

@media screen and (max-width: 640px) {
  .stack-item .copy-wrapper .link:hover span {
    transform: translate3d(-25px, 0, 0);
  }
}

.stack-item .copy-wrapper .link:hover:before {
  transform: translate3d(-50%, 0, 0);
}

@media screen and (min-width: 640px) {
  .stack-item .copy-wrapper .link {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
  }
}

.stack-item .copy-wrapper a > h2,
.stack-item .copy-wrapper h2 > a {
  transition: opacity 200ms ease-in-out;
}

.stack-item .copy-wrapper a > h2:hover,
.stack-item .copy-wrapper h2 > a:hover {
  opacity: 0.6;
}

.stack-item .eyebrow {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: none;
  margin-top: 14.4px;
  margin-bottom: 9.6px;
  color: #888888;
}

@media screen and (min-width: 640px) {
  .stack-item .eyebrow {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item .eyebrow {
    font-size: 15px;
  }
}

@media screen and (min-width: 640px) {
  .stack-item .eyebrow {
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.stack-item h2 {
  font-weight: 500;
  font-size: 48px;
  line-height: 0.92;
  letter-spacing: -0.04em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  margin-top: 9.6px;
}

@media screen and (min-width: 1024px) {
  .stack-item h2 {
    font-size: 38px;
    line-height: 1;
    letter-spacing: -0.03em;
    font-weight: 500;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item h2 {
    font-size: 74px;
    line-height: 0.92;
    letter-spacing: -0.04em;
  }
}

@media screen and (max-width: 640px) {
  .stack-item h2 {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 360px) {
  .stack-item h2 {
    font-size: 6vw;
    line-height: 8vw;
  }
}

@media screen and (min-width: 640px) {
  .stack-item h2 {
    margin-top: 0;
    margin-bottom: 72px;
  }
}

@media screen and (min-width: 1300px) {
  .stack-item h2 {
    margin-top: 0;
    margin-bottom: 96px;
  }
}

.stack-item > .copy-wrapper:first-child {
  display: none;
}

@media screen and (min-width: 640px) {
  .stack-item > .copy-wrapper:first-child {
    display: block;
  }
}

.story-content.blog {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -6px;
  margin-right: -6px;
}

.story-content.blog > * {
  padding-left: 6px;
  padding-right: 6px;
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

@media screen and (max-width: 640px) {
  .story-content.blog {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 640px) {
  .story-content.blog .block {
    width: 54%;
    margin-left: 0;
    margin-right: 0;
    margin-left: auto;
    margin-right: auto;
  }
}

.story-content .chapter {
  margin-top: 96px;
}

.story-content .section {
  margin-bottom: 36px;
}

.story-content .section:last-child {
  margin-bottom: 0;
}

.story-content .split .column:not(:first-child) {
  margin-top: 36px;
}

@media screen and (min-width: 640px) {
  .story-content .split .column:not(:first-child) {
    margin-top: 0;
  }
}

.story-content .split.column-align-center .grid {
  align-items: center;
}

.story-content .split.column-align-bottom .grid {
  align-items: flex-end;
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-equal .first,
  .story-content .split.bias-equal .last {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-equal .block.left {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-equal .block.right {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-equal .block.center {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 20%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-left .first {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .first .block.center {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .first .block.left {
    width: 83.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .first .block.right {
    width: 83.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-left .last {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .last .block.center {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 25%;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .last .block.left {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 1024px) {
  .story-content .split.bias-left .last .block.right {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 25%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .first {
    width: 40%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .first .block.center {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 25%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .first .block.left {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .first .block.right {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 25%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .last {
    width: 60%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .last .block.center {
    width: 66.66667%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .last .block.left {
    width: 83.33333%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .story-content .split.bias-right .last .block.right {
    width: 83.33333%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 16.66667%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .wide .block.left {
    width: 70%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .story-content .wide .block.right {
    width: 70%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 30%;
  }
}

@media screen and (min-width: 640px) {
  .story-content .wide .block.center {
    width: 80%;
    margin-left: 0;
    margin-right: 0;
    margin-left: 10%;
  }
}

.block {
  margin-bottom: 36px;
}

.block:last-child {
  margin-bottom: 0;
}

.block.sideBySide figure + figure, .block.images figure + figure {
  margin-top: 24px;
}

.block.sideBySide figcaption, .block.images figcaption {
  margin-top: 6px;
}

.block.sideBySide figcaption span, .block.images figcaption span {
  font-family: "akzidenz-grotesk-extended", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.8px;
  font-size: 12px;
  line-height: 1.5;
  display: block;
}

@media screen and (min-width: 1024px) {
  .block.sideBySide figcaption span, .block.images figcaption span {
    display: inline-block;
  }
}

.block.sideBySide figcaption span.caption, .block.images figcaption span.caption {
  color: #444444;
  display: inline-block;
}

.block.sideBySide figcaption span.attribution, .block.images figcaption span.attribution {
  color: #888888;
}

.block.sideBySide .responsive-image, .block.images .responsive-image {
  margin-top: 12px;
}

@media screen and (min-width: 640px) {
  .block.sideBySide .fullwidth, .block.images .fullwidth {
    width: 130%;
    margin-left: 0;
    margin-right: 0;
    margin-left: -15%;
  }
}

.block.sideBySide .fullwidth.center, .block.images .fullwidth.center {
  margin-left: -15%;
}

.block.sideBySide .fullwidth .attribution, .block.images .fullwidth .attribution {
  margin-left: 13%;
}

@media screen and (min-width: 640px) {
  .block.sideBySide .fullwidth .attribution, .block.images .fullwidth .attribution {
    margin-left: 0%;
  }
}

@media screen and (min-width: 640px) {
  .block.sideBySide .twenty-five, .block.images .twenty-five {
    width: 25%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .block.sideBySide .fifty, .block.images .fifty {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (min-width: 640px) {
  .block.sideBySide .seventy-five, .block.images .seventy-five {
    width: 75%;
    margin-left: 0;
    margin-right: 0;
  }
}

.block.sideBySide .center, .block.images .center {
  margin: 0 auto;
}

.block.sideBySide .right, .block.images .right {
  margin-left: auto;
}

@media screen and (min-width: 640px) {
  .block.sideBySide {
    padding: 0 2px;
  }
}

@media screen and (min-width: 640px) {
  .block.sideBySide .side-by-side {
    width: 130%;
    margin-left: 0;
    margin-right: 0;
    margin-left: -15%;
  }
}

.block.sideBySide .single-side {
  width: 50%;
  margin-left: 0;
  margin-right: 0;
}

.block.spacer {
  margin: 0;
  display: none;
}

@media screen and (min-width: 640px) {
  .block.spacer {
    display: block;
  }
  .block.spacer .spacer-shim.normal {
    height: 54px;
  }
  .block.spacer .spacer-shim.double {
    height: 108px;
  }
  .block.spacer .spacer-shim.tiny {
    height: 24px;
  }
}

.ways-to-watch {
  position: relative;
}

.ways-to-watch .button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  display: block;
  padding: 22px 22px 21px;
  border: 1px solid black;
  line-height: normal;
}

@media screen and (min-width: 640px) {
  .ways-to-watch .button {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .ways-to-watch .button {
    font-size: 15px;
  }
}

.ways-to-watch a.button {
  cursor: pointer;
  transition: 100ms linear color, 100ms linear background-color;
}

.ways-to-watch .watch-now-links {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 400;
  font-feature-settings: 'cpsp';
  font-family: "NB International Web", "Helvetica Neue", Helvetica, Arial, sans-serif;
  letter-spacing: 0.005em;
  position: absolute;
  overflow: hidden;
  width: 100%;
  max-width: 380px;
  max-height: 0;
  list-style-type: none;
  padding: 0 26.4px;
  margin: 0;
  background-color: white;
  border-left: 1px solid black;
  border-right: 1px solid black;
  transition: max-height 150ms ease-out;
  z-index: 1;
}

@media screen and (min-width: 640px) {
  .ways-to-watch .watch-now-links {
    font-size: 13px;
  }
}

@media screen and (min-width: 1300px) {
  .ways-to-watch .watch-now-links {
    font-size: 15px;
  }
}

@media screen and (min-width: 769px) {
  .ways-to-watch .watch-now-links {
    width: 85%;
  }
}

@media screen and (min-width: 1300px) {
  .ways-to-watch .watch-now-links {
    width: 100%;
  }
}

.ways-to-watch .watch-now-links.active {
  overflow: visible;
  max-height: 500px;
  padding: 0 26.4px 26.4px 26.4px;
  border-bottom: 1px solid black;
}

@media screen and (min-width: 1024px) {
  .ways-to-watch .watch-now-links.active {
    max-height: 500px;
  }
}

.ways-to-watch .watch-now-links.active li {
  opacity: 1;
}

.ways-to-watch .watch-now-links li {
  opacity: 0;
  transition: opacity 50ms ease-out 75ms;
}

.ways-to-watch .watch-now-links li:first-of-type {
  padding-top: 26.4px;
}

.ways-to-watch .watch-now-links a {
  color: black;
}

.ways-to-watch .watch-now-links a:hover {
  border-bottom-color: black;
}

@media screen and (min-width: 640px) {
  .ways-to-watch .watch-now-links a {
    border-bottom: 1px solid transparent;
  }
}
