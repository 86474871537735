.explore {
  padding: 0;
  margin: 0;

  @include screen-medium {
    margin: 0 38px;
    padding: 32px 0 48px;
    border-top: 0.5px solid $color-black; }

  .button-container {
    position: relative;
    display: block;
    height: auto;
    padding: 0;

    .button {
      @include eyebrow;
      @include dropdown-button;
      display: block;
      position: relative;
      padding: space(2.2) 0;
      width: 100%;
      line-height: normal;
      border-top: 1px solid $color-black;

      &.active {
        &:after {
          transform: rotate(180deg); } }

      @include screen-medium {
        display: none; } }

    a.button {
      width: calc(100% - 30px);
      margin: 0 17px;
      cursor: pointer; } }

  .explore-wrapper {
    max-height: 0;
    overflow: hidden;
    margin-bottom: 0;

    &.active {
      max-height: none;
      margin-bottom: space(3); }

    @include screen-medium {
      overflow: visible;
      max-height: none; } }

  h6 {
    @include eyebrow;
    padding: 0 0 space(2);
    margin-bottom: 33px;
    border-bottom: 1px solid $color-black; }

  .secondary-features {
    padding: 0;

    @include screen-medium {
      @include columns(8/12);
      padding: 0 40px 0 0; }

    &.full {
      @include columns(1);
      padding: 0;

      .related-title {
        display: none;
        padding: space(2) 0 20px;
        border-bottom: none;

        @include screen-medium {
          display: block; } }

      .feature-tile {
        @include screen-medium {
          @include columns(1/2);
          padding: 0 42px 0 0;
          margin-bottom: space(2);

          &:nth-child(3) {
            padding: 0; }

          &:last-of-type {
            display: none;
            padding: 0; } }

        @include screen-large {
          @include columns(1/3);

          &:nth-child(3) {
            padding: 0 42px 0 0; }

          &:last-of-type {
            display: block; } } }

      .related-eyebow {
        @include eyebrow($mono: true);
        border-bottom: 0;
        padding: 0;
        margin-bottom: 3px;
        color: $color-grey; } } }

  .primary-features {
    padding: space(2) 0 0;
    margin-top: space(2);
    border-top: 1px solid $color-black;

    @include screen-medium {
      @include columns(4/12);
      padding: 0;
      margin-top: 0;
      border-top: none; } }

  .feature-tile {
    margin-bottom: space(4.5);
    padding: 0;

    @include screen-medium {
      margin-bottom: space(1); }

    @include screen-large {
      margin-bottom: 0; }

    &.secondary:first-of-type {
      @include screen-large {
        padding: 0 42px 0 0; } }

    &.secondary:last-of-type {
      margin-bottom: 0;

      @include screen-medium {
        display: none; }

      @include screen-large {
        display: block; } }

    &.ready {
      .responsive-image {
        background-color: $color-black; } }

    // Large Tile
    &.primary {
      margin-bottom: space(1);

      h4 {
        margin-bottom: space(1); }

      h3,
      h3 p {
        @include headline-3;
        @include inline-markdown;

        br {
          display: none;

          @include screen-large {
            display: block; } } }

      .feature-titling {
        @include antialiased-type; }

      .feature-image {
        min-height: space(24); } }

    // Small Tiles
    &.secondary {
      @include screen-medium {
        @include columns(1); }

      @include screen-large {
        @include columns(1/2); }

      h3,
      h3 p {
        @include headline-3;
        @include inline-markdown; }

      .feature-titling {
        @include antialiased-type;
        margin: 0 16px;

        @include screen-medium {
          margin: 0; } }

      .feature-image {
        width: 100%;
        min-height: space(24); } } }

  img {
    backface-visibility: hidden; }

  .tile-content {
    display: block;
    position: relative; }

  .feature-titling {
    padding-top: space(1.5); }

  .feature-image {
    @include filling-image-container;
    flex-grow: 1;
    overflow: hidden;

    .responsive-image {
      @include fill; }

    .image-link {
      overflow: hidden; }

    img {
      transition: transform 500ms ease-out;
      transform-origin: center center; }

    &:hover {
      img {
        transform: scale(1.04); } } } }
