@mixin eyebrow($nav: false, $overlay: false, $attribution: false, $mono: false) {
  @include antialiased-type;
  text-transform: uppercase;
  font-size: $type-size-overlay-eyebrow;
  font-weight: $type-weight-normal;
  font-feature-settings: 'cpsp';
  font-family: $type-family-sans;
  letter-spacing: $type-tracking-h6;

  @include screen-medium {
    font-size: $type-size-overlay-eyebrow; }

  @include screen-wide {
    font-size: $type-size-h6; }

  @if $nav {
    text-transform: none;
    font-size: $type-size-nav;
    letter-spacing: 0; }

  @if $overlay {
    font-size: 12px;
    letter-spacing: 0.6px;
    color: rgba(255, 255, 255, 0.54);

    @include screen-large {
      font-size: 12px; }

    @include screen-wide {
      font-size: $type-size-overlay-eyebrow; } }

  @if $attribution {
    display: none;
    font-size: $type-size-attribution;

    @include screen-medium {
      display: block;
      font-size: $type-size-attribution; } }

  @if $mono {
    font-family: $type-family-mono;
    font-weight: $type-weight-normal;
    letter-spacing: $type-tracking-h6-mono; } }

@mixin overlay-body {
  font-size: 18px;
  line-height: 1.1; }


// Typography helpers

@mixin headline-1($mobile-friendly: false) {
  font-weight: $type-weight-medium;
  font-size: $type-size-h3;
  line-height: $type-leading-h3;
  letter-spacing: $type-tracking-h3;

  @include screen-large {
    font-size: $type-size-h2;
    line-height: $type-leading-h2;
    letter-spacing: $type-tracking-h2; }

  @include screen-wide {
    font-size: $type-size-h1;
    line-height: $type-leading-h1;
    letter-spacing: $type-tracking-h1; } }

// default headline
@mixin headline($mobile-friendly: false, $light: false) {
  font-weight: $type-weight-medium;
  font-size: 48px;
  line-height: $type-leading-h2;
  letter-spacing: $type-tracking-h2;

  @include screen-large {
    font-size: $type-size-h3;
    line-height: $type-leading-h3;
    letter-spacing: $type-tracking-h3;
    font-weight: $type-weight-medium; }

  @include screen-wide {
    font-size: $type-size-h1;
    line-height: $type-leading-h1;
    letter-spacing: $type-tracking-h1; }

  @if $light {
    font-weight: $type-weight-normal;

    @include screen-large {
      font-weight: $type-weight-normal; }

    @include screen-wide {
      font-weight: $type-weight-normal; } } }

@mixin headline-3($mobile-friendly: false, $big-type: false, $mono: false) {
  font-weight: $type-weight-medium;
  font-size: $type-size-h5;
  line-height: $type-leading-h5;
  letter-spacing: $type-tracking-h5;

  @include screen-large {
    font-size: $type-size-h4;
    letter-spacing: $type-tracking-h4;
    line-height: $type-leading-h4; }

  @include screen-wide {
    font-size: $type-size-h3;
    letter-spacing: $type-tracking-h3;
    line-height: $type-leading-h3; }

  @if $big-type {
    line-height: 1.15;

    @include screen-medium {
      line-height: 1.15; }

    @include screen-large {
      line-height: 1.15; }

    @include screen-wide {
      line-height: 1.15;
      font-size: 32px; } }

  @if $mono {
    font-family: $type-family-mono;
    font-weight: $type-weight-normal;
    letter-spacing: $type-tracking-h3-mono;

    @include screen-medium {
      letter-spacing: $type-tracking-h5-mono; }

    @include screen-large {
      letter-spacing: $type-tracking-h4-mono; }

    @include screen-wide {
      letter-spacing: $type-tracking-h3-mono; } } }

@mixin sup {
  position: relative;
  vertical-align: super;
  font-family: $type-family-mono;
  font-weight: $type-weight-normal;
  font-size: $type-size-h6;
  letter-spacing: $type-tracking-h6-mono;

  @include screen-large {
    // font-size: $type-size-note-details
    margin-left: space(-0.6);
    top: -10px; }

  @include screen-wide {
    // font-size: $type-size-h6
    margin-left: space(-1);
    top: -14px; } }

@mixin inline-markdown {
  em {
    font-style: italic; }

  strong {
    font-weight: $type-weight-medium; } }

@mixin large-text {
  font-size: $type-size-medium;
  line-height: $type-leading-medium;
  font-weight: $type-weight-normal;

  @include screen-large {
    font-weight: $type-weight-normal;
    font-size: $type-size-large;
    line-height: $type-leading-large; } }

@mixin antialiased-type {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@mixin fine-underlines {
  a {
    text-decoration: none;
    background: linear-gradient(to bottom, transparent 86%, $color-grey 86%, $color-grey 90.5%, transparent 91.3%);

    @include hi-dpi {
      background: linear-gradient(to bottom, transparent 86%, $color-grey 86%, $color-grey 91%, transparent 91%); }

    &:hover {
      background-image: linear-gradient(to bottom, transparent 86%, $color-black 86%, $color-black 90.5%, transparent 91.3%);

      @include hi-dpi {
        background-image: linear-gradient(to bottom, transparent 86%, $color-black 86%, $color-black 91%, transparent 91%); } } } }

@mixin show-more-wrapper {
  display: block;
  position: relative;
  cursor: pointer;
  padding: 0 18px;
  margin-top: 80px;
  transition: opacity 200ms ease-in-out;

  @include screen-medium {
    padding: 0; }

  &:hover {
    opacity: 0.6; }

  h4 {
    @include arrow-link($plus: true); } }
