.media-metadata {
  .meta-group {
    margin-bottom: 0.5em; }

  .group-label {
    display: inline;
    font-weight: bold;
    margin-right: 0.15em; }

  .group-content {
    display: inline; } }

ul.links {
  padding: 0;
  margin: 0 0 1rem 0;
  list-style: none;

  .link {
    display: inline-block;
    margin-right: 0.5em; }

  li {
    position: relative;
    margin-bottom: 11px;

    @include screen-medium {
      margin-bottom: space(0.4); } }

  a {
    @include external-link; } }
