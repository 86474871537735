@mixin grid($gutter-width: $grid-gutters) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  margin-left: -($gutter-width / 2);
  margin-right: -($gutter-width / 2);
  // padding-left: 0
  // padding-right: 0

  & > * {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2;
    @include columns(1/1); } }

// Edit default gutters on grid used in markup
@mixin grid-gut($gutter-width: $grid-gutters) {
  margin-left: -($gutter-width / 2);
  margin-right: -($gutter-width / 2);

  & > * {
    padding-left: $gutter-width / 2;
    padding-right: $gutter-width / 2; } }

// $n should be a value between 0 and 1, expressed as a fraction:
// Example: `+columns(5/12)` -> `width: 41.6667%`
@mixin columns($n, $greedy: false) {
  width: 100% * $n;
  margin-left: 0;
  margin-right: 0;

  // Greedy columns have a kind of "fake" width so they appear on their own row
  @if $greedy {
    flex-basis: 100%; } }

@mixin push($n) {
  margin-left: 100% * $n; }

@mixin origin($position: flex-start) {
  justify-content: $position; }

@mixin vertical-align($position: flex-start) {
  align-items: $position; }
