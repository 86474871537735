.single-film,
.single-television {
  padding-bottom: 20px;

  @include screen-medium {
    padding-bottom: 48px; }

  section.content,
  section.share-module {

    @include screen-medium {
      padding: 0 48px; } }

  .hero-media {
    &.no-mobile {
      display: none;

      @include screen-medium {
        display: block; } }

    &.mobile {
      display: block;

      @include screen-medium {
        display: none; } } }

  .hero-media-container {
    position: relative;

    @include screen-small {
      height: 100vh; } }

  .slideshow {
    &.space-filling {
      // Slideshow dimensions have been standardized with the first feature tile on the homepage
      padding-bottom: 0;
      min-height: 100vh;
      display: flex;
      flex-direction: column;

      @include screen-medium {
        padding-bottom: 56.25%;
        min-height: 0; }

      @include screen-large {
        padding-bottom: 47.5%; } }

    button.play-video {
      transform: translate3d(-50%, -50%, 0); }

    .slide.video {

      img {
        transform: scale(1);
        transition: transform 250ms ease-out; }

      &:hover {
        img {
          transform: scale(1.02); } } } }

  .carousel-info__wrapper {
    position: relative;

    @include screen-medium {
      position: absolute;
      bottom: 0;
      left: 0; } }

  .title {
    @include headline;
    left: 0;
    padding: 36px 18px 3px;
    margin-bottom: space(12.6);
    color: $color-white;
    max-width: 11em;

    @include screen-medium {
      @include antialiased-type;
      margin-top: 0;
      margin-bottom: 0;
      padding: 36px 42px 46px;

      &::after {
        border-top-color: $color-white; } }

    @include screen-large {
      padding: 36px 42px 46px; }

    @include screen-wide {
      padding: 36px 42px 46px; } }

  .mobile-date {
    @include eyebrow;
    display: block;
    position: absolute;
    left: 0;
    bottom: 108px;
    padding: 18px 18px 18px 20px;
    color: $color-white;

    @include screen-medium {
      display: none; } }

  section.content {
    margin-top: space(2);

    @include screen-medium {
      margin-top: space(4.5); } }

  .film-details {
    padding: 24px 0 24px;

    @include screen-medium {
      padding: 0 0 38px;
      border-bottom: 0.5px solid $color-black; }

    @include screen-wide {
      padding: 0 6px 38px; } }

  .credits {
    padding: 0 3px;

    @include screen-medium {
      padding: 0; }

    @include screen-wide {
      padding: 0 6px; }

    .credits__logos {
      padding-bottom: 30px;

      .apple-original,
      .hulu,
      .prime-video,
      .netflix,
      .hbo-max,
      .apple-tv,
      .showtime,
      .peacock {
        min-height: 22px;
        background-size: contain;
        background-repeat: no-repeat;
        margin-bottom: 20px;
        opacity: 0.5; }

      .apple-original {
        background-image: url('/assets/images/AAOF.svg'); }

      .hulu {
        background-image: url('/assets/images/hulu_logo-black.svg'); }

      .prime-video {
        background-image: url('/assets/images/prime_video_logo-black.svg');
        min-height: 38px; }

      .netflix {
        background-image: url('/assets/images/netflix_logo-black.svg'); }

      .hbo-max {
        background-image: url('/assets/images/max_logo-black.svg');
        margin: 10px 0 13px; }

      .apple-tv {
        background-image: url('/assets/images/apple_tv-black.svg');
        min-height: 30px; }

      .showtime {
        background-image: url('/assets/images/showtime-black.svg');
        min-height: 30px; }

      .peacock {
        background-image: url('/assets/images/peacock-black.svg');
        min-height: 35px; } }

    .top-content {
      position: relative;

      @include screen-ipad {
        min-height: 76px; }

      @include screen-large {
        min-height: 84px; }

      @include screen-wide {
        min-height: 108px; }

      .credit {
        @include columns(1);
        margin-bottom: space(2);

        @include screen-medium {
          @include columns(1/2);
          margin-bottom: 0; }

        @include screen-ipad {
          @include columns(4/12); }

        @include screen-wide {
          margin-bottom: 24px; } }

      .release-content {
        display: block;

        @include screen-medium {
          @include columns(1/2); }

        @include screen-ipad {
          @include columns(4/12); }

        &.mobile {
          display: block;
          margin-bottom: space(3);

          @include screen-medium {
            display: none; } }

        &.film {
          display: none;

          @include screen-medium {
            display: block; } }

        &.tv {
          margin-bottom: 24px;

          @include screen-medium {
            margin-bottom: 0; } }

        h6 {
          @include eyebrow;
          margin-bottom: 10px;
          color: $color-grey; }

        h3 {
          @include headline-3; }

        .release-date h3 {
          @include headline-3; } }


      .watch-content {
        @include columns(1);
        position: relative;
        padding-bottom: 9px;

        @include screen-ipad {
          @include columns(0.308);
          position: absolute;
          right: space(2);
          padding-bottom: 0;
          margin-bottom: 0;

          @include screen-medium {
            right: calc(6px + 1%); }

          @include screen-large {
            right: calc(6px + 1.5%); }

          @include screen-wide {
            right: calc(8px + 1.5%); }

          @include breakpoint($min-width: 1600px) {
            right: calc(12px + 1.5%); } } } }

    .bottom-content {
      margin-top: 12px;

      @include screen-medium {
        margin-top: 15px; } }

    .starring-content {
      margin-top: 24px;

      @include screen-medium {
        margin-top: 15px; } }

    .credit {
      margin-bottom: space(2);

      @include screen-medium {
        margin-bottom: 0; }

      @include screen-ipad {
        margin-bottom: space(2); }

      @include screen-wide {
        margin-bottom: 38px; }

      &.television:not(.starring) {

        @include screen-medium {
          @include columns(4/12);

          ul {
            column-count: 1; } } }

      h6 {
        margin-bottom: 10px;
        color: $color-grey;

        .content {
          margin-top: space(1); } }

      h6 {
        @include eyebrow; }

      h3,
      li {
        @include headline-3; }

      ul {
        list-style: none;

        @include screen-ipad {
          column-count: 3;
          column-gap: 20px; } }

      li {
        margin-bottom: space(0.75);
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid;
        orphans: 3;

        @include screen-large {
          margin-bottom: 4px; }

        &:first-of-type {
          margin-top: 0; } } } }

  .film-description {
    margin-top: 0;
    padding: 0;

    @include screen-medium {
      margin-top: 52px;
      padding-left: space(1); }

    .meta {
      @include screen-medium {
        @include columns(0.33333);
        margin-bottom: 0; } }

    .meta.links {
      display: none;

      &.links-exist {
        display: block; }

      @include screen-medium {
        display: block; } }

    .synopsis-wrapper {
      padding: 0;

      @include screen-medium {
        @include columns(0.51); } }

    .button-container {
      @include dropdown-container;
      border-bottom: none;

      &.margin {
        margin: 0 13px; }

      .button {
        @include eyebrow;
        @include dropdown-button;
        display: block;
        padding: space(2.2) 0;
        width: 100%;
        line-height: normal;

        @include screen-medium {
          display: none; } }

      a.button {
        cursor: pointer; }

      .synopsis p {

        @include screen-small {
          font-size: 22px;
          line-height: 1.59; } }

      .synopsis,
      .awards {
        max-height: 0;
        overflow: hidden;
        margin-bottom: 0;

        &.active {
          max-height: none;
          margin-bottom: space(3); }
        @include screen-medium {
          overflow: visible;
          max-height: none;
          margin-bottom: space(3); } } } }

  .text-content {
    margin-bottom: space(3); }

  .ways-to-watch {
    margin-bottom: space(2);
    margin-top: -1px;

    .link {
      @include external-link;
      display: inline-block;
      margin-bottom: space(1.2); }

    .button-container {
      @include columns(1);
      max-width: 380px;
      border-top: none;

      @include screen-medium {
        margin-bottom: 0; }

      .button {
        position: relative;
        width: 100%;
        max-width: 380px;

        @include screen-ipad {
          width: 85%; }

        @include screen-wide {
          width: 100%; } }

      &.in-theaters-container {
        margin-bottom: space(2); } }

    .button {
      @include dropdown-button;
      &:after {
        right: space(2);
        top: 24px;

        @include screen-medium {
          top: 21px; }

        @include screen-wide {
          left: 332px;
          top: 23px; } } }

    .button.active {
      &:after {
        transform: rotate(180deg); } }

    .watch-now-links {
      // +inline-list

      li {
        margin: 0 space(1) space(0.5) 0;

        @include screen-large {
          display: block; } } } }

  .meta-group.links {
    padding-top: 52px;
    padding-bottom: 34px;
    margin-bottom: 52px;
    border-top: 0.5px solid $color-black;

    @include screen-medium {
      padding-top: 0;
      padding-bottom: 0;
      border-top: none; } }

  .meta-group {

    &:last-child {
      margin-bottom: 0; }

    .group-label,
    .group-content {
      display: inline; }

    h6 {
      display: none;

      @include screen-medium {
        display: block; } } }

  .release-date,
  .seasons {
    h3 {
      display: inline-block; } }

  .seasons {
    margin-bottom: space(2); }

  .awards {
    h6 {
      @include eyebrow;
      margin-bottom: space(2.2); }

    p + p {
      margin-top: space(2); }

    strong {
      font-weight: $type-weight-medium; } }

  .posters {
    display: none;

    @include screen-medium {
      display: block;
      margin-bottom: space(3); }

    .poster {
      @include columns(4/4);
      @include screen-medium {
        @include columns(2/4); } } }


  .button-container.poster {
    @include dropdown-container;
    border-bottom: none;
    margin: 0 18px;

    .button {
      @include eyebrow;
      @include dropdown-button;
      display: block;
      padding: space(2.2) 0;
      width: 100%;
      line-height: normal;

      @include screen-medium {
        display: none; } } }

  // Mobile-specific poster thumbs
  section.mobile-posters {
    display: block;
    max-height: 0;
    overflow: hidden;
    padding: 0;

    &.active {
      max-height: none;
      margin-bottom: space(3); }

    @include screen-medium {
      display: none; }

    .posters {
      display: block; }

    .poster {
      margin-top: space(2); } }

  section.sharing {
    margin: 0 space(1);
    @include screen-medium {
      margin-top: space(4.5); } }

  .explore {
    padding-bottom: 0;

    .button-container {
      @include dropdown-container;
      border-bottom: none;

      &.margin {
        margin: 0 18px;

        @include screen-medium {
          margin: 0; } }

      .button {
        @include eyebrow;
        @include dropdown-button;
        display: block;
        padding: space(2.2) 0;
        width: 100%;
        line-height: normal;
        margin: 0;
        border-top: none;

        @include screen-medium {
          display: none; } } } } }

.single-television {
  .credit {
    margin-bottom: space(2); }

  .past-date {
    display: none;

    @include screen-medium {
      display: block; } } }
