main.cart {
  .line-items {
    @include screen-large {
      @include columns(6/10);
      @include push(2/10); } }

  .line-item {
    @include grid;
    margin-bottom: space(4.5);

    &:last-child {
      margin-bottom: 0; } }

  .line-item-image {
    @include screen-medium {
      @include columns(2/6); } }

  .line-item-details {
    @include screen-medium {
      @include columns(2/6);
      @include push(1/6); }

    .label,
    .value {
      display: inline-block; }

    .label {
      &::after {
        content: ':'; } }

    .line-item-price,
    .line-item-variant,
    .line-item-quatity {
      margin-bottom: space(1);

      @include screen-medium {
        margin-bottom: space(2); }

      &:last-child {
        margin-bottom: 0; } }

    .line-item-price {
      font-weight: $type-weight-medium; } }

  .line-item-controls {
    margin-top: space(1);

    @include screen-medium {
      margin-top: 0;
      text-align: right;
      @include columns(1/6); }

    button.remove {
      border: none;
      padding: 0;
      color: $color-grey;
      text-decoration: underline;

      &:hover {
        color: $color-black; }

      @include screen-medium {
        @include image-button;
        display: inline-block;
        width: 24px;
        height: 24px;
        background-image: url(inline-asset('/web/assets/images/icons/close-medium-black.svg')); } } }

  .checkout {
    margin-top: space(4.5);

    .totals {
      @include screen-medium {
        @include columns(2/6);
        @include push(3/6); }

      @include screen-large {
        @include columns(2/10);
        @include push(5/10); } }

    .totals-entry {
      .label,
      .value {
        display: inline-block; }

      .label {
        &::after {
          content: ':'; } } }

    .discount-base,
    .total-discount,
    .total-price {
      margin-top: space(1); }

    .total-price {
      font-weight: $type-weight-medium; }

    .next-step {
      margin-top: space(3);

      @include screen-medium {
        @include columns(2/6);
        @include push(3/6); }

      @include screen-large {
        @include columns(2/10);
        @include push(5/10); } } }

  .cart-empty {
    .cart-empty-message {
      @include screen-medium {
        @include columns(4/6);
        @include push(1/6); }

      @include screen-large {
        @include columns(6/10);
        @include push(2/10); } } } }
