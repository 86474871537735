main.position {
  margin-top: 116px;
  // padding: 0 space(2)

  // +screen-medium
  //   padding: 0 space(3) space(3)

  // +screen-large
  //   +columns(4/6)

  @include screen-wide {
    margin-top: 160px; }
  //   padding: 0 space(4) space(5)

  .position-title {
    margin-bottom: space(4);

    @include screen-medium {
      @include columns(0.70);
      margin: 22px auto 24px 17.5%;
      padding: 0; }

    h1 {
      @include headline;
      text-indent: -2px;
      margin-bottom: 23px; }

    h2 {
      @include eyebrow;
      display: block;
      margin-bottom: 29px;
      font-size: $type-size-note-details; }

    .location-list {
      font-size: $type-size-normal;
      font-weight: $type-weight-normal;
      line-height: $type-leading-base;
      text-indent: 0;
      display: block;
      @include comma-separated-list('and'); } }

  .text-content ul {
    padding-left: 0; }

  .position-description {
    margin-bottom: space(4.5);

    .position-text {
      h3 {
        @include eyebrow;
        margin: 86px 0 34px; }

      strong {
        @include headline-3; }

      p {
        margin-top: 38px;
        font-size: 18px;
        line-height: 1.2!important;

        span {
          font-size: 24px;
          line-height: 1.2; } }

      strong {
        font-weight: 400; }

      li {
        font-size: 18px;
        font-weight: $type-weight-normal;
        line-height: 1.5;
        margin-bottom: space(0.5);
        margin-left: 21px; } } }

  .position-application {

    h2 {
      @include eyebrow;
      margin-bottom: 22px; }

    .flash {
      margin-top: space(2); } }

  form {
    .field-container {
      margin-bottom: space(2); } }

  .sharing {
    padding-top: space(3); }

  form .field-container.submit button {
    background: #000;
    height: 62px;
    padding: 0 40px;
    border: none;
    color: #fff;
    text-transform: uppercase;
    font-family: akzidenz-grotesk-extended;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: .1px;

    &:after {
      display: none; } }

  .position-description,
  .position-application {
    > * {
      padding-left: 3px;
      padding-right: 3px;

      @include screen-medium {
        width: 54%;
        margin-left: auto;
        margin-right: auto; } } } }

