.how-to-print {
  margin-top: space(3);

  // Not many buttons are outside forms, so this has to be synchronized with forms.sass:
  button {
    @include input;
    @include icon('arrow-down-micro');
    cursor: pointer;
    padding: space(1/3) space(1);
    width: auto;
    display: inline-block;
    min-width: 160px;
    margin-bottom: space(2);

    &::after {
      float: right;
      margin-top: 2px; }

    &.open {
      @include icon('arrow-up-micro'); } }

  // Hide them by default
  .printing-options {
    display: none;

    a {
      color: $color-grey;
      text-decoration: underline;

      &:hover {
        color: $color-black; } } } }
