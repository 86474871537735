.block.text {
  .text-content {
    p,
    li {
      @include screen-medium {
        font-size: 22px; } }

    &.large,
    .big-type {
      @include headline-3($big-type: true); } } }
