form {
  input,
  textarea,
  button {
    @include input;

    &:-webkit-autofill {
      background-color: $color-black;
      color: $color-white;
      transition: all 5000s ease-in-out 0s; }

    &:disabled {
      color: $color-grey-mid;
      border-color: $color-grey-mid;

      &:active {
        color: $color-grey-mid; } } }

  ::placeholder {
    color: $color-black; }

  // Focusable inputs
  input[type="text"],
  input[type="email"],
  input[type="checkbox"],
  textarea {
    &:focus {} }

  button,
  input[type="submit"],
  input[type="button"] {
    cursor: pointer;
    padding: space(1/3) space(1);
    width: auto;
    display: inline-block;

    &:active {
      color: inherit; }

    &:disabled {
      cursor: default; }

    &.button-with-arrow {
      @include icon('arrow-right-micro');
      padding-right: 9px;

      &::after {
        float: right;
        margin-top: 2px; }

      &:disabled {
        @include icon('arrow-right-micro-grey-mid'); } } }

  button.search {
    padding-left: 3rem;
    background-image: url(inline-asset('/web/assets/images/icons/search.svg'));
    background-size: 18px 18px;
    background-position: 1rem center;
    background-repeat: no-repeat; }

  select {
    @include input;
    padding: space(1/3) space(1);
    background-image: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIxLjEuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAyMS40IDEyLjQiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIxLjQgMTIuNCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPHBvbHlnb24gcG9pbnRzPSIxMC43LDEyLjQgMCwxLjQgMS40LDAgMTAuNyw5LjUgMjAsMCAyMS40LDEuNCAJIi8+CjwvZz4KPC9zdmc+Cg==");
    background-size: 18px 18px;
    background-position: 95% center;
    background-position: right 25px center;
    background-repeat: no-repeat;
    padding: 19.2px 136.4px 19.2px 26.4px;
    border: 1px solid #000;
    width: auto;

    @include screen-medium {
      font-size: $type-size-normal; }

    &:-webkit-autofill {
      box-shadow: 0 0 0 1000px #0FF inset;
      transition: background-color 5000s ease-in-out 0s; } }

  input[type="text"],
  input[type="email"] {
    border: none;
    border-bottom: 1px solid $color-black;
    padding-left: 0;
    padding-right: 0;


    &:focus + span.label::before {
      opacity: 0; } }

  textarea {
    padding: 19px;
    border: 1px solid $color-black;
    height: 140px;
    resize: none; }

  input[type="checkbox"] {
    cursor: pointer;
    display: inline-block;
    width: 27px;
    height: 27px;
    border: 1px solid $color-black;
    appearance: none;
    vertical-align: middle;
    line-height: 1rem;
    padding: 0;
    background-size: 27px 27px;
    background-position: center center;
    background-repeat: no-repeat;

    &:checked {
      background-image: url(inline-asset('/web/assets/images/icons/check-large.svg')); } }

  input[type="file"] {}

  label {
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0; }

    a {
      color: $color-grey;
      text-decoration: underline; }

    &.required {
      .label {
        &::after {
          display: inline-block;
          content: '*';
          margin-left: 0.25em; } } }

    &.static-label {
      .label {
        font-size: $type-size-small;
        top: 0; } }

    &.special-label,
    &.static-label {
      &.with-data {
        .label {
          display: none !important;
          // font-size: $type-size-small
 } }          // top: 0

      .label {
        font-weight: $type-weight-normal;
        margin-bottom: 0;
        color: $color-grey; } }

    &.special-label {
      padding-top: space(2);
      position: relative;

      .label {
        text-transform: none;
        position: absolute;
        top: 19px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100%; }

      .label,
      input,
      textarea {
        @include label; } } }

  // Labels

  span.label {
    @include label;
    display: block;
    margin-bottom: 12px; }

  .input-type-textarea {
    &.special-label .label {
      white-space: pre-wrap; }

    span.label {
      margin-top: 20px;
      left: 20px; } }

  span.inline-label {
    @include label;
    margin-left: 0.5em;
    display: inline;
    vertical-align: middle; }

  .field-container {
    &.required {
      span.label::after {
        display: inline-block;
        content: '*';
        margin-left: 0.25em; } } }

  .textinput__label {
    font-size: 13px;
    position: absolute;
    top: 52%;
    left: space(1.5);
    z-index: 1;
    transition-duration: 75ms;
    transition-property: transform;
    transition-timing-function: ease-in;
    color: #f1f1f1;
    pointer-events: none;
    text-transform: uppercase;
    transform: translateY(-50%);
    transform: scale(.7) translateY(-2em) translateY(-50%);
    transform-origin: left center; }

  // Messaging

  .instructions {
    color: $color-grey;
    margin-bottom: space(1); }

  .messages {
    font-size: $type-size-small;

    @include screen-medium {
      font-size: $type-size-normal; } }

  .errors {
    display: none;
    margin-top: space(1);
    font-style: $type-style-emphasis;
    color: red;

    &.with-errors {
      display: block; } }

  // Dynamic Forms

  .form-row {
    @include grid; }

  .field-container {
    label {
      padding-top: space(2); }

    // Layout
    &.half {
      @include screen-medium {
        @include columns(1/2); } }

    &.third {
      @include screen-medium {
        @include columns(1/3); } }

    &.quarter {
      @include screen-medium {
        @include columns(1/4); } }

    // Types

    &.file {
      input {}

      .file-field-wrapper {
        width: 0;
        height: 0;
        overflow: hidden; }

      .file-control {
        font-size: 18px;
        padding: 0;
        border: none;
        color: $color-grey;
        text-decoration: underline;
        text-align: left;

        &.file-control-remove {
          display: none; } }

      label {
        &.with-file {
          .file-control-add {
            display: none; }

          .file-control-remove {
            display: block; } } } }

    &.checkbox {
      .label {
        display: none; }

      input {
        margin-right: space(0.5); }

      .instructions {
        display: inline-block;
        vertical-align: middle;
        margin-bottom: 0;
        color: $color-black; } }

    &.checkbox_group { // This is non-conformant because it's provided by Freeform…
      .label {
        padding-top: space(2); }

      .options {
        display: flex;
        flex-wrap: wrap; }

      .option {
        width: 100%;

        @include screen-medium {
          width: 50%;
          padding-right: space(1); } }

      label {
        padding-top: space(1); } }

    &.submit {
      // Stop the whole `label` from triggering click
      pointer-events: none;

      button {
        pointer-events: auto;
        @include icon('arrow-right-micro');

        &:disabled {
          @include icon('arrow-right-micro-grey-mid'); } } } }

  // Dark "theme"

  &.dark {
    input,
    textarea {
      border-color: $color-white;
      color: inherit; }

    ::placeholder {
      color: $color-white; }

    button,
    input[type="submit"],
    input[type="button"] {
      color: inherit;
      border-color: transparent; }

    input[type="text"],
    input[type="email"],
    input[type="checkbox"],
    textarea {
      &:focus {} } } }
