.media-list-wrapper {
  position: relative;
  overflow: hidden;

  @include screen-medium {
    margin-top: 0; } }

.media-list {
  padding: 0 18px;
  margin-bottom: space(0.8);
  color: $color-black;
  position: relative;
  @include columns(1);

  @include screen-medium {
    @include columns(1);
    padding: 0;
    margin-bottom: 4px; }

  &.inactive {
    a {
      @include screen-medium {
        cursor: default; } } }

  .media-list-title {
    .title {
      margin-bottom: 0.15em;
      font-weight: $type-weight-medium;
      font-size: $type-size-h2;
      line-height: $type-leading-h2;
      letter-spacing: $type-tracking-h2;

      @include screen-small {
        font-size: 42px;
        margin-bottom: 0.3em; }

      @include screen-large {
        font-size: 96px; }

      @include screen-wide {
        font-size: 120px; } } }

  a {
    display: inline-block;
    transition: color 200ms ease-in-out;

    &:hover {
      color: $color-grey; } }

  sup {
    display: none;
    margin-left: 0.25em;
    white-space: nowrap;

    @include screen-medium {
      display: inline; } } }

.media-list-image {
  @include columns(7/12);
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  max-height: 60vh;
  overflow: hidden;
  z-index: -1;
  opacity: 0;
  transition: opacity 100ms ease-in;

  @include screen-medium {
    display: block; }

  &.sticky {
    position: fixed;
    margin-top: 20vh; }

  &.active {
    opacity: 1; }

  .responsive-image {
    padding-bottom: (9/16) * 100%;

    @include screen-medium {
      padding-bottom: 70%; } }

  .image {
    position: relative;
    margin-bottom: 10px; }

  img {
    position: absolute;
    top: 0;
    left: 0;
    @include fade-in(false);

    &.loaded {
      opacity: 1; } } }
