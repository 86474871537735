// Layout

$breakpoint-small: 640px;
$breakpoint-medium: 1024px;
$breakpoint-large: 1300px;

$grid-gutters: space(1);

// Type

$type-size-root: 15px;
$type-size-small: 10px;
$type-size-normal: 15px;
$type-size-medium: 28px;
$type-size-large: 40px;
$type-size-jumbo: 100px;

$type-size-overlay-eyebrow: 13px;
$type-size-attribution: 14px;
$type-size-footer-copy: 15px;
$type-size-footer-eyebrow: 12.1px;
$type-size-note-details: 17px;
$type-size-nav: 18px;
$type-size-caption: 22px;
$type-size-pullquote: 58px;
$type-size-search-input: 84px;

$type-size-h6: 15px;
$type-size-h5: 24px;
$type-size-h4: 28px;
$type-size-h3: 38px;
$type-size-h2: 62px;
$type-size-h1: 74px;

$type-size-paragraph-small: 18px;
$type-size-paragraph: 27px;

$type-leading-base: 25px;
$type-leading-medium: 36px;
$type-leading-large: 50px;
$type-leading-jumbo: 110px;

$type-leading-h6: 1;
$type-leading-h5: 1;
$type-leading-h4: 1;
$type-leading-h3: 1;
$type-leading-h2: 0.92;
$type-leading-h1: 0.92;

$type-tracking-default: -0.005em;
$type-tracking-paragraph: -0.0125em;
$type-tracking-nav: -0.009em;

$type-tracking-h6: 0.005em;
$type-tracking-h5: -0.025em;
$type-tracking-h4: -0.025em;
$type-tracking-h3: -0.03em;
$type-tracking-h2: -0.04em;
$type-tracking-h1: -0.04em;
$type-tracking-h6-mono: -0.065em;
$type-tracking-h5-mono: -0.075em;
$type-tracking-h4-mono: -0.085em;
$type-tracking-h3-mono: -0.095em;

$type-size-paragrph: 38px;

$type-family-sans: $font-name-sans, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$type-family-mono: $font-name-mono, 'Helvetica Neue', Helvetica, Arial, sans-serif;

$type-weight-normal: 400;
$type-weight-medium: 500;
$type-weight-bold: 700;

$type-style-emphasis: italic;

// Color

$color-white: rgb(255, 255, 255);
$color-black: rgb(0, 0, 0);
$color-grey: rgb(136, 136, 136);
$color-grey-mid: rgb(202, 202, 202);
$color-grey-light: rgb(238, 238, 238);
$color-grey-dark: rgb(68, 68, 68);

// Easing

$quad-ease-in : cubic-bezier(0.550, 0.085, 0.680, 0.530);
$cubic-ease-in: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$quart-ease-in: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$quint-ease-in: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$sine-ease-in: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$expo-ease-in: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$circ-ease-in: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$back-ease-in: cubic-bezier(0.600, -0.280, 0.735, 0.045);
$ease-in-out-back: cubic-bezier(0.68, -0.55, 0.265, 1.55);

$quad-ease-out: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$cubic-ease-out: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$quart-ease-out: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$quint-ease-out: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$sine-ease-out: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$expo-ease-out: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$circ-ease-out: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$back-ease-out: cubic-bezier(0.175, 0.885, 0.320, 1.275);

$quad-ease-in-out: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$cubic-ease-in-out: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$quart-ease-in-out: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$quint-ease-in-out: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$sine-ease-in-out: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$expo-ease-in-out: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$circ-ease-in-out: cubic-bezier(0.785, 0.135, 0.150, 0.860);

$ease-out-power1: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$ease-out-power2: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$ease-out-power3: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$ease-out-power4: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$ease-in-power1: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$ease-in-power2: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$ease-in-power3: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$ease-in-power4: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$ease-out-expo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$ease-in-expo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$ease-in-out-expo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$ease-out-sine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$ease-in-sine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$ease-in-out-power1: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$ease-in-out-power2: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$ease-in-out-power3: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$ease-in-out-power4: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$ease-out-slow: cubic-bezier(.04,1.15,0.4,.99);
