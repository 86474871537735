aside.loader {

  &.inline {
    display: inline-block;
    vertical-align: middle;
    pading-left: 15px;

    &::after {
      content: '';
      width: 16px;
      height: 16px;
      display: block;
      background-image: url(inline-asset('web/assets/images/icons/loader-small.svg'));
      background-size: 16px 16px;
      background-position: center center;
      animation: spin 2s linear infinite; } }

  &.fullscreen {
    display: block;
    @include fill(fixed);
    background-color: rgba($color-white, 0.9);

    &::after {
      width: 50px;
      height: 50px;
      background-image: url(inline-asset('web/assets/images/icons/loader.svg'));
      background-size: 50px 50px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -25px 0 0 -25px; } } }

@keyframes spin {
  0% {
    transform: rotate(0); }

  100% {
    transform: rotate(360deg); } }
