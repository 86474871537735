main.checkout {
  .checkout-container {
    @include screen-medium {
      @include columns(3/6);
      @include push(1/6); }

    @include screen-large {
      @include columns(3/10);
      @include push(2/10); } }

  .checkout-sidebar {
    margin-top: space(4.5);

    @include screen-medium {
      @include columns(4/6);
      @include push(1/6); }

    @include screen-large {
      @include columns(2/10);
      @include push(3/10);
      margin-top: 0; } }

  // Step 1: Login/Guest Checkout
  &.start {}

  // Step 2: Addresses
  &.address {
    form {
      @include grid; } }

  // Step 3: Shipping
  &.shipping {
    .shipping-options {
      .price {
        &::before {
          content: '('; }

        &::after {
          content: ')'; }

        @include screen-medium {
          float: right;
          margin-top: 1px;

          &::before,
          &::after {
            content: none; } } } } }

  // Step 4: Payment
  &.payment {
    .billing-address-config {
      .form-section {
        margin-bottom: space(2);

        &:last-child {
          margin-bottom: 0; } }

      .form-section.billing-address {
        display: none; }

      // This is always going to be hidden.
      .form-section.shipping-address {
        display: none; }

      &.billing-address-required {
        .form-section.billing-address {
          display: block; } } }

    .payment-create {
      margin-top: space(4.5);

      @include screen-medium {
        margin-top: space(8); } }

    .stripe-element-group {
      @include grid; }

    .stripe-element {
      @include input;
      padding-left: 0;
      padding-right: 0;
      border: none;
      border-bottom: 2px solid $color-black; }

    .stripe-element-container {
      &:not(:first-child) {
        margin-top: space(1); } }

    .stripe-element-container-card-number {}

    .stripe-element-container-card-expiry {
      @include columns(1/2); }

    .stripe-element-container-card-cvc {
      @include columns(1/2); } }


  // Step X: Complete
  &.complete {
    .checkout-confirmation {}

    .next {
      margin-top: space(2);

      a {
        text-decoration: underline; } }

    .celebration {
      margin-top: space(4.5); } } }
