.block.headline {
  .headline-container {
    padding: space(2) 0;
    border-top: 1px solid $color-black;
    border-bottom: 1px solid $color-black; }

  .headline-image {
    margin-bottom: space(1.5); }

  .headline-title {
    margin-bottom: space(1.5);

    h2 {
      @include large-text; } }

  .headline-summary {
    font-size: 18px;
    line-height: 1.4;

    a {
      text-decoration: underline;
      color: $color-black;
      display: inline-block;

      &:hover {
        color: $color-grey; } } }

  .headline-attribution {
    font-size: 18px;
    line-height: 1.4;
    font-weight: $type-weight-medium; } }
