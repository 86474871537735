.stack-item {
  @include columns(11/12);
  @include margin-auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 0 25px;
  margin-top: 33px;
  border-bottom: 1px solid $color-black;

  @include screen-small {
    width: auto;
    margin-left: 24px;
    margin-right: 24px; }

  @include screen-medium {
    @include columns(8.57/10);
    flex-wrap: nowrap;
    flex-direction: row;
    padding: 0;
    margin: 0 auto 90px;
    border-bottom: none;

    &:nth-child(odd) .copy-wrapper {
      order: 3;
      padding: 0 0 0 56px; }

    &:nth-child(even) .copy-wrapper {
      order: 2;
      padding: 0 56px 0 0; }

    &:nth-child(odd) figure {
      order: 2; }

    &:nth-child(even) figure {
      order: 3; } }

  @include screen-wide {
    margin: 0 auto 120px; }

  &.film,
  &.show {
    cursor: pointer; }

  .eyebrow.mobile {
    display: block;
    margin-top: 0;

    @include screen-medium {
      display: none;
      order: 1; } }

  &:first-of-type {
    margin-top: space(3); }

  &:last-of-type {
    border: none;
    margin-bottom: 0; }

  &.full-width {
    @include columns(1/1);
    max-height: 80vh;
    height: 80vh;
    overflow: hidden;
    border-bottom: none;
    margin-bottom: 20px;

    @include screen-medium {
      height: auto;
      margin-bottom: 90px; }

    @include screen-wide {
      margin-bottom: 120px; }

    @include breakpoint($max-height: 800px) {
      max-height: 85vh; }

    figure {
      @include columns(1/1);
      height: 100%;
      max-height: 80vh;

      @include breakpoint($max-height: 800px) {
        max-height: 85vh; }

      .responsive-image,
      img {
        min-height: 80vh;
        width: auto;
        min-width: 100%;

        @include breakpoint($max-height: 800px) {
          min-height: 85vh; } }

      .mobile .responsive-image {
        min-height: none;
        max-height: 100vh;

        @include screen-medium {
          max-height: none; } }

      img {
        left: 50%;
        transform: translate3d(-50%, 0, 0); }

      .image-link {
        img,
        .stack-image img {
          transform-origin: left center; } }

      .image-link:hover {
        img,
        .stack-image img {
          transform: scale(1.04) translate3d(-50%, 0, 0); } }

      .stack-image {
        &.no-mobile {
          display: none;

          @include screen-medium {
            display: block; } }

        &.mobile {
          display: block;

          img {
            width: auto!important;
            height: 100%; }

          @include screen-medium {
            display: none; } } } }

    figcaption {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      padding: 0px 17px;
      color: $color-white;

      @include screen-medium {
        padding: 0 42px; }

      h2 {
        color: $color-white;
        margin-bottom: 22px;

        @include screen-medium {
          margin-bottom: 51px; } }

      .eyebrow {
        display: block;
        margin-bottom: 2px;
        color: $color-grey; } } }

  &.archive-preview {
    @include screen-medium {

      &:nth-child(odd) .copy-wrapper {
        order: 2;
        padding: 0 0 0 56px; }

      &:nth-child(even) .copy-wrapper {
        order: 1;
        padding: 0 56px 0 0; }

      &:nth-child(odd) .story-image {
        order: 2; }

      &:nth-child(even) .story-image {
        order: 3; } } }

  .image-link {
    overflow: hidden;

    img,
    .stack-image img {
      transition: transform 500ms ease-out;
      transform-origin: center center; } }

  .image-link:hover {
    img,
    .stack-image img {
      transform: scale(1.04); } }

  .stack-image {
    padding-bottom: 56.25%;
    background-color: $color-black;
    @include filling-image-container;

    &.note,
    &.show,
    &.product {
      padding-bottom: 0; }

    img {
      @include fade-in(false);

      &.loaded {
        opacity: 1; } } }

  figure {
    position: relative;
    @include columns(1/1);

    @include screen-medium {
      @include columns(6/10); } }

  .copy-wrapper {
    @include columns(1/1);
    position: relative;

    @include screen-medium {
      @include columns(4/10);

      // &.left
      //   padding: 0 56px 0 0
      //
      // &.right
 }      //   padding: 0 0 0 56px

    // &.mobile
    //   +screen-medium
    //     display: none

    .link {
      @include arrow-link($hover: true);
      position: relative;
      margin-top: space(2);
      margin-bottom: space(2);

      @include screen-medium {
        position: absolute;
        bottom: 0;
        margin-bottom: 0; } }

    a > h2,
    h2 > a {
      transition: opacity 200ms ease-in-out;

      &:hover {
        opacity: 0.6; } } }

  .eyebrow {
    @include eyebrow;
    display: none;
    margin-top: space(1.2);
    margin-bottom: space(0.8);
    color: $color-grey;

    @include screen-medium {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px; } }

  h2 {
    @include headline($mobile-friendly: true);
    @include antialiased-type;
    color: $color-black;
    margin-top: space(0.8);

    @include screen-small {
      margin-bottom: 0; }

    @include breakpoint($max-width: 360px) {
      font-size: 6vw;
      line-height: 8vw; }

    @include screen-medium {
      margin-top: 0;
      margin-bottom: space(6); }

    @include screen-wide {
      margin-top: 0;
      margin-bottom: space(8); } } }

.stack-item > .copy-wrapper:first-child {
  display: none;

  @include screen-medium {
    display: block; } }
