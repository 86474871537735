.size-chart-table {
  max-width: 500px;
  margin: 15vh auto;
  padding: space(1);
  border: 2px solid $color-white;
  @include antialiased-type;

  @include screen-medium {
    padding: space(3); }

  .size-chart-row {
    display: flex;
    flex-wrap: none;
    width: 100%;
    margin-bottom: space(1);

    &:last-child {
      margin-bottom: 0; }

    @include screen-medium {
      margin-bottom: space(2); } }

  .size-chart-datum {
    width: 10%;
    flex-grow: 1; }

  .modal & {
    color: $color-white; } }
