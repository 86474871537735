.product-detail {
  .product-title {
    margin-bottom: space(2);

    h1 {
      font-size: $type-size-large;
      line-height: $type-leading-large;
      font-weight: $type-weight-normal;

      @include screen-medium {
        font-size: $type-size-jumbo;
        line-height: $type-leading-jumbo; } } }

  .product-image {
    margin-bottom: space(4.5);

    figure {
      @include screen-large {
        @include columns(6/10);
        @include push(2/10); } }

    figcaption {
      display: none; } }

  .product-info {
    @include grid;

    .product-text {
      margin-bottom: space(2);

      @include screen-medium {
        margin-bottom: 0;
        @include columns(3/6); }

      @include screen-large {
        @include columns(4/10);
        @include push(1/10); } }

    .product-purchase {
      @include screen-medium {
        @include columns(2/6);
        @include push(1/6); }

      @include screen-large {
        @include columns(2/10);
        @include push(1/10); } } }

  .product-supplemental {
    margin-bottom: space(3);

    .text-content {
      color: $color-grey; }

    .size-chart-table {
      display: none; } }

  .product-price {
    @include large-text;
    margin-bottom: space(3); }

  .option-selection {
    margin-bottom: space(3); }

  .quantity-select {
    margin-bottom: space(3); }

  .waitlist {
    margin-top: space(2); }

  .sharing {
    margin-top: space(4.5); }

  .additional-images {
    margin-top: space(4.5);
    @include grid;

    .additional-image-container {
      margin-bottom: space(1);

      @include screen-medium {
        @include filling-image-container; } }

    figure {}

    figcaption {
      display: none; }

    .responsive-image {} }

  .image-row {
    @include screen-large {
      @include columns(8/10);
      @include push(1/10); }

    &.single {}

    &.double {
      .additional-image-container {
        @include screen-medium {
          height: 50vw; }

        @include screen-large {
          height: 30vw; } } } }

  .image-group {
    @include grid;

    // Default image group layout
    .additional-image {
      @include screen-medium {
        @include columns(3/6); } }

    &.left {
      .additional-image:first-child {
        @include screen-medium {
          @include columns(4/6); }

        @include screen-large {
          @include columns(6/10); } }

      .additional-image:last-child {
        @include screen-medium {
          @include columns(2/6); }

        @include screen-large {
          @include columns(4/10); } } }

    &.right {
      .additional-image:first-child {
        @include screen-medium {
          @include columns(2/6); }

        @include screen-large {
          @include columns(4/10); } }

      .additional-image:last-child {
        @include screen-medium {
          @include columns(4/6); }

        @include screen-large {
          @include columns(6/10); } } } } }
