.search-wrapper {
  .search-bar {
    background-color: $color-black;
    padding: 42px 13px 45px 45px;
    margin-bottom: space(2); }

  .grid {
    position: relative;
    height: 100%;
    @include vertical-align(center); }

  .search-form {
    @include headline;

    @include screen-medium {
      @include columns(1); } }

  form {
    @include grid;
    align-items: center;

    input[type="text"],
    input[type="email"] {
      padding-right: 50px;

      @include screen-large {
        padding-right: 0; } } }

  label {
    margin-bottom: 0; }

  label.search {
    @include columns(3/4);

    @include screen-medium {
      @include columns(3/6); }

    @include screen-large {
      @include columns(2/3); }

    input {
      @include input;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
      border: none;
      border-bottom: 2px solid $color-white;
      font-size: $type-size-small; } }

  label.submit {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    width: 54px;
    height: 45px;
    background-image: url(inline-asset('/web/assets/images/icons/right-arrow-white.svg'));
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    background-color: black;

    @include screen-medium {
      width: 38px;
      height: 27px; }

    @include screen-large {
      width: 46px;
      height: 36px; }

    @include screen-wide {
      top: 51%;
      width: 54px;
      height: 45px; }

    input {
      width: auto;
      padding-left: 0;
      padding-right: 0;
      font-size: $type-size-small; } }

  button.close {
    @include image-button;
    position: absolute;
    top: space(5);
    right: space(0.8);
    background-image: url(inline-asset('/web/assets/images/icons/close-medium.svg'));
    width: 34px;
    height: 34px;
    opacity: 0;
    transition: opacity 100ms ease-out 350ms;

    @include screen-medium {
      top: space(0.5); } } }
