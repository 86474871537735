*,
*::before,
*::after {
  box-sizing: border-box; }

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
  padding: 0;
  margin: 0;
  border-radius: 0;
  appearance: none;
  line-height: $type-leading-base;
  outline: none; }

select {
  &::-ms-expand {
    display: none; } }

label {
  display: block; }
