footer {
  display: flex;
  flex-flow: row;
  position: relative;
  padding: 30px 18px;
  font-family: $type-family-sans;
  color: $color-white;
  line-height: 16px;
  @include fade-in(false);
  @include antialiased-type;
  background-color: $color-black;
  text-transform: uppercase;

  @include screen-medium {
    display: block;
    padding: 5px 42px 32px; }

  li {

    display: block;
    width: auto;
    font-size: 12px;
    line-height: 12px;

    @include screen-medium {
      font-size: $type-size-footer-copy;
      line-height: inherit; }

    &:not(:last-child) {
      margin-bottom: space(.75); }

    a:hover {
      border-bottom: 1px solid $color-white; } }

  &.ready {
    opacity: 1; }

  section {
    padding: 24px 0 44px;

    @include screen-medium {
      padding: space(4.5) 0; } }

  .grid {
    align-items: stretch;
    margin: 0; }

  .footer-grid__col {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }

  .grid__col {
    flex: 1 0 0;
    padding-left: 0;

    &:not(:last-child) {
      padding-right: space(4);

      @include breakpoint($max-width: 922px) {
        margin-bottom: space(4.5);
        padding-right: space(2); } }

    @include breakpoint($max-width: 922px) {
      flex-basis: 50%;

      &:nth-child(2n) {
        padding-right: 0; } } }

  .footer-col-eyebrow {
    border-top: 1px solid white;
    font-size: 10px;

    @include breakpoint($max-width: 922px) {
      margin-bottom: space(1.5); }

    @include screen-medium {
      font-size: $type-size-footer-eyebrow; } }

  .legal {
    ul {
      &:first-of-type {
        margin-bottom: space(3); } } }

  .sharing {
    @include columns(1);
    order: 2;
    display: block;
    margin-left: 6px;
    margin-bottom: space(3);
    text-align: left;

    @include screen-medium {
      margin-bottom: space(4); }

    @include screen-large {
      @include columns(0.303);
      margin-left: 0;
      margin-bottom: 0; }

    .grid {
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      h4 {
        @include eyebrow;
        width: auto;
        text-align: left;
        flex-basis: 100%;
        margin-bottom: space(2); }

      nav {
        width: auto;
        text-align: left;

        ul {
          margin-left: -7px; } }

      a {
        @include svg-flood {
          transition: 100ms linear fill;
          fill: $color-white; }

        &:hover {
          @include svg-flood {
            fill: $color-grey; } } } } }

  .newsletter {
    flex-grow: 2;

    .cta {
      margin-bottom: space(2);
      max-width: 23em;
      text-transform: initial;
      font-size: 12px;

      @include screen-medium {
        font-size: inherit; }

      @include breakpoint($max-width: 922px); }

    form {
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;

      label {
        margin-bottom: 0.25rem; } }

    .messages {
      color: #787878;
      position: absolute;
      top: calc(100% + 0.25rem * 1);
      font-size: .875rem;
      letter-spacing: -0.01em;
      text-transform: none;
      padding: 0; } }

  .disclaimer {
    font-size: 11px;
    line-height: 1.5;
    opacity: 0.7;
    margin-top: space(3);
    max-width: 21em; } }

