aside.modal {
  @include fill(fixed);
  background-color: rgba($color-black, 0.9);
  padding: 0 space(2) space(4.5) space(2);
  z-index: 4;
  overflow: auto;

  .modal-wrap {
    @include grid;
    @include origin(center); }

  .modal-content {
    @include screen-large {
      @include columns(6/10); } }

  .modal-controls {
    padding-top: space(2);
    padding-bottom: space(4.5);
    text-align: right; }

  button.close {
    @include image-button;
    margin: 0 0 0 auto;
    width: 35px;
    height: 35px;
    background-image: url(inline-asset('/web/assets/images/icons/close.svg'));

    @include screen-large {
      width: 40px;
      height: 40px; } } }
