.flashes {}

.flash {
  padding: space(1) space(1.25);
  background-color: $color-grey-light;
  margin-top: space(1);

  @include screen-medium {
    padding: space(1.5) space(1.75); }

  &:first-child {
    margin-top: 0; }

  &.error {
    color: $color-white;
    background-color: $color-black; }

  &.size-compact {
    padding: space(0.75) space(1);
    margin-bottom: space(1); }

  &.size-inline {
    padding: 0;
    background-color: transparent;
    color: red;
    font-style: $type-style-emphasis; } }
