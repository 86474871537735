.block.tweet {
  .tweet {
    position: relative;

    &.is-embedded {}

    &.is-custom {} }

  .tweet-image {
    margin-top: space(2); }

  .tweet-text {
    word-wrap: break-word;
    @include headline-3;

    a {
      color: $color-black;
      border-bottom: 1px solid $color-black;

      &:hover {
        color: $color-grey;
        border-bottom: 1px solid $color-grey; }

      &.is-hashtag {
        border-bottom: 1px solid $color-black; } } }

  .tweet-attribution {
    margin-top: space(0.5);
    color: $color-grey;

    @include screen-large {
      margin-top: space(1); }

    a {
      @include eyebrow;
      color: $color-black;
      transition: all 100ms ease-in-out;
      border-bottom: 1px solid $color-black;

      &:hover {
        color: $color-grey;
        border-bottom: 1px solid $color-grey; }

      &.is-hashtag {
        border-bottom: 1px solid $color-black; } } } }
