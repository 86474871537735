.hero-media {
  .slideshow {
    .attribution {
      display: none!important; }
    figcaption {
      display: none!important; } } }

.slideshow {
  position: relative;
  overflow: hidden;

  &.space-filling {
    padding-bottom: 100%;

    .slides {
      position: absolute; } }

  &.single-slide {
    .pagination .image-button,
    .indicators {
      display: none; } }

  &.touch {
    .slides {
      transition: none; } }

  .slides {
    position: relative;
    overflow: hidden;
    height: 100%;
    // transition: 500ms cubic-bezier(0.25, 0.75, 0.5, 1) left

    .slide {
      float: left;
      overflow: hidden;
      background: none;
      padding: 0;
      position: relative;
      // background: black
      @include fade-in(false);

      &.loaded {
        opacity: 1; } }

    img {
      display: block;
      max-width: none;
      @include fade-in;

      &.loaded {
        opacity: 1; } }

    .text-content {
      position: relative;
      margin-top: space(1);

      @include screen-medium {
        margin-top: space(2); }

      &.attribution {
        @include eyebrow($attribution: true);
        min-height: 25px;
        text-align: right;
        text-transform: none;
        margin-bottom: space(1);
        font-size: 14px;
        color: $color-grey; }

      .slide-title {
        font-family: $type-family-sans;
        text-transform: uppercase;
        font-size: 18px; } } }

  .image-button__wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .image-button__inner-wrapper {
      transform: translate3d(0, 0, 0);
      flex: 1;
      margin-top: 48px;
      width: 100%;

      @include screen-medium {
        margin-top: 0; } }

    .carousel-info__wrapper {
      opacity: 0; } }

  .pagination {
    position: relative;
    width: 100%;
    flex: 1;
    margin-top: 50px;
    z-index: 1;
    pointer-events: none;

    @include screen-medium {
      position: absolute;
      height: 100%;
      top: 50%;
      margin-top: 0;
      transform: translate3d(0, -50%, 0); }

    .prev,
    .next {
      @include image-button;
      position: absolute;
      width: 40px;
      height: 100%;
      pointer-events: auto;
      background-size: 40px 40px;

      @include screen-medium {
        width: 25px;
        background-size: 25px 45px; } }

    .prev {
      background-image: url(inline-asset('/web/assets/images/icons/arrow-left.svg'));
      left: 0;
      margin-left: space(1);

      @include screen-medium {
        padding-left: 76px;
        margin-left: 0; } }

    .next {
      background-image: url(inline-asset('/web/assets/images/icons/arrow-right.svg'));
      right: 0;
      margin-right: space(1);

      @include screen-medium {
        padding-right: 76px;
        margin-right: 0; } } }

  .indicators {
    position: absolute;
    bottom: space(1);
    left: 0;
    width: 100%;
    text-align: center;

    @include screen-large {
      bottom: space(2); }

    .dot {
      display: inline-block;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: rgba(white, 0.5);
      margin: 0 0.5em;

      &:first-of-type,
      &:last-of-type {
        display: none; }

      &.on {
        background-color: rgba(white, 0.75); } } } }
