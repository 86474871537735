nav.social-links {
  ul {
    @include inline-list;
    line-height: 0;
    cursor: default; }

  li {
    @include screen-medium {
      margin-right: space(1.2);

      &:last-of-type {
        margin-right: 0; } } }

  a {
    display: block;
    width: 1.5rem;
    height: 1.5rem; } }
