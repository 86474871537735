html {
  --burger-width: #{0.6 * 3vw};
  --burger-height: 10px;

  @include screen-shop-phone {
      --burger-width: #{2.5 * 10px * 0.875};
      --burger-height: 8px; }

  @include screen-shop-tablet {
      --burger-width: #{0.9 * 3vw};
      --burger-height: 8px; } }

.burger {
  --burger-color: #{$color-black};
  --burger-color-current-page: #{$color-black};
  @include eyebrow;
  position: fixed;
  top: var(--header-padding-top);
  left: var(--header-padding-horiz);
  z-index: 4;
  height: var(--burger-height);
  margin: -6px 0  0 0;
  padding: 6px;
  box-sizing: content-box;
  cursor: pointer;
  color: var(--burger-color);
  line-height: 1;
  transition: 0.25s cubic-bezier(0, 1, 0.25, 1) width, transform 0.2s ease-in-out;

  @include screen-large {
    &:not(.active):hover {
      --burger-color: #{$color-grey};

      .bun.top {
        transform: translate(0, calc(-0.5 * (var(--burger-height) + 2px))); }

      .bun.bottom {
        transform: translate(0, calc(0.5 * (var(--burger-height) + 2px))); } } }

  &.active {
    --burger-color: #{$color-white};

    .bun.top {
      transform: rotate(45deg); }

    .bun.bottom {
      transform: rotate(-45deg); }

    .burger-label__breadcrumbs::before {
      content: 'Close'; }

    .burger-label__breadcrumbs::after {
      display: none; } }

  &.hidden {
    // transform: translate3d(0, -200%, 0)

    &:not(:hover) .burger-label {
      opacity: 0; } }

  .piece {
    position: absolute;
    top: 50%;
    left: 0;
    width: var(--burger-width);
    border-bottom: 1px solid var(--burger-color);
    transition: 0.25s cubic-bezier(0, 1, 0.25, 1) all; }

  .bun.top {
    transform: translate(0, calc(-0.5 * var(--burger-height))); }

  .bun.bottom {
    transform: translate(0, calc(0.5 * var(--burger-height))); }

  .burger-label {
    height: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: calc(var(--burger-width) * 1.333);
    transition: 0.5s ease opacity;

    &__breadcrumbs {
      display: flex;

      &::before {
        content: 'Menu'; }

      &::after {
        color: var(--burger-color-current-page);

        @at-root body.films #{&} {
          @include screen-medium {
            content: '\00a0/\00a0 Films'; } }

        @at-root body.television #{&} {
          @include screen-medium {
            content: '\00a0/\00a0 Television'; } }

        @at-root body.docs #{&} {
          @include screen-medium {
            content: '\00a0/\00a0 Docs'; } }

        @at-root body.about #{&} {
          @include screen-medium {
            content: '\00a0/\00a0 About'; } }

        @at-root body.notes #{&} {
          @include screen-medium {
            content: '\00a0/\00a0 Notes'; } } } } } }

header.home:not(.sticky) ~ .burger,
header.hero:not(.sticky) ~ .burger {
  --burger-color: #{$color-white};
  --burger-color-current-page: #{$color-white}; }
