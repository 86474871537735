main.order {
  section.order-summary {}

  section.order-details {
    .order-detail-group {
      margin-bottom: space(4.5);

      @include screen-large {
        margin-bottom: 0; } }

    h2 {
      margin-bottom: space(2); }

    .details,
    .history,
    .payments {
      @include screen-medium {
        @include columns(2/6); }

      @include screen-large {
        @include columns(2/10);
        @include push(2/10); } }

    // Order Details
    .details {}

    .order-number {
      margin-bottom: space(2); }

    .order-items {
      .line-item {
        margin-bottom: space(2); }

      .line-item-info-group {
        .label,
        .value {
          display: inline-block; }

        .label {
          &::after {
            content: ':'; } } } }


    .order-totals {
      .totals-entry {
        .label,
        .value {
          display: inline-block; }

        .label {
          &::after {
            content: ':'; } }

        &.total {
          margin-top: space(1);
          font-weight: $type-weight-medium; } } }

    // Order History
    .history {
      @include screen-large {
        @include push(0); } }

    .order-status-event {
      margin-bottom: space(2);

      &:last-child {
        margin-bottom: 0; }

      .label,
      .value {
        display: inline-block; }

      .label {
        &::after {
          content: ':'; } } }

    // Order Transactions
    .payments {
      @include screen-large {
        @include push(0); } }

    .transaction {
      margin-bottom: space(2);

      &:last-child {
        margin-bottom: 0; }

      &.failed {
        color: $color-grey; } }

    .transaction-message {
      font-style: italic; } } }
